import React, { Fragment } from "react"
import { docType } from "config"
import { Form } from "antd"
import { Input, Button, DatePicker, Select, Checkbox } from "antd"
import moment from "moment"
import uniqBy from "lodash/uniqBy"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { AuthActions, MainActions } from "redux-store/models"
import AdressAutoFinder from "shared-components/AdressAutoFinder/AdressAutoFinder"
import "../../themes/css-register/register.css"
import countriesArray from "config/countryArr"
import VirtualizedSelect from "react-virtualized-select"
import { Header } from "shared-components"
import "react-select/dist/react-select.css"
import "react-virtualized-select/styles.css"
import { getBase64 } from "utils/HelperFunc"
import { InlineShareButtons } from "sharethis-reactjs"
import { getCopy } from "utils"

const { Option } = Select

class RegisterEndUser extends React.Component {
  state = {
    visible: true,
    locationData: {},
    comuniSelected: {},
    nazione: "",
    province_of_birth: "",
    city_of_birth: "",

    nazioneDiResidenca: "",
    residence_province: "",
    residence_city: "",
    agency_nazione: "",
    agency_comune: "",
    agency_province: "",

    tipoDocumento: "",
    fileType: 0,
    cardView: 0,
    // sesso: "",
    nascita: "",
    imageUrl: "",
    imageUrl2: "",
    loading: false,
    // step: 1,
    pagamensileInp: "",
    costoanualeInp: "",
    recieve_emails: false,
    privacy_policy: false,
    codFisInps: "",
    valuess2: "",
    skin_default_rent: "",
    token: "",
    shareIcons: false,
  }
  componentDidMount() {
    fetch("https://ipapi.co/json")
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          locationData: data,
          cordinateReq: `${data.latitude},${data.longitude}`,
        })
        this.props.form.setFieldsValue({
          cordinate: `${data.latitude},${data.longitude}`,
        })
      })

    // if (this.props.match.params.token) {
    //   this.setState({ token: this.props.match.params.token })
    // } else {
    //   this.props.getRegisterToken(
    //     this.props.accountInfo.profile.account_id,
    //     this.setToken
    //   )
    // }

    if (!this.props.match.params.token) {
      this.props.form.setFieldsValue({
        pagmens: this.props.accountInfo.profile.skin_default_rent
          ? this.props.accountInfo.profile.skin_default_rent
          : "",
      })
    }
  }

  // nextStep = () => {
  //   let { step } = this.state;
  //   step += 1;
  //   if (step >= 4) {
  //     this.setState({ step: 4 });
  //   } else {
  //     this.setState({ step });
  //   }
  // };
  // prevStep = () => {
  //   let { step } = this.state;
  //   step -= 1;
  //   if (step <= 1) {
  //     this.setState({ step: 1 });
  //   } else {
  //     this.setState({ step });
  //   }
  // };

  handleChangeBack = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl2) =>
        this.setState({
          imageUrl2,
          loading: false,
        })
      )
    }
  }

  handleChangeFront = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      )
    }
  }
  handleClose = () => {
    this.setState({ visible: false })
  }

  hideAlert = () => {
    this.props.setRegister({})
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.getRegister(
          values.first_name,
          values.last_name,
          values.nickname, // for username
          values.email,
          values.gender,
          this.state.codFisInps,
          moment(values.birthday).format("YYYY-MM-DD"),
          this.state.nazione,
          this.state.province_of_birth,
          this.state.city_of_birth,
          this.state.nazioneDiResidenca,
          this.state.residence_province,
          this.state.residence_city,
          values.address,
          values.cap,
          values.identity_id,
          values.identity_type,
          values.number_prefix,
          values.number,
          this.state.imageUrl,
          this.state.imageUrl2,
          "agency",
          values.aRagSoc,
          values.aInsegna,
          values.aPhone,
          values.aAdress,
          this.state.agency_comune,
          this.state.agency_province,
          values.aCap,
          values.aPiva,
          values.aFcode,
          values.confirm_password,
          values.password,
          values.rilasciato_da,
          values.luogo_di_rilascio,
          values.data_di_rilascio,
          values.data_di_scadenza,
          this.state.cordinateReq,
          this.state.agency_nazione,
          values.pagmens,
          this.state.privacy_policy,
          this.state.recieve_emails,
          null,
          "",
          values.wallet,
          values.tipo_di_mensile,
          this.props.activeSkinId,
          this.props.match.params.token
          //   values.self_limit_period,
          //   values.promo,
          //   values.parent,
          //   values.contract,
          //   values.terms,
          //   values.privacy,
          //   values.newsletter
        )

        var that = this

        setTimeout(function () {
          that.setState({ visible: true })
        }, 1000)
      }
    })
  }

  inputlength = (e) => {
    if (e.target.value.length === 16) {
      this.validateCodiceFiscale(e)
    }
  }

  validateCodiceFiscale = (e) => {
    const codFisInps = e
    const str = codFisInps

    const fiscalCodeKey = str.substring(str.length - 5, str.length - 1)
    const sexKey = parseInt(str.substring(9, 11), 10)
    const yearBKey = str.substring(6, 8)
    const monthBKey = str.substring(8, 9)

    let month = ""
    let year = 1900 + parseInt(yearBKey, 10)
    let day = sexKey % 40

    const monthMap = {
      A: "01",
      B: "02",
      C: "03",
      D: "04",
      E: "05",
      H: "06",
      L: "07",
      M: "08",
      P: "09",
      R: "10",
      S: "11",
      T: "12",
    }

    month = monthMap[monthBKey.toUpperCase()]

    if (sexKey > 40) {
      this.props.form.setFieldsValue({ gender: "F" })
    } else {
      this.props.form.setFieldsValue({ gender: "M" })
    }

    this.props.form.setFieldsValue({
      birthday: moment(`${day}-${parseInt(month)}-${year}`, "DD-MM-YYYY"),
    })

    countriesArray
      .filter((comune) => comune.codeKey.toString() === fiscalCodeKey.toString())
      .map((comune) => {
        this.setState({
          comuniSelected: comune,
          nazione: comune.nazione,
          province_of_birth: comune.sigla,
          city_of_birth: comune.provincia,
        })

        return comune
      })
  }

  getValues = () => {
    const inpArr = [...new Array(16)].map((inp, k) => {
      return document.getElementById("inp" + k)
    })
    let codFisInps = ""
    inpArr.forEach((inp) => {
      codFisInps += inp.value.toUpperCase() || " "
    })
    this.setState({
      codFisInps,
    })

    const str = codFisInps
    const fiscalCodeKey = str.substring(str.length - 5, str.length - 1)
    const sexKey = str.substring(9, 11)

    const yearBKey = str.substring(6, 8)
    const monthBKey = str.substring(8, 9)

    let month = ""
    let year = 1900 + parseInt(yearBKey)
    let day = sexKey % 40

    if (monthBKey.toUpperCase() === "A") {
      month = "01"
    } else if (monthBKey.toUpperCase() === "B") {
      month = "02"
    } else if (monthBKey.toUpperCase() === "C") {
      month = 3
    } else if (monthBKey.toUpperCase() === "D") {
      month = 4
    } else if (monthBKey.toUpperCase() === "E") {
      month = 5
    } else if (monthBKey.toUpperCase() === "H") {
      month = 6
    } else if (monthBKey.toUpperCase() === "L") {
      month = 7
    } else if (monthBKey.toUpperCase() === "M") {
      month = 8
    } else if (monthBKey.toUpperCase() === "P") {
      month = 9
    } else if (monthBKey.toUpperCase() === "R") {
      month = 10
    } else if (monthBKey.toUpperCase() === "S") {
      month = 11
    } else if (monthBKey.toUpperCase() === "T") {
      month = 12
    }

    if (sexKey > 40) {
      // this.setState({ sesso: "F" });
      this.props.form.setFieldsValue({
        gender: "F",
      })
    } else {
      // this.setState({ sesso: "M" });
      this.props.form.setFieldsValue({
        gender: "M",
      })
    }

    this.setState({ nascita: `${day}-${parseInt(month)}-${year}` })

    countriesArray
      .filter((comune) => comune.codeKey.toString() === fiscalCodeKey.toString())
      .map((comune) => {
        this.setState({
          comuniSelected: comune,
          nazione: comune.nazione,
          province_of_birth: comune.sigla,
          city_of_birth: comune.provincia,
        })
        return comune
      })
  }
  receiveEmailsChange = (e) => {
    this.setState({ recieve_emails: e.target.checked })
  }

  privacyPolicyChange = (e) => {
    this.setState({ privacy_policy: e.target.checked })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { register } = this.props

    const {
      nazione,
      nazioneDiResidenca,
      residence_city,
      residence_province,
      agency_nazione,
      agency_comune,
      codFisInps,
      agency_province,
    } = this.state
    // const {sesso}=this.state

    console.log(this.props.activeSkinId)

    const allNazione = uniqBy(countriesArray, "nazione")

    const nazioneList = []
    if (allNazione && allNazione.length > 0) {
      for (let i = 0; i < allNazione.length; i++) {
        nazioneList.push(
          <Option key={allNazione[i].nazione} value={allNazione[i].nazione}>
            {allNazione[i].nazione}
          </Option>
        )
      }
    }

    let city_of_birth = []
    if (countriesArray) {
      city_of_birth = countriesArray
        .filter((items) => items.nazione === nazione.toUpperCase())
        .map((items) => {
          return {
            label: items.provincia,
            value: items.provincia,
          }
        })
    }

    let province_of_birthOptions = []
    if (countriesArray) {
      province_of_birthOptions = countriesArray
        .filter((items) => items.nazione === nazione.toUpperCase())
        .map((items) => {
          return {
            label: items.sigla,
            value: items.sigla,
          }
        })
    }

    const handleNazione = (e) => {
      this.setState({ nazione: e })
    }

    /************ RESIDENCA **********/

    const dateFormat = "DD/MM/YYYY"
    const number_prefix = getFieldDecorator("number_prefix", {
      initialValue: "+39",
    })(<Input style={{ width: 70 }}></Input>)
    // const { step } = this.state;
    return (
      <Fragment>
        {!this.props.accountInfo?.profile?.activity_id &&
          this.props.accountInfo?.profile?.role?.name !== "main_admin" && (
            <Header></Header>
          )}
        <Form
          className="newReg registerAgenzia"
          style={{ marginTop: 10, maxWidth: "unset" }}
          onSubmit={this.handleSubmit}
        >
          <div className="newReg--header">Registra Agenzia</div>
          <div className="newReg--row">
            <div className="newReg--row__col">
              <div className="itemCol full">
                <div className="inputLabel">
                  Codice Fiscale <span>*</span>
                </div>

                <div className={"inpssWrapper"}>
                  {this.props.screenWidth > 1024 ? (
                    <input
                      style={{ border: 0, width: "100%" }}
                      onChange={(e) => {
                        this.validateCodiceFiscale(e.target.value)
                        this.setState({ codFisInps: e.target.value })
                      }}
                      value={this.state.codFisInps}
                    />
                  ) : (
                    <>
                      <input
                        className="inputCodice text-left"
                        style={{ borderRight: 0 }}
                        type="text"
                        value={this.state.codFisInps}
                        onChange={(e) => {
                          this.setState({ codFisInps: e.target.value })
                        }}
                      />
                    </>
                  )}
                  {/* <i
                    onClick={() => {
                      navigator.clipboard
                        .readText()
                        .then((codFisInps) => {
                          this.setState({ codFisInps })
                          this.validateCodiceFiscale()
                        })
                        .catch((err) => {
                          console.error("Failed to read clipboard contents: ", err)
                        })
                    }}
                    className="fal fa-paste"
                  ></i> */}
                  {codFisInps.length > 0 &&
                    codFisInps.length === 16 &&
                    !codFisInps.includes(" ") && (
                      <i className="fas fa-check-circle"></i>
                    )}
                  {((codFisInps.length > 0 && codFisInps.length < 16) ||
                    codFisInps.includes(" ")) && (
                    <i className="fas fa-times-circle"></i>
                  )}
                </div>

                {/* <Form.Item>
                  {getFieldDecorator("personal_number", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire codice fiscale!",
                      },
                    ],
                  })(
                    <Input
                      // placeholder="codice fiscale*"
                      onBlur={this.validateCodiceFiscale}
                      onInput={(e) => this.inputlength(e)}
                    />
                  )}
                </Form.Item> */}
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Nome <span>*</span>
                </div>
                <Form.Item hasFeedback>
                  {getFieldDecorator("first_name", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire nome!",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Cognome <span>*</span>
                </div>
                <Form.Item hasFeedback>
                  {getFieldDecorator("last_name", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire cognome!",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Sesso <span>*</span>
                </div>
                <Form.Item hasFeedback>
                  {getFieldDecorator("gender", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Inserire sesso!",
                      },
                    ],
                  })(
                    <Select>
                      <Option value="M">Maschile</Option>
                      <Option value="F">Femminile</Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Email <span>*</span>
                </div>
                <Form.Item hasFeedback name="emaaail">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "L'input non è valido E-mail!",
                      },
                      {
                        required: true,
                        message: "Inserisci la tua e-mail!",
                      },
                    ],
                  })(
                    <Input
                      onMouseEnter={() => {
                        document
                          .getElementById("infoUser_email")
                          .removeAttribute("readonly")
                      }}
                      readOnly
                    />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Data di nascita <span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("birthday", {
                    initialValue:
                      this.state.nascita !== "" &&
                      moment(this.state.nascita, dateFormat).isValid()
                        ? moment(this.state.nascita, dateFormat)
                        : null,
                    rules: [
                      {
                        required: true,
                        message: "Inserisci data di nascita",
                      },
                    ],
                  })(
                    <DatePicker
                      // disabledDate={(current) => {
                      //   return current && current > moment().add(-18, "years");
                      // }}
                      placeholder=""
                      format={("DD/MM/YYYY", "DD/MM/YYYY")}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Nazione <span>*</span>
                </div>
                <Form.Item hasFeedback>
                  {nazione === "" && (
                    <Select onChange={(e) => handleNazione(e)}>{nazioneList}</Select>
                  )}
                  {nazione !== "" && (
                    <Select
                      defaultValue={this.state.nazione}
                      onChange={(e) => handleNazione(e)}
                    >
                      {nazioneList}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Comune di nascita <span>*</span>
                </div>
                <Form.Item hasFeedback>
                  <VirtualizedSelect
                    options={city_of_birth}
                    onChange={(city_of_birth) =>
                      this.setState({ city_of_birth: city_of_birth.value })
                    }
                    value={this.state.city_of_birth}
                    maxHeight={100}
                  />
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Provincia <span>*</span>
                </div>
                <Form.Item hasFeedback>
                  <VirtualizedSelect
                    options={province_of_birthOptions}
                    onChange={(province_of_birth) =>
                      this.setState({
                        province_of_birth: province_of_birth.value,
                      })
                    }
                    value={this.state.province_of_birth}
                    maxHeight={100}
                  />
                </Form.Item>
              </div>
              {/* <div className="itemCol semi">
                <div className="inputLabel">
                  Nazione <span>*</span>
                </div>
                <Form.Item hasFeedback>
                  {nazione === "" && (
                    <Select onChange={(e) => handleNazione(e)}>{nazioneList}</Select>
                  )}
                  {nazione !== "" && (
                    <Select
                      defaultValue={this.state.nazione}
                      onChange={(e) => handleNazione(e)}
                    >
                      {nazioneList}
                    </Select>
                  )}
                </Form.Item>
              </div> */}
              <div className="itemCol full">
                <div className="inputLabel">
                  Indirizzo <span>*</span>
                </div>
                <Form.Item hasFeedback>
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "Inserisci il tuo indirizzo!",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <AdressAutoFinder
                form={this.props.form}
                nazione={nazioneDiResidenca}
                province={residence_province}
                comune={residence_city}
                setNazione={(nazione) => {
                  this.setState({ nazioneDiResidenca: nazione })
                }}
                setProvince={(province) => {
                  this.setState({ residence_province: province })
                }}
                setComune={(comune) => {
                  this.setState({ residence_city: comune })
                }}
              />
              {this.props.screenWidth >= 800 && (
                <Agreement
                  privacyPolicyChange={this.privacyPolicyChange}
                  receiveEmailsChange={this.receiveEmailsChange}
                />
              )}
            </div>
            <div className="newReg--row__col">
              <div className="itemCol full">
                <div className="inputLabel">
                  Ragione sociale <span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("aRagSoc", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire ragione sociale!",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Insegna <span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("aInsegna", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire insegna!",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Cordinate
                  <span
                    onMouseOver={() => {
                      this.setState({ cordHelp: true })
                    }}
                    onMouseLeave={() => {
                      this.setState({ cordHelp: false })
                    }}
                  >
                    * (?)
                    {this.state.cordHelp && (
                      <div className="helper">
                        La tua posizione latitudine, longitudine
                      </div>
                    )}
                  </span>
                </div>
                <Form.Item>
                  {getFieldDecorator("cordinate", {
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: "Inserisci le cordinate!",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <div className="inputLabel">
                  Telefono agenzia <span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("aPhone", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire numero di telefono",
                        whitespace: true,
                      },
                    ],
                  })(<Input addonBefore={number_prefix} />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Sede operativa<span>*</span>
                </div>
                <Form.Item hasFeedback>
                  {getFieldDecorator("aAdress", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire la sede overativa",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="itemCol semi ">
                <div className="inputLabel">
                  Codice fiscale agenzia<span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("aFcode", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire codice",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <AdressAutoFinder
                titles={{
                  nazione: "Nazione (Agenzia)",
                  province: "Provincia (Agenzia)",
                  comune: "Comune",
                  cap: "Cap (Agenzia)",
                }}
                capFormKey="aCap"
                form={this.props.form}
                nazione={agency_nazione}
                province={agency_province}
                comune={agency_comune}
                setNazione={(nazione) => {
                  this.setState({ agency_nazione: nazione })
                }}
                setProvince={(province) => {
                  this.setState({ agency_province: province })
                }}
                setComune={(comune) => {
                  this.setState({ agency_comune: comune })
                }}
              />
              <div className="itemCol semi">
                <div className="inputLabel">
                  P.Iva<span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("aPiva", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire p.Iva",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="itemCol semi piva">
                <div className="inputLabel">
                  Verifica P.iva<span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("pivaVerifica", {
                    initialValue: "b",
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                      },
                    ],
                  })(
                    <Select initialValue="b">
                      <Option value="a">Validato</Option>
                      <Option value="b">Non Validato</Option>
                    </Select>
                  )}
                  <i className="fas fa-file-check"></i>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva"
                  >
                    <i className="fas fa-link"></i>
                  </a>
                </Form.Item>
              </div>

              {/* pagamensileInp : '',
              costoanualeInp : '' */}
              <div className="itemCol full">
                <div className="inputLabel">
                  Pagamento mensile<span>*</span>
                </div>

                <Form.Item>
                  {getFieldDecorator("pagmens", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire Pagamento Mensile",
                        whitespace: true,
                      },
                    ],
                  })(<Input type="number" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <div className="inputLabel">
                  Tipo di Pagamento Mensile<span>*</span>
                  <Form.Item>
                    {getFieldDecorator("tipo_di_mensile", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                        },
                      ],
                    })(
                      <Select>
                        <Option value="annually">Annuale</Option>
                        <Option value="monthly">Mensile</Option>
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="newReg--row__col">
              <div className="itemCol full">
                <div className="inputLabel">Wallet</div>

                <Form.Item>
                  {getFieldDecorator("wallet", {})(<Input type="number" />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <div className="inputLabel">
                  Username
                  <span
                    onMouseOver={() => {
                      this.setState({ nicknameHelp: true })
                    }}
                    onMouseLeave={() => {
                      this.setState({ nicknameHelp: false })
                    }}
                  >
                    * (?)
                    {this.state.nicknameHelp && (
                      <div className="helper">
                        L'username deve contenere più di 7 caratteri
                      </div>
                    )}
                  </span>
                  <Form.Item hasFeedback>
                    {getFieldDecorator("nickname", {
                      rules: [
                        {
                          required: true,
                          message: "Inserire un nome utente valido!",
                        },
                        {
                          validator: (a, b, c) => {
                            const { form } = this.props
                            if (form.getFieldValue("nickname").length >= 8) {
                              c()
                            } else {
                              c("Username è corto!")
                            }
                          },
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Password<span>*</span>
                  <Form.Item hasFeedback>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Inserire password!",
                        },
                      ],
                    })(<Input.Password type="password" />)}
                  </Form.Item>
                </div>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Conferma password<span>*</span>
                </div>
                <Form.Item hasFeedback>
                  {getFieldDecorator("confirm_password", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire password!",
                      },
                      {
                        validator: (a, b, c) => {
                          const { form } = this.props
                          if (
                            form.getFieldValue("password") ===
                            form.getFieldValue("confirm_password")
                          ) {
                            c()
                          } else {
                            c("Conferma password errata!")
                          }
                        },
                      },
                    ],
                  })(<Input.Password />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <div className="inputLabel">
                  Cellulare<span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("number", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire il numero di cellulare!",
                      },
                    ],
                  })(<Input addonBefore={number_prefix} />)}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <div className="inputLabel">
                  Tipo documento<span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("identity_type", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire tipo di documenti!",
                      },
                    ],
                  })(
                    <Select onChange={this.onChangeIdentity}>
                      {docType.map((doc) => {
                        return (
                          <Option key={doc.id} value={doc.id}>
                            {doc.name}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="itemCol full">
                <div className="inputLabel">
                  Numero documento<span>*</span>
                </div>
                <Form.Item>
                  {getFieldDecorator("identity_id", {
                    rules: [
                      {
                        required: true,
                        message: "Inserire il numero di documento!",
                        whitespace: true,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Rilasciato da<span>*</span>
                  <Form.Item>
                    {getFieldDecorator("rilasciato_da", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                        },
                      ],
                    })(
                      <Select>
                        <Option value="1">Comune</Option>
                        <Option value="10">Motorizzazione</Option>
                        <Option value="13">Questura</Option>
                        <Option value="14">Polizia</Option>
                        <Option value="16">Commissariato</Option>
                        <Option value="19">Altro</Option>
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Luogo di rilascio<span>*</span>
                  <Form.Item>
                    {getFieldDecorator("luogo_di_rilascio", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Inserire il luogo di rilascio",
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Data di rilascio<span>*</span>
                  <Form.Item>
                    {getFieldDecorator("data_di_rilascio", {
                      initialValue: moment(this.state.nascita, dateFormat).isValid()
                        ? moment(this.state.nascita, dateFormat)
                        : null,
                      rules: [
                        {
                          required: true,
                          message: "Inserisci una data valida!",
                        },
                      ],
                    })(<DatePicker format={("DD/MM/YYYY", "DD/MM/YYYY")} />)}
                  </Form.Item>
                </div>
              </div>
              <div className="itemCol semi">
                <div className="inputLabel">
                  Data di scadenza<span>*</span>
                  <Form.Item>
                    {getFieldDecorator("data_di_scadenza", {
                      initialValue: moment(this.state.nascita, dateFormat).isValid()
                        ? moment(this.state.nascita, dateFormat)
                        : null,
                      rules: [
                        {
                          required: true,
                          message: "Inserisci una data valida!",
                        },
                      ],
                    })(<DatePicker format={("DD/MM/YYYY", "DD/MM/YYYY")} />)}
                  </Form.Item>
                </div>
              </div>

              <div className="itemCol full">
                {register.message && (
                  <React.Fragment>
                    {register.role ? (
                      <React.Fragment>
                        <div className="Nmessage S">
                          <i className="fas fa-check-circle" aria-hidden="true"></i>
                          {register.message}
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="Nmessage E">
                          <i className="fas fa-times-circle" aria-hidden="true"></i>
                          {register.message}
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
              {this.props.screenWidth < 800 && (
                <Agreement
                  privacyPolicyChange={this.privacyPolicyChange}
                  receiveEmailsChange={this.receiveEmailsChange}
                />
              )}
              <div className="registerContainer">
                <div className="itemCol semi">
                  <Button
                    type="primary"
                    className="SubmitButton Agency"
                    htmlType="submit"
                  >
                    Registrati
                  </Button>
                </div>
                <div className="itemCol semi">
                  {!this.props.match.params.token &&
                    this.props.accountInfo?.profile?.role?.name !== "main_admin" && (
                      <div
                        className="shareRegister"
                        style={{ marginTop: 7, width: "100%", maxWidth: "unset" }}
                      >
                        <div
                          onClick={() => {
                            this.setState({ shareIcons: !this.state.shareIcons })
                          }}
                          style={{
                            height: "35px",
                            borderRadius: "4px",
                            maxWidth: "unset",
                            width: "100%",
                          }}
                        >
                          <i className="fal fa-share" aria-hidden="true"></i>
                          Condividere
                        </div>
                        {
                          <div
                            className={
                              "options" + (this.state.shareIcons ? "" : " d-none")
                            }
                          >
                            <i
                              className="fal fa-copy"
                              onClick={() => {
                                getCopy(
                                  `${window.location.href}t/${this.props.accountInfo.profile.account_id}`
                                )
                              }}
                            ></i>

                            <InlineShareButtons
                              config={{
                                alignment: "center",
                                color: "social",
                                enabled: true,
                                font_size: 16,
                                language: "en",
                                url: `${window.location.href}t/${this.props.accountInfo.profile.account_id}`,
                                networks: [
                                  // which networks to include (see SHARING NETWORKS)
                                  "whatsapp",
                                  "telegram",
                                  "email",
                                ],
                                padding: 12,
                                radius: 4,
                                show_total: true,
                                size: 40,
                              }}
                            />
                          </div>
                        }
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Fragment>
    )
  }
}

const Agreement = ({ privacyPolicyChange, receiveEmailsChange }) => {
  return (
    <div style={{ marginTop: 10 }}>
      <div className="itemCol full">
        <Checkbox onChange={privacyPolicyChange} className="checkbox-privacy">
          Dichiaro di aver letto e accettato l'
          <a
            href="/informativa-trattamento-dati-personali.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Informativa sulla Privacy
          </a>{" "}
          e i
          <a
            href="/termini-e-condizioni.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            Termini e Condizioni
          </a>{" "}
          del servizio, e acconsento al trattamento dei miei dati personali per
          l'erogazione del servizio in conformità al GDPR.
        </Checkbox>
      </div>
      <div className="itemCol full">
        <Checkbox onChange={receiveEmailsChange}>
          Desidero ricevere comunicazioni commerciali e promozionali.
        </Checkbox>
      </div>
    </div>
  )
}

const InfoUser = Form.create({ name: "infoUser" })(RegisterEndUser)

const mapsStateToProps = ({ auth, main }) => ({
  personalInfo: auth.personalInfo,
  register: auth.register,
  accountInfo: auth.accountInfo,
  screenWidth: main.screenWidth,
  activeSkinId: main.activeSkinId,
})

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions, ...MainActions })(InfoUser)
)
