import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { Tabs } from "antd"
import { RingProgress } from "@ant-design/plots"

const { TabPane } = Tabs

class StatisticheReportMensile extends React.Component {
  // componentDidMount() {
  //   this.props.getLatestServiceStatistic(
  //     this.props.activeSkinId !== -1 ? this.props.activeSkinId : 1
  //   )
  // }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeSkinId !== prevProps.activeSkinId &&
      this.props.activeSkinId !== -1
    ) {
      this.props.getLatestServiceStatistic(this.props.activeSkinId)
    }
  }
  render() {
    const { latestServiceStatistic } = this.props

    const config = {
      height: 60,
      width: 60,
      autoFit: false,
      innerRadius: 0.6,
    }

    const colorConfigs = [
      {
        color: ["#0AD885", "#DEF4ED"],
      },
      {
        color: ["#0062FF", "#DDE8F9"],
      },
      {
        color: ["#FD7A18", "#F7EAE1"],
      },
      {
        color: ["#ED41E0", "#F5E4F6"],
      },
      {
        color: ["#FAD337", "#FBF2D1"],
      },
    ]
    return (
      <div className="statistiche-report-mensili">
        <div className="statistiche-ring-chart">
          {latestServiceStatistic &&
            latestServiceStatistic.payments &&
            Object.entries(latestServiceStatistic.payments).map(
              ([serviceKey, serviceValue], index) => {
                return (
                  <Tabs defaultActiveKey="1" key={index}>
                    <TabPane
                      tab={
                        <>
                          {" "}
                          <div className="ring-chart-details">{serviceKey}</div>
                          <div className="ring-chart-percentage">
                            <div className="details-percentage">
                              <span>Percentuale</span>
                              <span>
                                {parseFloat(serviceValue?.percentage).toFixed(2)}%
                              </span>
                            </div>

                            <RingProgress
                              percent={parseFloat(serviceValue?.percentage / 100)}
                              color={colorConfigs[index].color}
                              {...config}
                            />
                          </div>
                        </>
                      }
                    ></TabPane>
                  </Tabs>
                )
              }
            )}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  statistiche: state.auth.Statistiche?.total,
  activeSkinId: state.main.activeSkinId,
  latestServiceStatistic: state.auth.latestServiceStatistic,
})
export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  StatisticheReportMensile
)
