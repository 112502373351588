import { Button, Form, Checkbox, message, Input } from "antd"
import React, { useEffect, useState } from "react"
import FloatingLabelInput from "../FloatingLabelInput/FloatingLabelInput"
import countriesArray from "config/countryArr"
import VirtualizedSelect from "react-virtualized-select"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { AuthActions, MainActions } from "redux-store/models"
import "./InfoRequestModal.css"
import { InlineShareButtons } from "sharethis-reactjs"
import { getCopy } from "utils"

const InfoRequestModal = ({
  form,
  getRegister,
  match,
  getRegisterToken,
  accountInfo,
}) => {
  const { getFieldDecorator, validateFields } = form
  const [selectedForm, setSelectedForm] = useState("richiedi")
  const [nazione, setNazione] = useState("ITALIA")
  const [shareIcons, setShareIcons] = useState(false)
  const [token, setToken] = useState({})

  useEffect(() => {
    if (match.params.token) {
      setToken(match.params.token)
    } else {
      getRegisterToken(accountInfo.profile.account_id, setToken)
    }
  }, [match.params.token, getRegisterToken, accountInfo])

  const city_of_birthOptions = countriesArray
    .filter((item) => item.nazione === nazione.toUpperCase())
    .map((item) => ({
      label: item.provincia,
      value: item.provincia,
    }))

  const number_prefix = getFieldDecorator("number_prefix", {
    initialValue: "0039",
  })(<Input style={{ width: 70 }}></Input>)

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        console.log("Form values:", values)
        message.success("Modulo inviato con successo!")
        if (selectedForm === "registrati") {
          getRegister(
            values.nome,
            values.cognome,
            values.username,
            values.email,
            "",
            "",
            "",
            nazione,
            "",
            "",
            "",
            "",
            values.cittaResidenza,
            "",
            "",
            "",
            "",
            values.number_prefix || number_prefix,
            values.number,
            "",
            "",
            "user",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            values.confirm_password,
            values.password,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            values.privacy_policy,
            values.privacyPolicy,
            "",
            match.params.token,
            "",
            ""
          )
        }
      }
    }).catch(() => {
      message.error("Compilare tutti i campi richiesti.")
    })
  }

  return (
    <div
      className="info-request-modal"
    >
      <div className="ant-modal-title">COMPILA IL FORM</div>
      <div className="ant-modal-content">
      <div className="form-toggle-buttons">
        <Button
          type="primary"
          block
          className={selectedForm === "richiedi" ? "selected" : "unselected"}
          onClick={() => setSelectedForm("richiedi")}
        >
          Richiedi Informazioni
        </Button>
        <Button
          type="primary"
          block
          className={selectedForm === "registrati" ? "selected" : "unselected"}
          onClick={() => setSelectedForm("registrati")}
        >
          Registrati
        </Button>
      </div>

      <Form layout="vertical" className="info-request-form">
        {/* Richiedi Form  */}
        {selectedForm === "richiedi" && (
          <>
            <Form.Item label="Città di residenza" hasFeedback>
              {getFieldDecorator("cittaResidenza", {
                rules: [
                  { required: true, message: "Per favore seleziona una città!" },
                ],
              })(
                <VirtualizedSelect
                  options={city_of_birthOptions}
                  maxHeight={100}
                  placeholder="Seleziona la tua città"
                />
              )}
            </Form.Item>

            <Form.Item hasFeedback>
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Per favore inserisci la tua e-mail!" },
                  { type: "email", message: "Inserisci un'email valida!" },
                ],
              })(<FloatingLabelInput label="Indirizzo e-mail" />)}
            </Form.Item>

            <Form.Item hasFeedback>
              {getFieldDecorator("number", {
                rules: [
                  {
                    required: true,
                    message: "Per favore inserisci il tuo numero di cellulare!",
                  },
                ],
              })(<FloatingLabelInput label="Numero di cellulare" />)}
            </Form.Item>

            <Form.Item hasFeedback>
              {getFieldDecorator("nome", {
                rules: [
                  { required: true, message: "Per favore inserisci il tuo nome!" },
                ],
              })(<FloatingLabelInput label="Nome" />)}
            </Form.Item>

            <Form.Item style={{ marginTop: "-17px" }} hasFeedback>
              {getFieldDecorator("cognome", {
                rules: [
                  {
                    required: true,
                    message: "Per favore inserisci il tuo cognome!",
                  },
                ],
              })(<FloatingLabelInput label="Cognome" />)}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator("privacyPolicy", {
                valuePropName: "checked",
                rules: [
                  {
                    required: true,
                    message:
                      "Devi accettare la Privacy Policy e i Termini e Condizioni!",
                  },
                ],
              })(
                <Checkbox handleSubmit>
                  Compilando questo modulo, accetti la nostra{" "}
                  <a
                    href="/informativa-trattamento-dati-personali.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  e i{" "}
                  <a
                    href="/termini-e-condizioni.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Termini e Condizioni
                  </a>
                  .
                </Checkbox>
              )}
            </Form.Item>
          </>
        )}

        {/* Registrati Form  */}
        {selectedForm === "registrati" && (
          <>
            <Form.Item label="Città di residenza" hasFeedback>
              {getFieldDecorator("cittaResidenza", {
                rules: [
                  { required: true, message: "Per favore seleziona una città!" },
                ],
              })(
                <VirtualizedSelect
                  options={city_of_birthOptions}
                  maxHeight={100}
                  placeholder="Seleziona la tua città"
                />
              )}
            </Form.Item>

            <Form.Item hasFeedback>
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Per favore inserisci la tua e-mail!" },
                  { type: "email", message: "Inserisci un'email valida!" },
                ],
              })(<FloatingLabelInput label="Indirizzo e-mail" />)}
            </Form.Item>

            <Form.Item hasFeedback>
              {getFieldDecorator("number", {
                rules: [
                  {
                    required: true,
                    message: "Per favore inserisci il tuo numero di cellulare!",
                  },
                ],
              })(<FloatingLabelInput label="Numero di cellulare" />)}
            </Form.Item>
            <Form.Item hasFeedback>
              {getFieldDecorator("nome", {
                rules: [
                  { required: true, message: "Per favore inserisci il tuo nome!" },
                ],
              })(<FloatingLabelInput label="Nome" />)}
            </Form.Item>

            <Form.Item style={{ marginTop: "-17px" }} hasFeedback>
              {getFieldDecorator("cognome", {
                rules: [
                  {
                    required: true,
                    message: "Per favore inserisci il tuo cognome!",
                  },
                ],
              })(<FloatingLabelInput label="Cognome" />)}
            </Form.Item>

            <Form.Item hasFeedback>
              {getFieldDecorator("username", {
                rules: [
                  {
                    required: true,
                    message: "Per favore inserisci il tuo username!",
                  },
                ],
              })(<FloatingLabelInput label="Username" />)}
            </Form.Item>

            <Form.Item hasFeedback>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Per favore inserisci la tua password!",
                  },
                ],
              })(<FloatingLabelInput label="Password" type="password" />)}
            </Form.Item>

            <Form.Item hasFeedback>
              {getFieldDecorator("confirmPassword", {
                rules: [
                  {
                    required: true,
                    message: "Per favore conferma la tua password!",
                  },
                  {
                    validator: (rule, value, callback) => {
                      const { password } = form.getFieldsValue()
                      if (value && value !== password) {
                        callback("Le password non coincidono!")
                      } else {
                        callback()
                      }
                    },
                  },
                ],
              })(<FloatingLabelInput label="Conferma Password" type="password" />)}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator("privacyPolicy", {
                valuePropName: "checked",
                rules: [
                  {
                    required: true,
                    message:
                      "Devi accettare la Privacy Policy e i Termini e Condizioni!",
                  },
                ],
              })(
                <Checkbox handleSubmit>
                  Compilando questo modulo, accetti la nostra{" "}
                  <a
                    href="/informativa-trattamento-dati-personali.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  e i{" "}
                  <a
                    href="/termini-e-condizioni.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Termini e Condizioni
                  </a>
                  .
                </Checkbox>
              )}
            </Form.Item>
          </>
        )}
      </Form>
      </div>

      <div className="ant-modal-footer">
        {shareIcons && (
           <div className={"options" + (shareIcons ? "" : " d-none")}>
           <i
             className="fal fa-copy"
             onClick={() => {
               getCopy(`${window.location.href}-token/${token?.register_token}`)
             }}
           ></i>

           <InlineShareButtons
             config={{
               alignment: "center",
               color: "social",
               enabled: true,
               font_size: 16,
               language: "en",
               url: `${window.location.href}-token/${token?.register_token}`,
               networks: [
                 // which networks to include (see SHARING NETWORKS)
                 "whatsapp",
                 "telegram",
                 "email",
               ],
               padding: 12,
               radius: 4,
               show_total: true,
               size: 40,
             }}
           />
         </div>
        )}
        <div style={{ display: "flex" }}>
          {!match.params.token && selectedForm === "registrati" && (
            <Button
              type="secondary"
              icon="share-alt"
              block
              onClick={() => {
                setShareIcons(!shareIcons)
              }}
            >
              Condividi
            </Button>
          )}
          <Button type="primary" block onClick={handleSubmit}>
            {selectedForm === "richiedi"
              ? "Invia richiesta di informazioni"
              : "Registrati"}
          </Button>
        </div></div>
    </div>
  )
}

const InfoRequestModalForm = Form.create()(InfoRequestModal)

const mapsStateToProps = ({ auth, main }) => ({
  register: auth.register,
  accountInfo: auth.accountInfo,
})

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions, ...MainActions })(InfoRequestModalForm)
)
