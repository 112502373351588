import React from "react"
import "./styles.css"
import { connect } from "react-redux"
import AuthActions from "redux-store/models/auth"
import { printFatturaReq } from "services/auth"
import DatePicker from "./DatePicker"
import { Loader } from "shared-components"
import { Select, Pagination, Table, Button, Input, Icon } from "antd"
import { b64toBlob } from "utils"

const { Option } = Select
class NewFaturaDomain extends React.Component {
  state = {
    yearDropdown: false,
    monthDropdown: false,
    yearChosen: "",
    monthChosen: "",
    calendarVis: false,
    userName: "",
    userListShow: false,
    perPage: 25,
    page_number: 1,
    clickedPage: 1,
    raport: "agency",
  }
  setCalendar = (val) => {
    this.setState({ calendarVis: val })
  }

  handleSubmit = (e) => {
    e.preventDefault()
  }
  convertB64ToBolbThenPrnt(file_name, open) {
    printFatturaReq(file_name).then(async (response) => {
      var myBlob = b64toBlob(response.data.base64, "application/pdf")
      var objectURL = URL.createObjectURL(myBlob)
      document.querySelector("#pdf-frame").src = ""
      document.querySelector("#pdf-frame").src = objectURL

      if (open) {
        window.open(
          objectURL,
          "_blank",
          "toolbar=no,scrollbars=no,resizable=no,top=50,left=500,width=1024,height=700"
        )
      } else {
        window.setTimeout(function () {
          document.querySelector("#pdf-frame").contentWindow.print()
        }, 1000)
      }
      objectURL = URL.revokeObjectURL(myBlob)
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => {
      if (dataIndex === "user") {
        return (
          <div
            style={{ padding: 8, width: "180px" }}
            className="table-filter-dropdown"
          >
            <Select
              mode="single"
              allowClear
              showSearch={true}
              style={{ width: "100%" }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys}
              onChange={(value) => {
                setSelectedKeys(value)
                this.setState({ userName: value })
              }}
            >
              {this.props.Users?.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
            <Button
              type="primary"
              onClick={() => this.handleSearch()}
              icon="search"
              size="small"
              style={{ marginTop: 8, width: "100%" }}
            >
              Search
            </Button>
          </div>
        )
      }
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) => (this.state.searchedColumn === dataIndex ? text : text),
  })

  handleSearch = () => {
    const { userName, monthChosen, yearChosen, perPage, page_number } = this.state
    this.props.getAllFaturaBySearch(
      userName,
      monthChosen.id,
      yearChosen,
      perPage,
      page_number,
      this.props.accountInfo.profile.role.name === "super_admin"
        ? this.state.raport
        : ""
    )
  }
  componentDidMount() {
    this.props.getAllFaturaBySearch(null, null, null, 25, 1)
  }
  render() {
    const { faturaDetails, Users, total_pages, screenWidth } = this.props

    var columns =
      screenWidth < 550
        ? [
            {
              title: "Date / Ora",
              dataIndex: "time",
              key: "time",
              ellipsis: true,
              width: "18%",
            },
            {
              title: "User",
              dataIndex: "user",
              key: "user",
              ellipsis: true,
              width: "12%",
              ...this.getColumnSearchProps("user"),
              render: (text) => (
                <>
                  <i className="fal fa-store"></i>
                  {text}
                </>
              ),
            },
            {
              title: "Operazione",
              dataIndex: "operazione",
              key: "operazione",
              ellipsis: true,
              width: "10%",
              align: "center",
              render: (text, record) => {
                console.log(record)
                return (
                  <div className="operazione">
                    <i
                      onClick={() => {
                        this.convertB64ToBolbThenPrnt(record.file_name, "open")
                      }}
                      className="far fa-file-pdf"
                    ></i>
                    <i
                      className="far fa-print"
                      onClick={() => {
                        this.convertB64ToBolbThenPrnt(record.file_name)
                      }}
                    ></i>
                    <iframe
                      id="pdf-frame"
                      title="pdf-frame-fattura"
                      style={{ display: "none" }}
                    ></iframe>
                    <i
                      className="far fa-envelope-open-text"
                      onClick={() => {
                        this.props.sendMailFattura(record.file_name)
                      }}
                    ></i>
                  </div>
                )
              },
            },
          ]
        : [
            {
              title: "Numero",
              dataIndex: "numero",
              key: "numero",
              ellipsis: true,
              width: "12%",
            },
            {
              title: "Date / Ora",
              dataIndex: "time",
              key: "time",
              ellipsis: true,
              width: "18%",
            },
            {
              title: "User",
              dataIndex: "user",
              key: "user",
              ellipsis: true,
              width: "12%",
              ...this.getColumnSearchProps("user"),
              render: (text) => (
                <>
                  <i className="fal fa-store"></i>
                  {text}
                </>
              ),
            },
            {
              title: "Descrizione",
              dataIndex: "descrizione",
              key: "descrizione",
              ellipsis: true,
              width: "18%",
            },
            {
              title: "Importo",
              dataIndex: "importo",
              key: "importo",
              ellipsis: true,
              align: "right",
              render: (text) => `${text}€`,
              width: "10%",
            },
            {
              title: "Commissione",
              dataIndex: "commissione",
              key: "commissione",
              ellipsis: true,
              align: "right",
              render: (text) => `${text}€`,
              width: "10%",
            },
            {
              title: "Proviggione",
              dataIndex: "proviggione",
              key: "proviggione",
              ellipsis: true,
              align: "right",
              render: (text) => `${text}€`,
              width: "10%",
            },
            {
              title: "Operazione",
              dataIndex: "operazione",
              key: "operazione",
              ellipsis: true,
              width: "10%",
              align: "center",
              render: (text, record) => {
                console.log(record)
                return (
                  <div className="operazione">
                    <i
                      onClick={() => {
                        this.convertB64ToBolbThenPrnt(record.file_name, "open")
                      }}
                      className="far fa-file-pdf"
                    ></i>
                    <i
                      className="far fa-print"
                      onClick={() => {
                        this.convertB64ToBolbThenPrnt(record.file_name)
                      }}
                    ></i>
                    <iframe
                      id="pdf-frame"
                      title="pdf-frame-fattura"
                      style={{ display: "none" }}
                    ></iframe>
                    <i
                      className="far fa-envelope-open-text"
                      onClick={() => {
                        this.props.sendMailFattura(record.file_name)
                      }}
                    ></i>
                  </div>
                )
              },
            },
          ]

    var data = faturaDetails?.map((f) => ({
      numero: f.numero,
      time: f.time,
      user: f.user,
      descrizione: f.descrizione,
      importo: f.importo,
      commissione: f.commissione,
      proviggione: f.proviggione,
      file_name: f.file_name,
    }))
    console.log(Users)
    const {
      calendarVis,
      monthDropdown,
      yearDropdown,
      monthChosen,
      yearChosen,
      userName,
      userListShow,
      perPage,
      page_number,
      clickedPage,
    } = this.state
    return (
      <div className="Container" style={{ width: "100%" }}>
        <div className="FaturaDomain">
          <div className="panels-container">
            <div className="row no-gutters ">
              <div className="FauturaFilter">
                {calendarVis && (
                  <DatePicker
                    setMonthDropdown={() => {
                      this.setState((state) => ({
                        monthDropdown: !state.monthDropdown,
                      }))
                    }}
                    setYearDropdown={() => {
                      this.setState((state) => ({
                        yearDropdown: !state.yearDropdown,
                      }))
                    }}
                    setMonthChosen={(month) => {
                      this.setState((state) => ({
                        monthChosen:
                          month.id === state.monthChosen.id
                            ? ""
                            : { id: month.id, name: month.name },
                        monthDropdown: !state.monthDropdown,
                      }))
                    }}
                    setYearChosen={(year) => {
                      this.setState((state) => ({
                        yearChosen: year.name === state.yearChosen ? "" : year.name,
                        yearDropdown: !state.yearDropdown,
                      }))
                    }}
                    yearChosen={yearChosen}
                    monthChosen={monthChosen}
                    monthDropdown={monthDropdown}
                    yearDropdown={yearDropdown}
                  />
                )}
                {screenWidth > 500 && (
                  <div className="FauturaFilter--Header">
                    <div>FATTURE</div>
                  </div>
                )}

                <div className="FauturaFilter--Options">
                  {screenWidth > 390 && (
                    <>
                      {this.props.accountInfo?.profile.role.name ===
                        "super_admin" && (
                        <React.Fragment>
                          <Select
                            defaultValue={this.state.raport}
                            onChange={(value) => this.setState({ raport: value })}
                            style={{marginRight: 10}}
                          >
                            <Option key="agency">Report Agenzie</Option>
                            <Option key="agent">Report Agente</Option>
                            <Option key="skin">Report Skin</Option>
                          </Select>
                        </React.Fragment>
                      )}
                    </>
                  )}
                  <div>
                    <div
                      className="FauturaFilter--CalendarLabel"
                      onClick={(e) => {
                        if (e.target.tagName !== "I") {
                          this.setCalendar(!calendarVis)
                        }
                      }}
                    >
                      <i className="fal fa-calendar-alt"></i>

                      {monthChosen && yearChosen
                        ? `${monthChosen.name} / ${yearChosen}`
                        : "Seleziona la data"}
                      <i
                        className={`fal fa-chevron-${calendarVis ? "up" : "down"}`}
                        onClick={() => {
                          this.setCalendar(!calendarVis)
                        }}
                      ></i>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      this.setState({ clickedPage: 1 })
                      this.props.getAllFaturaBySearch(
                        userName,
                        monthChosen.id,
                        yearChosen,
                        perPage,
                        page_number,
                        this.props.accountInfo.profile.role.name === "super_admin"
                          ? this.state.raport
                          : ""
                      )
                    }}
                  >
                    Filtra
                  </button>
                </div>
              </div>
            </div>
            <div
              className={
                this.props.accountInfo?.profile.role.name === "agency" ||
                this.props.accountInfo?.profile.role.name === "simple_user"
                  ? "row no-gutters maxWidth"
                  : "row no-gutters"
              }
            >
              {!this.props.fattura_loading ? (
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  rowKey={(record, i) => i}
                  bordered
                />
              ) : (
                <Loader />
              )}
              <div className="paginationWrapper FatturePag">
                <Pagination
                  onChange={(e) => {
                    this.setState({ clickedPage: e })
                    this.props.getAllFaturaBySearch(
                      userName,
                      monthChosen.id,
                      yearChosen,
                      perPage,
                      e
                    )
                  }}
                  total={total_pages ? total_pages * 10 : 1}
                  current={clickedPage}
                />
                <Select
                  defaultValue={25}
                  onChange={(e) => {
                    this.setState({ perPage: parseInt(e), clickedPage: 1 }, () => {
                      this.props.getAllFaturaBySearch(
                        userName,
                        monthChosen.id,
                        yearChosen,
                        parseInt(e),
                        page_number
                      )
                    })
                  }}
                  value={this.state.perPage}
                >
                  <Option value={10}>10 / Pagina</Option>
                  <Option value={25}>25 / Pagina</Option>
                  <Option value={50}>50 / Pagina</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mpst = (state) => ({
  faturaDetails: state.auth.Fatture.FaturaDetails,
  total_pages: state.auth.Fatture.total_pages,
  skinExtras: state.auth.skinExtras,
  Users: state.auth.Fatture.Users,
  screenWidth: state.main.screenWidth,
  fattura_loading: state.auth.fattura_loading,
  accountInfo: state.auth.accountInfo,
})
export default connect(mpst, AuthActions)(NewFaturaDomain)
