import React, { useEffect, useState } from "react"
import images from "../../../themes/images"
import ImageUploadComponent from "../ServiziModal/ImageUploadComponent"
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Tabs,
} from "antd"
import { baseUrlImg } from "../../../config/api"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "./EditServiceForm.css"
import { useHistory } from "react-router-dom"
import BonusModal from "../ServiziModal/BonusModal"

const EditService = ({
  id,
  selectedCompany,
  updateCompany,
  getSelectedCompany,
  UpdateServicesChangeStatus,
  getAllServices,
  activeSkinId,
}) => {
  const [logo, setLogo] = useState(null)
  const [logoScontrino, setLogoScontrino] = useState(null)
  const [icon, setIcon] = useState(null)
  const [bgImage, setBgImage] = useState(null)
  const [selectedService, setSelectedService] = useState("default")
  const [selectedServiceId, setSelectedServiceId] = useState("default")
  const [formData, setFormData] = useState({
    default: {
      servizio: "",
      commissioni: "",
      provvigioni: "",
      iva: "",
    },
  })
  const [openBonusModal, setOpenBonusModal] = useState(false)
  const [serviceChanges, setServiceChanges] = useState({})
  const [defaultChanges, setDefaultChanges] = useState({})
  const [content, setContent] = useState("")
  const [scontrinoContent, setScontrinoContent] = useState("")
  const [colorBg, setColorBg] = useState("")
  const [colorTesto, setColorTesto] = useState("")

  const history = useHistory()
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ align: [] }],
        ["link", "image", "video"],
      ],
    },
  }

  useEffect(() => {
    getSelectedCompany(id)
  }, [id])

  useEffect(() => {
    if (selectedCompany && selectedCompany.services) {
      const initialFormData = {}
      selectedCompany.services.forEach((service) => {
        initialFormData[service.id] = {
          servizio: service.cost || "",
          commissioni: service.commissione || "",
          provvigioni: service.agency_guadagno || "",
          iva: service.iva || "",
        }
      })
      setFormData(initialFormData)
    }
    setLogo(selectedCompany.logo)
    setBgImage(selectedCompany.background)
    setIcon(selectedCompany.icon)
    setLogoScontrino(selectedCompany.scontrino)
    setColorBg(selectedCompany.colorBg)
    setColorTesto(selectedCompany.colorTesto)
    setContent(selectedCompany.content)
  }, [selectedCompany])

  const handleChangeService = (value) => {
    setSelectedService(value)
    value === "default"
      ? setSelectedServiceId("default")
      : setSelectedServiceId(selectedCompany?.services[value]?.id)
  }

  const handleSubmit = async () => {
    let servicesToSend
    if (Object.keys(defaultChanges).length > 0) {
      servicesToSend = defaultChanges
    } else {
      servicesToSend = serviceChanges
    }

    try {
      await updateCompany(
        selectedCompany.id,
        logo?.id,
        bgImage?.id,
        icon?.id,
        logoScontrino?.id,
        colorBg,
        colorTesto,
        servicesToSend,
        content,
        scontrinoContent
      )
    } catch (error) {
      console.error(error)
    } finally {
      //   getService()
      setDefaultChanges({})
      setServiceChanges({})
    }
  }

  const handleFieldChange = (serviceId, field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceId]: {
        ...prevFormData[serviceId],
        [field]: value,
      },
    }))
    if (serviceId === "default") {
      setDefaultChanges((prevDefaultChanges) => ({
        ...prevDefaultChanges,
        default: {
          ...prevDefaultChanges.default,
          [field]: value,
        },
      }))
    } else {
      setServiceChanges((prevChanges) => {
        const updatedChanges = { ...prevChanges }
        if (!updatedChanges[serviceId]) {
          updatedChanges[serviceId] = {}
        }
        updatedChanges[serviceId][field] = value
        return updatedChanges
      })
    }
  }
  const handleQuillChange = (value, isScontrino) => {
    isScontrino ? setScontrinoContent(value) : setContent(value)
  }

  const handleImageSelect = (selectedImage, imageType) => {
    if (imageType === "logo") {
      setLogo(selectedImage)
    } else if (imageType === "icon") {
      setIcon(selectedImage)
    } else if (imageType === "logoScontrino") {
      setLogoScontrino(selectedImage)
    } else if (imageType === "bgImage") {
      setBgImage(selectedImage)
    }
  }

  const handleStatusChange = (newStatus) => {
    UpdateServicesChangeStatus(
      selectedCompany.name,
      selectedCompany.full_name,
      selectedCompany.id,
      newStatus,
      activeSkinId,
      async () => {
        getSelectedCompany(id)
      }
    )
  }

  return (
    <>
      <BonusModal
        open={openBonusModal}
        onClose={() => {
          setOpenBonusModal(false)
        }}
        selectedCompany={selectedCompany}
      />
      <div className="edit-service">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6} xl={4}>
            <div className="service-header">
              <div className="service-logo">
                <div onClick={() => history.goBack()} className="go-back">
                  <i className="fal fa-chevron-left mr-1" />
                  <img
                    src={
                      `${baseUrlImg}/${selectedCompany.icon}` ||
                      images["placeholder"]
                    }
                    alt="service"
                  />
                </div>
                <span>{selectedCompany.full_name}</span>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={6} xl={4}>
            <div className="service-header">
              <img
                src={
                  images[selectedCompany.active === 0 ? "inactiveBtn" : "activeBtn"]
                }
                className="activate-btn"
                onClick={() => handleStatusChange(selectedCompany.active === 0)}
                alt="Activate Button"
              />
              <img
                src={images["bonusBtn"]}
                className={`activate-btn ${
                  selectedCompany.active === 0 ? "inactive" : ""
                }`}
                onClick={() => {
                  if (selectedCompany.active !== 0) {
                    setOpenBonusModal(true)
                  }
                }}
                alt="Bonus Button"
              />
            </div>
          </Col>
        </Row>
        <div className="service-media">
          <div className="section-title">Media e colori</div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6} xl={4}>
              <ImageUploadComponent
                label="Logo"
                imageType="logo"
                value={logo}
                onImageSelect={(e) => handleImageSelect(e, "logo")}
                selectedCompany={selectedCompany}
              />
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <ImageUploadComponent
                label="Icon"
                imageType="icon"
                value={icon}
                onImageSelect={(e) => handleImageSelect(e, "icon")}
                selectedCompany={selectedCompany}
              />
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <ImageUploadComponent
                label="Logo scontrino"
                value={logoScontrino}
                imageType="scontrino"
                onImageSelect={(e) => handleImageSelect(e, "logoScontrino")}
                selectedCompany={selectedCompany}
              />
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <ImageUploadComponent
                label="Background"
                value={bgImage}
                imageType="background"
                onImageSelect={(e) => handleImageSelect(e, "bgImage")}
                selectedCompany={selectedCompany}
              />
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="colorBg" label="Colore background">
                <Input
                  value={colorBg}
                  className="color-input"
                  onChange={(e) => setColorBg(e.target.value)}
                  prefix={
                    <input
                      type="color"
                      onChange={(e) => setColorBg(e.target.value)}
                      value={colorBg}
                      style={{ width: 30, height: 30 }}
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="colorTesto" label="Colore Testo">
                <Input
                  value={colorTesto}
                  className="color-input"
                  onChange={(e) => setColorTesto(e.target.value)}
                  prefix={
                    <input
                      type="color"
                      onChange={(e) => setColorTesto(e.target.value)}
                      value={colorTesto}
                      style={{ width: 30, height: 30 }}
                    />
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="service-variables">
          <div className="section-title">Servizi e variabili</div>
          <Form.Item name="selectedService">
            <div className="services-row">
              <Row
                gutter={16}
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: "100%",
                }}
              >
                {selectedCompany?.services?.map((service, index) => (
                  <Col
                    xs={24}
                    sm={12}
                    md={3}
                    xl={2}
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingRight: "0px",
                    }}
                    className="service-col"
                  >
                    <Card
                      onClick={() => handleChangeService(index)}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedService === index ? "var(--accent-bg)" : "#fff",
                        width: "100%",
                        height: "66px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <div className="service-cost">{service.cost}</div>
                      <div className="service-sign">Euro</div>
                    </Card>
                  </Col>
                ))}

                <Col
                  xs={24}
                  sm={12}
                  md={3}
                  xl={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingRight: "0px",
                  }}
                >
                  <Card
                    onClick={() => handleChangeService("default")}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedService === "default" ? "var(--accent-bg)" : "#fff",
                      width: "100%",
                      height: "66px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="service-cost">Tutti</div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form.Item>

          <Row
            gutter={[16, 16]}
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            className="service-commissione"
          >
            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="commissioni" label="Commissioni">
                <Input
                  value={formData[selectedServiceId]?.commissioni}
                  onChange={(e) =>
                    handleFieldChange(
                      selectedServiceId,
                      "commissioni",
                      e.target.value
                    )
                  }
                  placeholder="Commissioni"
                  suffix={<i className="far fa-euro-sign"></i>}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="provvigioni" label="Provvigioni agenzia">
                <Input
                  value={formData[selectedServiceId]?.provvigioni}
                  onChange={(e) =>
                    handleFieldChange(
                      selectedServiceId,
                      "provvigioni",
                      e.target.value
                    )
                  }
                  placeholder="Provvigioni agenzia"
                  suffix={<i className="far fa-percent"></i>}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="bonus" label="Bonus agenzia">
                <Input
                  className="bonus-ag"
                  placeholder="Bonus agenzia"
                  suffix={
                    <i
                      className="far fa-cog"
                      onClick={() => setOpenBonusModal(true)}
                    />
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="aggio" label="Aggio netto">
                <Input placeholder="Aggio" />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            className="service-commissione"
          >
            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="iva" label="IVA" className="iva">
                <Input
                  placeholder="IVA"
                  value={formData[selectedServiceId]?.iva}
                  onChange={(e) =>
                    handleFieldChange(selectedServiceId, "iva", e.target.value)
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item
                name="descrizione"
                label="Descrizione IVA"
                className="descrizione"
              >
                <Input placeholder="Descrizione IVA" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="service-information">
          <Row>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="section-title">Informazioni</div>
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Guida all’uso" key="1">
                  <ReactQuill
                    value={content}
                    onChange={(e) => handleQuillChange(e, false)}
                    modules={modules}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Informazioni scontrino" key="2">
                  <ReactQuill
                    value={scontrinoContent}
                    onChange={(e) => handleQuillChange(e, true)}
                    modules={modules}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>

        <Row
          gutter={[16, 16]}
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
          className="form-buttons"
        >
          <Col xs={24} sm={12} md={6} xl={4}>
            <Button block type="default" onClick={() => history.goBack()}>
              CANCELLA
            </Button>
          </Col>

          <Col xs={24} sm={12} md={6} xl={4}>
            <Button block type="primary" onClick={handleSubmit}>
              SALVA
            </Button>
          </Col>
        </Row>
      </div>
    </>
  )
}

const EditServiceForm = Form.create()(EditService)

const mapStateToProps = (state) => ({
  selectedCompany: state.main.selectedCompany,
  activeSkinId: state.main.activeSkinId,
})

export default connect(mapStateToProps, { ...AuthActions, ...MainActions })(
  EditServiceForm
)
