import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { Area, Column } from "@ant-design/plots"
import { Spin } from "antd"
import moment from "moment"

class StatisticheMensili extends React.Component {
  constructor() {
    super()
    this.state = {
      date: new Date(),
    }
  }

  graphForMap = (graphData) => {
    let arrayForMap = []
    if (graphData) {
      Object.keys(graphData).forEach((key) => {
        arrayForMap.push({
          value: parseFloat(graphData[key].replace(".", "").replace(",", ".")) / 100,
          month: parseInt(key.substring(0, key.indexOf("_"))),
          day: parseInt(key.substring(key.indexOf("_") + 1, key.length)).toString(),
          realValue: graphData[key] + " €",
        })
      })
    }
    return { arrayForMap }
  }

  render() {
    let { statistiche, loadingStatistiche } = this.props
    let data = []
    if (statistiche) {
      const length = Object.keys(statistiche).length
      if (length <= 31) {
        data = this.graphForMap(statistiche).arrayForMap
      } else {
        data = []
      }
    }

    const config = {
      data,
      padding: "auto",
      xField: "day",
      yField: "value",
      smooth: true,
      color: "#0078FF",
      lineStyle: {
        shadowColor: "#0078FF",
        shadowBlur: 10,
        shadowOffsetX: 1,
        shadowOffsetY: 3,
        cursor: "pointer",
      },
      maxColumnWidth: 30,
      tooltip: {
        customContent: (title, data) => {
          return `<div>${data[0]?.data?.value + "€"}</div>`
        },
      },
      // onReady: (plot) => {
      //   plot.on("tooltip:change", (data) => {
      //     this.setState({
      //       date: new Date(
      //         `${data.data.items[0].data.month}-${
      //           data.data.items[0].data.day
      //         }-${new Date().getFullYear()}`
      //       ),
      //     });
      //   });
      // },
      areaStyle: () => {
        return {
          fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
        }
      },
    }

    return (
      data.length !== 0 && (
        <div className="statistiche-mensili">
          <div className="statistiche-header">Andamento delle vendite</div>
          {loadingStatistiche ? (
            <Spin tip="Loading" size="large">
              <div style={{ height: "135px" }} className="content" />
            </Spin>
          ) : (
            <Column
              {...config}
              onReady={(plot) => {
                plot.chart.on("plot:click", (evt) => {
                  const { x, y } = evt
                  const data = plot.chart.getTooltipItems({ x, y })
                  this.setState({
                    date: new Date(
                      `${data[0].data.month}-${
                        data[0].data.day
                      }-${new Date().getFullYear()}`
                    ),
                  })
                  const date1 = moment(this.state.date).format("YYYY-MM-DD")
                  this.props.onDateChange(date1)
                  this.props.getPayments(
                    "",
                    date1,
                    date1,
                    1,
                    25,
                    this.props.activeSkinId,
                    "",
                    ""
                  )
                  // this.props.getStatistiche(
                  //   this.props.activeSkinId,
                  //   date1 || "",
                  //   date1 || "",
                  // );
                })
              }}
            />
          )}
        </div>
      )
    )
  }
}
const mapStateToProps = (state) => ({
  statistiche: state.auth.Statistiche?.data,
  activeSkinId: state.main.activeSkinId,
  loadingStatistiche: state.auth.loadingStatistiche,
})
export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  StatisticheMensili
)
