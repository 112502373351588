import React, { useEffect, useState } from "react"
import { skin } from "config/api"
import { connect } from "react-redux"
import MainActions from "redux-store/models/main"
import AuthActions from "redux-store/models/auth"
import { capitalize } from "lodash"
import $ from "jquery"
import images from "themes/images"
import { removeUnwantedCompanies, isSepafinSelector } from "utils/HelperFunc"
import CompaniesCheck from "routes/domains/Dashboard/CompaniesCheck"
import { baseUrlImg } from "../../../config/api"

const ricaricheTelefonicheOptions = [
  {
    optionId: "RTELD",
    label: "Ricarica SIM",
    image: images["ricaricaSIM"],
  },
  {
    optionId: "RTELC",
    label: "Ricarica con PIN",
    image: images["ricaricaPIN"],
  },
]

const PrenotazioniItem = ({ src, link, name, backgroundColor }) => {
  return (
    <div
      className={`mobileServices--body__item ${name}`}
      style={{ backgroundColor: backgroundColor }}
    >
      <img
        src={src}
        onClick={() => {
          window.location.hash = `/forms/${link}`
        }}
        alt={link}
      />

      <span className="prenotazioni-span">{name}</span>
    </div>
  )
}

const OneTab = ({
  serviceCategory,
  services,
  panelOpen,
  setPanelOpen,
  setService,
  setCategory,
  accountInfo,
  isSepafin,
  isFav,
}) => {
  const [open, setDD] = useState(panelOpen === serviceCategory)
  const [optionId, setOptionId] = useState("RTELD")

  const pagaamentiCategory = isSepafin ? "SPRDPST" : "PRDPST"
  const isRicaricheTelefoniche = ["RTELC", "RTELD"].includes(serviceCategory)

  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const isEPay = accountData?.profile?.supplier === "E-Pay"

  useEffect(() => {
    if (panelOpen !== serviceCategory) {
      setDD(false)
    } else {
      setDD(true)
    }
  }, [panelOpen, serviceCategory])

  return (
    services && (
      <div className="mobileServices--panel">
        <div
          id={
            serviceCategory && serviceCategory.includes(" ")
              ? serviceCategory.split(" ").join("")
              : serviceCategory
          }
          className={"mobileServices--header" + (open ? " active" : "")}
          onClick={() => {
            if (!open) {
              setPanelOpen(serviceCategory)
              setDD(true)
            } else {
              setDD(false)
            }
          }}
        >
          <span>
            {" "}
            {isFav && "Preferiti "}
            {capitalize(
              services[serviceCategory].name.toLowerCase() === "spediz"
                ? "Spedizioni"
                : serviceCategory === "RTELD"
                ? "Ricariche Telefoniche"
                : services[serviceCategory].name
            )}
          </span>
          <i
            className={`fal fa-chevron-${
              open && panelOpen === serviceCategory ? "up" : "down"
            }`}
            aria-hidden="true"
          ></i>
        </div>
        {open && panelOpen === serviceCategory && (
          <div className="mobileServices--body animated fadeIn">
            {isRicaricheTelefoniche ? (
              <div className="ricariche-telefoniche">
                <div className="ricariche-telefoniche-options">
                  {ricaricheTelefonicheOptions.map((option) => (
                    <div
                      key={option.optionId}
                      onClick={() => {
                        if (accountInfo?.profile) {
                          setOptionId(option.optionId)
                        } else {
                          window.location.hash = "login"
                        }
                      }}
                      className={`mobileServices--body__item ${option.optionId} ${
                        optionId === option.optionId ? "active" : ""
                      }`}
                    >
                      <img src={images[option.optionId]} alt={option.label} />
                      <span>{option.label}</span>
                    </div>
                  ))}
                </div>
                <div className="ricariche-telefoniche-services">
                  {services[optionId] ? (
                    removeUnwantedCompanies(
                      Object.keys(services[optionId]),
                      (comp) => ({
                        companyKeyID: services[optionId][comp].service_id,
                        companyName: services[optionId][comp].name,
                      })
                    ).map((serviceId) => {
                      const companyServices = services[optionId][serviceId]
                      return (
                        optionId !== pagaamentiCategory &&
                        serviceId !== "name" &&
                        serviceId !== "group" && (
                          <div
                            onClick={() => {
                              if (accountInfo?.profile) {
                                setService(serviceId)
                                setCategory(optionId)
                              } else {
                                window.location.hash = "login"
                              }
                            }}
                            key={serviceId}
                            data-key={serviceId}
                            className={`mobileServices--body__item ${companyServices.name}`}
                          >
                            <img
                              src={
                                isEPay
                                  ? `${baseUrlImg}/${
                                      companyServices?.services[0]?.icon ||
                                      "uncategorized/placeholder.jpg"
                                    }`
                                  : companyServices?.services[0]?.icon
                                  ? `${baseUrlImg}/${companyServices?.services[0]?.icon}`
                                  : images[serviceId]
                              }
                              alt={serviceId + "||||" + companyServices.service_id}
                            />
                            <span>
                              {" "}
                              {companyServices.name
                                .replace(/TOPUP/g, "")
                                .replace(/PIN/g, "")}
                            </span>
                          </div>
                        )
                      )
                    })
                  ) : (
                    <div style={{ justifyContent: "center" }}>No preferiti</div>
                  )}
                </div>
              </div>
            ) : (
              removeUnwantedCompanies(
                Object.keys(services[serviceCategory]),
                (comp) => ({
                  companyKeyID: services[serviceCategory][comp].service_id,
                  companyName: services[serviceCategory][comp].name,
                })
              ).map((serviceId) => {
                const companyServices = services[serviceCategory][serviceId]
                const companyServiceId = () =>
                  companyServices.services[0]?.service_id
                const bollimg = Array.isArray(companyServices)
                  ? companyServiceId()
                  : images["BOLL"]
                return (
                  serviceCategory !== pagaamentiCategory &&
                  serviceId !== "name" &&
                  serviceId !== "group" && (
                    <div
                      onClick={() => {
                        if (accountInfo?.profile) {
                          setService(serviceId)
                          setCategory(serviceCategory)
                        } else {
                          window.location.hash = "login"
                        }
                      }}
                      key={serviceId}
                      data-key={serviceId}
                      className={`mobileServices--body__item ${companyServices.name}`}
                    >
                      <img
                        src={
                          isEPay
                            ? `${baseUrlImg}/${
                                companyServices?.services[0]?.icon ||
                                "uncategorized/placeholder.jpg"
                              }`
                            : companyServices?.services[0]?.icon
                            ? `${baseUrlImg}/${companyServices?.services[0]?.icon}`
                            : images[serviceId]
                        }
                        alt={serviceId + "||||" + companyServices.service_id}
                      />
                      <span>
                        {" "}
                        {companyServices.name
                          .replace(/TOPUP/g, "")
                          .replace(/PIN/g, "")}
                      </span>
                    </div>
                  )
                )
              })
            )}

            {serviceCategory === pagaamentiCategory &&
              Object.keys(services[pagaamentiCategory]).map((item) => {
                const comapnyPagamenti = services[pagaamentiCategory][item]
                return item !== "name" &&
                  item !== "group" &&
                  ["BOL001", "SBOL001"].includes(
                    comapnyPagamenti.services[0].service_id
                  )
                  ? comapnyPagamenti.services.map((serviceI) => (
                      <CompaniesCheck
                        className="mobileServices--body__item"
                        id={item}
                        key={serviceI.service_id}
                        submit={() => {
                          if (accountInfo?.profile) {
                            setService(serviceI.service_id)
                            setCategory(pagaamentiCategory)
                          } else {
                            window.location.hash = "login"
                          }
                        }}
                        service={serviceI}
                      />
                    ))
                  : item !== "name" && item !== "group" && (
                      <CompaniesCheck
                        className="mobileServices--body__item"
                        id={item}
                        key={comapnyPagamenti.services[0].service_id}
                        submit={() => {
                          if (accountInfo?.profile) {
                            setService(comapnyPagamenti.services[0].service_id)
                            setCategory(pagaamentiCategory)
                          } else {
                            window.location.hash = "login"
                          }
                        }}
                        service={comapnyPagamenti}
                      />
                      // <div
                      //   className="mobileServices--body__item"
                      //   onClick={() => {
                      //     if (accountInfo?.profile) {
                      //       setService(services[pagaamentiCategory][item].services[0].service_id);
                      //       setCategory(pagaamentiCategory);
                      //     } else {
                      //       window.location.hash = "login";
                      //     }
                      //   }}
                      //   key={services[pagaamentiCategory][item].services[0].service_id}
                      // >
                      //   <img
                      //     src={
                      //       images[
                      //         services[pagaamentiCategory][item].services[0].service_id === "PPA001"
                      //           ? `${services[pagaamentiCategory][item].services[0].service_id}-Black-Mobile`
                      //           : `${item === "RCPP" ? "RCPP" : "BOLL"}${item === "RCPP" ? "" : "-Black-Mobile"}`
                      //       ]
                      //     }
                      //     alt={services[pagaamentiCategory][item].services[0].service_id}
                      //   />
                      //   <span>{services[pagaamentiCategory][item].name} </span>
                      // </div>
                    )
              })}
          </div>
        )}
      </div>
    )
  )
}

export const updateSearchParam = (key, value) => {
  const url = (window.location?.hash || "").substring(
    (window.location?.hash || "").lastIndexOf("?") + 1
  )
  const urlSearchParams = new URLSearchParams(url || "")
  let searchParams = urlSearchParams
  if (!value) {
    searchParams.delete(key)
  } else {
    searchParams.set(key, value)
  }
  const search = searchParams.toString().trim()
  const { pathname, hash } = window.location
  const base = (hash || "").substring(0, (hash || "").indexOf("?"))
  let newRelativePathQuery = pathname + base + (search ? "?" : "") + search
  window.history.pushState(null, "", newRelativePathQuery)
}

const Servizi = ({
  services,
  serviceSearched,
  tab,
  setService,
  setCategory,
  accountInfo,
  setTab,
  favorites,
  setScannedBarcode,
  isSepafin,
}) => {
  const [panelOpen, setPanelOpen] = useState(null)
  const search = (window.location?.hash || "").substring(
    (window.location?.hash || "").lastIndexOf("?") + 1
  )
  const click = new URLSearchParams(search).get("click")
  const qrCode = new URLSearchParams(search).get("qr-code")
  // console.log(qrCode)

  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const isEPay = accountData?.profile?.supplier === "E-Pay"

  const clickFunct = async (options = []) => {
    const categoryDom = await $(`#${(options[0] || "").replace(" ", ".")}`)
    if (categoryDom) await categoryDom.click()
    await setTimeout(async () => {
      const serviceDom = await $(`.${(options[1] || "").replace(" ", ".")}`)
      if (serviceDom) await serviceDom.click()
    }, 300)
  }
  // console.log(search,click,"click")

  if (qrCode && !click) {
    setScannedBarcode(qrCode)

    updateSearchParam("qr-code", "")
  }
  if (click) {
    const clickOptions = click.split(",")

    clickFunct(clickOptions)
    updateSearchParam("click", "")
  }

  return (
    <div className="mobileServices">
      {serviceSearched.length > 0 && tab !== "8" ? (
        <div className="searchContainer">
          {/*search servizi*/}
          {Object.keys(services).map((types) => {
            if (types !== "name" && types !== "group") {
              return Object.keys(services[types]).map((company) => {
                return (
                  services[types][company]?.name
                    ?.toLowerCase?.()
                    ?.includes?.(serviceSearched.toLowerCase()) && (
                    <div
                      onClick={() => {
                        setService(company)
                        setCategory(types)
                      }}
                      className={`mobileServices--body__item ${services[types][company]?.name}`}
                      key={company}
                      alt={`${company}`}
                    >
                      <img
                        src={
                          isEPay
                            ? `${baseUrlImg}/${
                                services[types][company]?.services[0]?.icon ||
                                "uncategorized/placeholder.jpg"
                              }`
                            : services[types][company]?.services[0]?.iconn
                            ? `${baseUrlImg}/${services[types][company]?.services[0]?.icon}`
                            : images[company]
                        }
                        alt=""
                      />
                      <span>{services[types][company]?.name}</span>
                    </div>
                  )
                )
              })
            } else {
              return null
            }
          })}
        </div>
      ) : (
        <>
          {/* {tab === "8" && <MammaneroCaffeDomain />} */}
          {Object.keys(services)
            .filter(
              (service) =>
                !["altri servizi", "indaco", "infoc", "billex", "icsfr"].includes(
                  services[service].name.toLowerCase()
                )
            )
            .sort((a, b) => {
              if (services[a].name.toLowerCase() === "pagamenti") return -1
              if (services[b].name.toLowerCase() === "pagamenti") return 1
              return 0
            })
            .map((serviceCategory) => {
              if (
                serviceCategory.toString().toLowerCase() === "altri" &&
                skin.skin_id === 4
              ) {
                return (
                  <div className="mobileServices--header">
                    <span>
                      <a key="AltriDefault" href="https://planetservizi.it/servizi/">
                        Altri servizi
                      </a>
                    </span>
                  </div>
                )
              }
              const name = services[serviceCategory].name.toLowerCase()
              const removedServicesName = [
                "visure",
                "uncategorized",
                "ricariche telefoniche pin",
                "spedizione",
                "servizi su prenotazione",
              ]
              return (
                name.includes(serviceSearched.toLowerCase()) &&
                !removedServicesName.includes(name.toLowerCase()) &&
                (tab === "0" || tab.includes(serviceCategory)) && (
                  <OneTab
                    setService={setService}
                    panelOpen={panelOpen}
                    isSepafin={isSepafin}
                    setPanelOpen={setPanelOpen}
                    key={serviceCategory}
                    serviceCategory={serviceCategory}
                    services={services}
                    setCategory={setCategory}
                    accountInfo={accountInfo}
                  />
                )
              )
            })}

          {tab === "4" && (
            <div className="mobileServices--panel">
              <div id="PRNOT" className="mobileServices--header active">
                <span>Prenotazioni</span>{" "}
                <i className="fal fa-chevron-down" aria-hidden="true"></i>
              </div>
              <div className="mobileServices--body animated fadeIn">
                <PrenotazioniItem
                  src={images["expedia-mobile"]}
                  name="Expedia"
                  link="expedia"
                  backgroundColor="#FFC60B"
                />
                <PrenotazioniItem
                  src={images["flixbus-mobile"]}
                  name="Flixbus"
                  link="flixbus"
                  backgroundColor="#73D800"
                />
                <PrenotazioniItem
                  src={images["trenitalia-mobile"]}
                  name="Trenitalia"
                  link="trenitalia"
                  backgroundColor="#BF0013"
                />
                {/* <PrenotazioniItem src={images["vivaticket-mobile"]} name="Vivaticket" link="vivaticket" /> */}
                {/* {skin?.skin_id !== 4 && (
                  <PrenotazioniItem src={images["ticketone-mobile"]} name="Ticketone" link="ticketone" />
                )}  */}
                <PrenotazioniItem
                  src={images["biglietti-mobile"]}
                  name="Biglietti"
                  link="biglietti"
                  backgroundColor="#DD738C"
                />
                {/* <PrenotazioniItem src={images["stubhub-mobile"]} name="Stubhub" link="stubhub" /> */}
                <PrenotazioniItem
                  src={images["shop-online-mobile"]}
                  name="Online Shop"
                  link="shop-online"
                  backgroundColor="#0089A2"
                />
                <PrenotazioniItem
                  src={images["auto-mobile"]}
                  name="Nolegio Autto"
                  link="auto-mobile"
                  backgroundColor="#22A094"
                />
                <PrenotazioniItem
                  src={images["auto-mobile"]}
                  name="Assicurazioni"
                  link="assicurazioni"
                  backgroundColor="#262626"
                />
                {/* <PrenotazioniItem src={images["bgame-mobile"]} name="Registrazione" link="bgame" /> */}
                <PrenotazioniItem
                  src={images["luce-gas-mobile"]}
                  name="Luce - Gas"
                  link="luce-gas"
                  backgroundColor="#18819B"
                />
                <PrenotazioniItem
                  src={images["finanziamenti-mobile"]}
                  name="Finanziamenti"
                  link="finanziamenti"
                  backgroundColor="#2075FC"
                />
                <PrenotazioniItem
                  src={images["enagic-mobile"]}
                  name="Enagic"
                  link="enagic"
                  backgroundColor="#004DA1"
                />
                <PrenotazioniItem
                  src={images["facileristrutturare-mobile"]}
                  name="Facileristrutturare"
                  link="facileristrutturare"
                  backgroundColor="#F19302"
                />
                {/* <PrenotazioniItem src={images["visure"]} name="Visure" link="visure" /> */}
              </div>
            </div>
          )}

          {favorites && (
            <div className="mobileServices--favorites">
              {Object.keys(favorites).map((serviceCategory) => {
                return (
                  favorites[serviceCategory].name
                    .toLowerCase()
                    .includes(serviceSearched.toLowerCase()) &&
                  tab === "fav" && (
                    <OneTab
                      setService={setService}
                      panelOpen={panelOpen}
                      setPanelOpen={setPanelOpen}
                      key={serviceCategory}
                      serviceCategory={serviceCategory}
                      services={favorites}
                      setCategory={setCategory}
                      accountInfo={accountInfo}
                      isFav
                    />
                  )
                )
              })}
            </div>
          )}
        </>
      )}
    </div>
  )
}

const mstp = (state, props) => {
  const {
    main: { services },
    auth: { accountInfo },
  } = state
  return {
    services: props.services || services,
    isSepafin: isSepafinSelector(state),
    accountInfo,
  }
}

export default connect(mstp, { ...MainActions, ...AuthActions })(Servizi)
