import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import CalendarRangePicker from "shared-components/CalendarRangePicker/CalendarRangePicker"
import moment from "moment"
import { Icon, Modal, Pagination, Select, Table } from "antd"
import { isArray } from "lodash"
import ModalResponsiveForTables from "shared-components/ModalResponsiveForTables/ModalResponsiveForTables"
import ModalRow from "shared-components/ModalResponsiveForTables/ModalRow"
import { numberWithCommas } from "utils/HelperFunc"

const { Option } = Select
const { confirm } = Modal

const NewDepositiAdmin = (props) => {
  const {
    getRequestedRefills,
    accountInfo,
    activeSkinId,
    loadingPayments,
    screenWidth,
    refills,
    refillsPages,
    fromDate,
    MessaggiComponent,
    forAdmin,
    requireRefillStatus,
  } = props

  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [fromLabel, setFromLabel] = useState("")
  const [toLabel, setToLabel] = useState("")
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [perPage, setPerPage] = useState(25)
  const [showModalResponsive, setShowModalResponsive] = useState(false)
  const [modalDetails, setModalDetails] = useState("")
  const [picker, setPicker] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "var(--accent-bg)",
    },
  ])

  const setCalendar = (val) => {
    setIsCalendarOpen(val)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    props.getRequestedRefills(
      1,
      perPage,
      accountInfo?.profile?.role?.name === "super_admin"
        ? accountInfo.profile.activity_id
        : activeSkinId !== -1
        ? activeSkinId
        : null,
      from,
      to
    )
  }

  const showConfirmAccept = (id) => {
    confirm({
      title: "Pagamento ricevuto! Accetta e ricarica il conto.",
      cancelText: "Cancella",
      onOk() {
        requireRefillStatus(id, "accepted")
      },
      onCancel() {},
    })
  }

  const showConfirmDecline = (id) => {
    confirm({
      title: "Vuoi annullare questa richiesta di ricarica conto?",
      cancelText: "Cancella",
      onOk() {
        requireRefillStatus(id, "rejected")
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    getRequestedRefills(
      1,
      25,
      accountInfo?.profile?.role?.name === "super_admin"
        ? accountInfo.profile.activity_id
        : activeSkinId !== -1
        ? activeSkinId
        : null
    )
  }, [])

  useEffect(() => {
    getRequestedRefills(
      1,
      perPage,
      accountInfo?.profile?.role?.name === "super_admin"
        ? accountInfo.profile.activity_id
        : activeSkinId != -1
        ? activeSkinId
        : null,
      from || "",
      to || ""
    )
  }, [activeSkinId, fromDate])

  const columns =
    screenWidth > 1024
      ? [
          {
            title: "Eseguito il",
            dataIndex: "eseguito_il",
            key: "eseguito_il",
            ellipsis: true,
            width: "12%",
            render: (text) => moment(text).format("DD/MM/YY HH:mm"),

          },
          {
            title: "Barcode",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            width: "12%",
            align: "center",
          },
          {
            title: "DAL",
            dataIndex: "mittente",
            key: "mittente",
            ellipsis: true,
            width: "12%",
            align: "center",
          },
          {
            title: "",
            dataIndex: "",
            key: "",
            width: "5%",
            ellipsis: true,
            align: "center",
            render: (text, record) =>
              record.metodo === "Deposito" ||
              record.metodo === "Bonifico Bancario" ? (
                <i className="fal fa-arrow-left" style={{ fontSize: 18 }}></i>
              ) : (
                <i className="fal fa-arrow-right" style={{ fontSize: 18 }}></i>
              ),
          },
          {
            title: "AL",
            dataIndex: "destinatario",
            key: "destinatario",
            width: "10%",
            ellipsis: true,
            align: "center",
          },
          {
            title: "Metodo",
            dataIndex: "metodo",
            key: "metodo",
            width: "10%",
            ellipsis: true,
            align: "center",
          },
          {
            title: "Importo",
            dataIndex: "amount",
            key: "amount",
            align: "right",
            width: "10%",
            ellipsis: true,
            render: (text, record) => (
              <div>
                {record.metodo === "Deposito" ||
                record.metodo === "Bonifico Bancario" ? (
                  <span>
                    <i
                      className="far fa-plus-circle fa-sm"
                      style={{ color: "#0DA90F" }}
                    ></i>{" "}
                    {text}€
                  </span>
                ) : (
                  <span>
                    <i
                      className="far fa-minus-circle fa-sm"
                      style={{ color: "#FF0000" }}
                    ></i>{" "}
                    {text}€
                  </span>
                )}
              </div>
            ),
          },
          {
            title: "Commisione",
            dataIndex: "commissione",
            key: "commissione",
            align: "right",
            width: "10%",
            ellipsis: true,
            render: (text) => <span>{text.toFixed(2)}€</span>,
          },
          {
            title: "Ricevuta",
            dataIndex: "document",
            key: "document",
            width: "10%",
            align: "center",
            ellipsis: true,
            render: (text, record) =>
              record.document ? (
                <a style={{ textDecoration: "underline" }}>Apri l'allegato</a>
              ) : (
                "Non prevenuto"
              ),
          },
          {
            title: "Stato",
            dataIndex: "stato",
            key: "stato",
            width: "5%",
            ellipsis: true,
            align: "center",
            render: (text, record) =>
              accountInfo?.profile?.role?.name === "super_admin" &&
              text === "pending" ? (
                <div>
                  <Icon
                    style={{ fontSize: "large", color: "#0da90f" }}
                    onClick={() => showConfirmAccept(record.id)}
                    type="check-circle"
                  />
                  <Icon
                    style={{ fontSize: "large", color: "#ff0000" }}
                    onClick={() => showConfirmDecline(record.id)}
                    type="close-circle"
                  />
                </div>
              ) : text === "accepted" ? (
                forAdmin ? (
                  <Icon
                    style={{ fontSize: "large", color: "#0da90f" }}
                    type="check-circle"
                  />
                ) : (
                  "Accettato"
                )
              ) : text === "rejected" ? (
                forAdmin ? (
                  <Icon
                    style={{ fontSize: "large", color: "#ff0000" }}
                    type="close-circle"
                  />
                ) : (
                  "Rifiutato"
                )
              ) : (
                "Pending"
              ),
            // text === "accepted" ? (
            //   <Icon
            //     style={{ fontSize: "large", color: "#0da90f" }}
            //     type="check-circle"
            //   />
            // ) : text === "rejected" ? (
            //   <Icon
            //     style={{ fontSize: "large", color: "#ff0000" }}
            //     type="close-circle"
            //   />
            // ) : (
            //   "Pending"
            // ),
          },
          {
            title: "Caricato il",
            dataIndex: "caricato_il",
            key: "caricato_il",
            width: "10%",
            ellipsis: true,
            render: (text) => moment(text).format("DD/MM/YY HH:mm"),
          },
        ]
      : screenWidth > 600
      ? [
          {
            title: "Eseguito il",
            dataIndex: "eseguito_il",
            key: "eseguito_il",
            ellipsis: true,
            width: "12%",
            render: (text) => moment(text).format("DD/MM/YY HH:mm"),

          },
          {
            title: "DAL",
            dataIndex: "mittente",
            key: "mittente",
            ellipsis: true,
            width: "12%",
            align: "center",
          },
          {
            title: "",
            dataIndex: "",
            key: "",
            width: "5%",
            ellipsis: true,
            align: "center",
            render: (text, record) =>
              record.metodo === "Deposito" ||
              record.metodo === "Bonifico Bancario" ? (
                <i className="fal fa-arrow-left" style={{ fontSize: 18 }}></i>
              ) : (
                <i className="fal fa-arrow-right" style={{ fontSize: 18 }}></i>
              ),
          },
          {
            title: "AL",
            dataIndex: "destinatario",
            key: "destinatario",
            width: "10%",
            ellipsis: true,
            align: "center",
          },
          {
            title: "Stato",
            dataIndex: "stato",
            key: "stato",
            width: "5%",
            ellipsis: true,
            align: "center",
            render: (text, record) =>
              text === "accepted" ? (
                <Icon
                  style={{ fontSize: "large", color: "#0da90f" }}
                  type="check-circle"
                />
              ) : text === "rejected" ? (
                <Icon
                  style={{ fontSize: "large", color: "#ff0000" }}
                  type="close-circle"
                />
              ) : (
                "Pending"
              ),
          },
          {
            title: "Importo",
            dataIndex: "amount",
            key: "amount",
            align: "right",
            width: "10%",
            ellipsis: true,
            render: (text, record) => (
              <div>
                {record.metodo === "Deposito" ||
                record.metodo === "Bonifico Bancario" ? (
                  <span>
                    <i
                      className="far fa-plus-circle fa-sm"
                      style={{ color: "#0DA90F" }}
                    ></i>{" "}
                    {text}€
                  </span>
                ) : (
                  <span>
                    <i
                      className="far fa-minus-circle fa-sm"
                      style={{ color: "#FF0000" }}
                    ></i>{" "}
                    {text}€
                  </span>
                )}
              </div>
            ),
          },
          {
            title: "",
            dataIndex: "",
            key: "",
            width: "5%",
            align: "center",
            ellipsis: true,
            render: (text, record) => (
              <i
                className="fal fa-eye"
                style={{ color: "#01a81b", fontWeight: 600 }}
              ></i>
            ),
            onCell: (record) => {
              return {
                onClick: (ev) => {
                  setShowModalResponsive(true)
                  setModalDetails(record)
                },
              }
            },
          },
        ]
      : [
          {
            title: "Eseguito il",
            dataIndex: "eseguito_il",
            key: "eseguito_il",
            ellipsis: true,
            width: "20%",
            render: (text) => moment(text).format("DD/MM/YY HH:mm"),

          },
          {
            title: "DAL",
            dataIndex: "mittente",
            key: "mittente",
            ellipsis: true,
            width: "20%",
            align: "center",
          },
          {
            title: "",
            dataIndex: "",
            key: "",
            width: "10%",
            ellipsis: true,
            align: "center",
            render: (text, record) =>
              record.metodo === "Deposito" ||
              record.metodo === "Bonifico Bancario" ? (
                <i className="fal fa-arrow-left" style={{ fontSize: 18 }}></i>
              ) : (
                <i className="fal fa-arrow-right" style={{ fontSize: 18 }}></i>
              ),
          },
          {
            title: "AL",
            dataIndex: "destinatario",
            key: "destinatario",
            width: "20%",
            ellipsis: true,
            align: "center",
          },
          {
            title: "",
            dataIndex: "",
            key: "",
            width: "10%",
            align: "center",
            ellipsis: true,
            render: (text, record) => (
              <i
                className="fal fa-eye"
                style={{ color: "#01a81b", fontWeight: 600 }}
              ></i>
            ),
            onCell: (record) => {
              return {
                onClick: (ev) => {
                  setShowModalResponsive(true)
                  setModalDetails(record)
                },
              }
            },
          },
        ]

  const data =
    refills &&
    isArray(refills) &&
    refills?.map((r) => ({
      eseguito_il: r.eseguito_il,
      id: r.id,
      mittente: r.mittente,
      destinatario: r.destinatario,
      metodo: r.metodo,
      amount: r.amount,
      commissione: r.commissione,
      document: r.document,
      stato: r.stato,
      caricato_il: r.caricato_il,
    }))

  return (
    <div
      className="Container Annunci AllTransazioni NewDepositi"
      style={{ paddingBottom: screenWidth < 500 && "100px", width: "100%" }}
    >
      {MessaggiComponent}
      <div className="container-fluid overview">
        <div className="panels-container">
          {isCalendarOpen && (
            <CalendarRangePicker
              setStateFunc={(item) => {
                setPicker([item.selection])
                setFrom(moment(item.selection.startDate).format("YYYY-MM-DD"))
                setTo(moment(item.selection.endDate).format("YYYY-MM-DD"))
                setFromLabel(moment(item.selection.startDate).format("DD/MM/YYYY"))
                setToLabel(moment(item.selection.endDate).format("DD/MM/YYYY"))
              }}
              setStateFuncEmpty={() => {
                setFrom("")
                setTo("")
                setFromLabel("")
                setToLabel("")
              }}
              picker={picker}
              setCalendar={setCalendar}
              handleSubmit={handleSubmit}
            />
          )}
          <div className="sort-annunci adminAnnunci ">
            <h1
              className="headerTop"
              onClick={() => {
                getRequestedRefills(
                  1,
                  25,
                  accountInfo?.profile?.role?.name === "super_admin"
                    ? accountInfo.profile.activity_id
                    : activeSkinId !== -1
                    ? activeSkinId
                    : null
                )
              }}
            >
              DEPOSITI
            </h1>
            <div
              className="dal calendar"
              type="text"
              onClick={() => {
                setCalendar(true)
              }}
            >
              <span>
                {fromLabel ? `${fromLabel} - ${toLabel}` : "Seleziona la data"}
                <i className="fal fa-calendar-alt"></i>
              </span>
            </div>
          </div>
          {loadingPayments && <div className="loaderAdmin"></div>}
          {showModalResponsive === true && screenWidth <= 1024 && (
            <ModalResponsiveForTables
              Close={(e) => {
                setShowModalResponsive(false)
                setModalDetails("")
              }}
              Header={
                <React.Fragment>
                  <i className="fal fa-credit-card" aria-hidden="true"></i>
                  <span>{modalDetails.agency_name}</span>
                </React.Fragment>
              }
              beforeFooter={null}
              Footer={null}
              Rows={
                <React.Fragment>
                  <div className="OtherRow">
                    <ModalRow title="Mittente" data={modalDetails.mittente} />
                    <ModalRow
                      title="Destinatario"
                      data={modalDetails.destinatario}
                    />
                    <ModalRow title="Eseguito" data={modalDetails.eseguito_il} />
                    <ModalRow title="Caricato" data={modalDetails.caricato_il} />
                    <ModalRow title="Metodo" data={modalDetails.metodo} />
                    <ModalRow
                      title="Importo"
                      data={numberWithCommas(modalDetails.amount)}
                    />
                    <ModalRow
                      title="Commissione"
                      data={numberWithCommas(modalDetails.commissione)}
                    />
                    <ModalRow
                      title="Stato"
                      data={
                        modalDetails.stato === "accepted"
                          ? "Accettato"
                          : modalDetails.stato === "rejected"
                          ? "Rifiutato"
                          : "In Attesa"
                      }
                    />
                    <ModalRow
                      title="Ricevuta"
                      data={
                        modalDetails.document ? (
                          <a style={{ textDecoration: "underline" }}>
                            Apri l'allegato
                          </a>
                        ) : (
                          "Non prevenuto"
                        )
                      }
                      handleClick={() => this.info(modalDetails.id)}
                    />
                  </div>
                  {modalDetails.message?.title && (
                    <div className="OtherRow">
                      <ModalRow title="Title" data={modalDetails.message.title} />
                      <ModalRow title="Message" data={modalDetails.message.text} />
                      <ModalRow
                        title="Status"
                        data={
                          modalDetails.message?.importance?.split("_")[1]
                            ? modalDetails.message.importance.split("_")[1] ===
                              "true"
                              ? "READ"
                              : "UNREAD"
                            : "No Record"
                        }
                      />
                      <ModalRow
                        title="Sent"
                        data={modalDetails.message.created_at}
                      />
                      <ModalRow
                        title="Read"
                        data={modalDetails.message.updated_at}
                      />
                    </div>
                  )}
                </React.Fragment>
              }
            />
          )}
          {!loadingPayments && (
            <Table
              style={{ width: "100%" }}
              columns={columns}
              pagination={false}
              showHeader={true}
              dataSource={data}
              rowKey={(record) => record.id}
            />
          )}
          <div className="paginationWrapper">
            <Pagination
              onChange={(e) => {
                getRequestedRefills(
                  e,
                  perPage,
                  accountInfo?.profile?.role?.name === "super_admin"
                    ? accountInfo.profile.activity_id
                    : activeSkinId != -1
                    ? activeSkinId
                    : null,
                  from,
                  to
                )
              }}
              total={
                Object.keys(refillsPages).length === 0
                  ? 1
                  : refillsPages.total_pages * 10
              }
            />
            <Select
              defaultValue={25}
              onChange={(e) => {
                setPerPage(e)
                getRequestedRefills(
                  1,
                  e,
                  accountInfo?.profile?.role?.name === "super_admin"
                    ? accountInfo.profile.activity_id
                    : activeSkinId != -1
                    ? activeSkinId
                    : null,
                  from,
                  to
                )
              }}
            >
              <Option value={10}>10 / Pagina</Option>
              <Option value={25}>25 / Pagina</Option>
              <Option value={50}>50 / Pagina</Option>
            </Select>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
  loadingPayments: state.auth.loadingPayments,
  refills: state.auth.requestedRefills,
  refillsPages: state.auth.refillsPages,
  fromDate: state.auth.fromDate,
  accountInfo: state.auth.accountInfo,
})

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  NewDepositiAdmin
)
