import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { AuthActions, MainActions } from "redux-store/models"
import { getCopy } from "utils"
import moment from "moment"
import { Button, Icon, Input, Table, Tooltip } from "antd"
import { Select, Pagination } from "antd"
import { isArray } from "lodash"
import "./style.css"

const { Option } = Select

export class NewAdminError extends Component {
  state = {
    selectedFilter: 3,
    skin_name: "",
    perPage: 50,
    page_number: 1,
    current_page: 1,
    from: "",
    to: "",
    popUp: false,
    errorId: "",
    description: ""
  }

  componentDidMount() {
    const { activeSkinName } = this.props
    const { perPage, page_number, from, to, description } = this.state
    this.props.getErrors(
      perPage,
      page_number,
      activeSkinName,
      from !== "" ? from : "",
      to !== "" ? to : "",
      description !== "" ? description : "",
    )
  }

  setPopUp = (val, id) => {
    this.setState({ popUp: val, errorId: id })
  }

  componentDidUpdate(prevProps, prevState) {
    const { perPage, page_number, from, to, description } = this.state
    const { activeSkinName } = this.props
    if (this.props.fromDate && this.props.fromDate !== prevProps.fromDate) {
      const label = moment(this.props.fromDate, "DD/MM/YYYY")

      this.setState({
        from: this.props.fromDate,
        to: this.props.fromDate,
        fromLabel: label,
        toLabel: label,
        perPage: 50,
      })
    }
    if ((activeSkinName !== prevProps.activeSkinName) || (description !== prevState.description)) {
      this.props.getErrors(
        perPage,
        page_number,
        activeSkinName,
        from !== "" ? from : "",
        to !== "" ? to : "",
        description !== "" ? description : "",
      )
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.setState({description: selectedKeys[0]})}
          style={{ width: 220, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.setState({description: selectedKeys[0]})}
          icon="search"
          size="small"
          style={{ width: 105, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.setState({description: ""})}
          size="small"
          style={{ width: 105 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) => (this.state.searchedColumn === dataIndex ? text : text),
  })

  render() {
    const { perPage, page_number, current_page, from, to, errorId } = this.state
    const { deleteError, total_pages, getErrors, errors, ErrLoading, screenWidth , activeSkinId} =
      this.props


    var columns =
      screenWidth < 550
        ? [
            {
              title: "Data Ora",
              dataIndex: "full_time",
              key: "full_time",
              ellipsis: true,
              width: "40%",
            },
            // {
            //   title: "Skin",
            //   dataIndex: "skin",
            //   key: "skin",
            //   ellipsis: true,
            //   width: "15%",
            // },
            {
              title: "Causale",
              dataIndex: "description",
              key: "description",
              ellipsis: true,
              width: "45%",
              ...this.getColumnSearchProps("causale"),
              render: (text, record) => (
                <span className="copy-icon" title={text}>
                  <i
                    className="fal fa-clipboard mr-2"
                    onClick={() => {
                      getCopy(text || "")
                    }}
                  ></i>
                  <Tooltip title={text}>{text}</Tooltip>
                </span>
              ),
            },
            {
              title: "Delete",
              dataIndex: "delete",
              key: "delete",
              ellipsis: true,
              width: "15%",
              align: "center",
              render: (text, record) => (
                <div className="delete-icon">
                  <i
                    className="fal fa-trash-alt"
                    onClick={() => this.setPopUp(true, record.id)}
                  ></i>
                </div>
              ),
            },
          ]
        : [
            {
              title: "Data Ora",
              dataIndex: "full_time",
              key: "full_time",
              ellipsis: true,
              width: "12%",
            },
            // {
            //   title: "Skin",
            //   dataIndex: "skin",
            //   key: "skin",
            //   ellipsis: true,
            //   width: "6%",
            // },
            {
              title: "Username",
              dataIndex: "user_full_name",
              key: "user_full_name",
              ellipsis: true,
              width: "12%",
              render: (text, record) => {
                const formattedFullName = text.replace(/\\/g, "")
                return (
                  <Tooltip title={record.username}>
                    <span>{formattedFullName}</span>
                  </Tooltip>
                )
              },
            },
            {
              title: "Servizio",
              dataIndex: "title",
              key: "title",
              ellipsis: true,
              width: "12%",
            },
            {
              title: "Causale",
              dataIndex: "description",
              key: "description",
              ellipsis: true,
              width: "30%",
              ...this.getColumnSearchProps("causale"),
              render: (text, record) => (
                <span className="copy-icon">
                  <i
                    className="fal fa-clipboard mr-2"
                    onClick={() => {
                      getCopy(text || "")
                    }}
                  ></i>
                  <Tooltip title={text}>{text}</Tooltip>
                </span>
              ),
            },
            {
              title: "Delete",
              dataIndex: "delete",
              key: "delete",
              ellipsis: true,
              width: "5%",
              align: "center",
              render: (text, record) => (
                <div className="delete-icon">
                  <i
                    className="fal fa-trash-alt"
                    onClick={() => this.setPopUp(true, record.id)}
                  ></i>
                </div>
              ),
            },
          ]

    var data =
      errors &&
      isArray(errors) &&
      errors?.map((e) => ({
        full_time: moment(e.full_time).format("DD/MM/YYYY HH:mm:ss"),
        skin: e.skin,
        user_full_name: e.user_full_name,
        title: e.title,
        description: e.description,
        id: e.id,
        username: e.username,
      }))
    return (
      <div className="accountInfo">
        {this.props.MessaggiComponent}
        <div className="userList--noDoc__user singleUser supportContent">
          {this.state.popUp && (
            <Fragment>
              <div
                style={{ animationDuration: "0.6s" }}
                className="userDetailPopup animated bounceIn confirmationDelete"
              >
                <h1>Are you sure you want to delete msg nr.{errorId}?</h1>
                <div>
                  <button
                    onClick={() => {
                      deleteError(errorId, () => {
                        getErrors(perPage, page_number, this.props.activeSkinName)
                        this.setPopUp(false)
                      })
                    }}
                  >
                    Yes
                  </button>
                  <button onClick={() => this.setPopUp(false)}>No</button>
                </div>
              </div>
              <div className="backDrop" onClick={() => this.setPopUp(false)}></div>
            </Fragment>
          )}
        </div>
        {!ErrLoading ? (
          <div className="userList mt-n5">
            <div className="userList--AllUsers ">
              <div className="errori-header">
                <h5>ERRORI <span style={{textTransform: "uppercase"}}> {this.props.activeSkinName && `(${this.props.activeSkinName})`}</span></h5>
              </div>

              {errors ? (
                <Table
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  bordered
                />
              ) : null}
            </div>
          </div>
        ) : (
          <div className="loaderAdmin">Loading...</div>
        )}{" "}
        {!ErrLoading && (
          <div className="paginationWrapper">
            <Pagination
              onChange={(e) => {
                getErrors(
                  perPage,
                  e,
                  this.props.activeSkinName !== "" ? this.props.activeSkinName : "",
                  from !== "" ? from : "",
                  to !== "" ? to : ""
                )
                this.setState({
                  current_page: e,
                })
              }}
              total={total_pages ? total_pages * 10 : 10}
              current={current_page}
            />
            <Select
              defaultValue={50}
              onChange={(e) => {
                this.setState({ perPage: parseInt(e), clickedPage: 1 }, () => {
                  getErrors(
                    e,
                    page_number,
                    this.props.activeSkinName !== ""
                      ? this.props.activeSkinName
                      : "",
                    from !== "" ? from : "",
                    to !== "" ? to : ""
                  )
                })
              }}
              value={perPage}
            >
              <Option value={10}>10 / Pagina</Option>
              <Option value={25}>25 / Pagina</Option>
              <Option value={50}>50 / Pagina</Option>
            </Select>
          </div>
        )}
      </div>
    )
  }
}

const mapStatToProps = (state) => {
  return {
    skins: state.auth.errors.skins,
    errors: state.auth.errors.errors,
    total_pages: state.auth.errors.total_pages,
    ErrLoading: state.auth.ErrLoading,
    screenWidth: state.main.screenWidth,
    activeSkinName: state.main.activeSkinName,
    activeSkinId: state.main.activeSkinId,
  }
}

export default connect(mapStatToProps, { ...MainActions, ...AuthActions })(
  NewAdminError
)
