import React, { useState } from "react"
import { Form, Input } from "antd"
import "./DateComponent.css"

const DateComponent = ({ form, formData, setFormData, fieldName, label }) => {
  const [date, setDate] = useState(
    formData[fieldName] || { day: "", month: "", year: "" }
  )

  const handleInputChange = (field, value) => {
    const updatedDate = { ...date, [field]: value }
    setDate(updatedDate)
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: updatedDate,
    }))
  }

  return (
    <Form.Item>
      <span className="inputLabel">
        {label} <span>*</span>
      </span>
      <div className="dateInputContainer">
        {form.getFieldDecorator(`${fieldName}_day`, {
          initialValue: date.day,
          rules: [{ required: true, message: "Giorno richiesto!" }],
        })(
          <Input
            type="number"
            className="dateInput"
            placeholder="Giorno"
            onChange={(e) => handleInputChange("day", e.target.value)}
          />
        )}
        <div className="divider" />
        {form.getFieldDecorator(`${fieldName}_month`, {
          initialValue: date.month,
          rules: [{ required: true, message: "Mese richiesto!" }],
        })(
          <Input
            type="number"
            className="dateInput"
            placeholder="Mese"
            onChange={(e) => handleInputChange("month", e.target.value)}
          />
        )}
        <div className="divider" />
        {form.getFieldDecorator(`${fieldName}_year`, {
          initialValue: date.year,
          rules: [{ required: true, message: "Anno richiesto!" }],
        })(
          <Input
            type="number"
            className="dateInput"
            placeholder="Anno"
            onChange={(e) => handleInputChange("year", e.target.value)}
          />
        )}
      </div>
    </Form.Item>
  )
}

export default Form.create()(DateComponent)
