import React, { useState, useEffect } from "react"
import { Menu, Icon } from "antd"
import { connect } from "react-redux"
import MainActions from "redux-store/models/main"
import AuthActions from "redux-store/models/auth"
import images from "../../../themes/images"
import { get } from "lodash"
import { withRouter } from "react-router-dom"
import "./AdminMenu.css"
import { openChat } from "../../Chat/Chat"

const { SubMenu } = Menu

const AdminMenu = (props) => {
  const {
    skinList,
    setActiveSkinId,
    setActiveSkinName,
    history,
    accountInfo,
    screenWidth,
    activeSkinId,
    activeSkinName,
  } = props

  const [selectedNavKey, setSelectedNavKey] = useState([])
  const [selectedSkinKey, setSelectedSkinKey] = useState([])

  const role = get(accountInfo, "profile.role.name")

  // Menu items
  const menuItems = [
    {
      key: "reg-agenzia",
      path: "/back-office/registraAgenzia",
      icon: <Icon type="plus" />,
      label: "Registra Agenzia",
      roles: ["super_admin", "agent"],
    },
    {
      key: "reg-agente",
      path: "/back-office/registraAgente",
      icon: <Icon type="plus" />,
      label: "Registra Agente",
      roles: ["super_admin"],
    },
    {
      key: "utenti",
      path: "/back-office/utenti",
      icon: <Icon type="usergroup-add" />,
      label: "Utenti",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "servizi",
      path: "/back-office/servizi",
      icon: <Icon type="appstore" />,
      label: "Servizi",
      roles: ["main_admin"],
    },
    {
      key: "categorie",
      path: "/back-office/category",
      icon: <Icon type="tags" />,
      label: "Categorie",
      roles: ["main_admin"],
    },
    {
      key: "dropshipping",
      path: "/back-office/ordine-shop",
      icon: <Icon type="shopping-cart" />,
      label: "Dropshipping",
      roles: ["main_admin", "super_admin"],
    },
    {
      key: "finanziario-section",
      label: "Finanziario",
      isSection: true,
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "payment",
      path: "/back-office/movimenti",
      icon: <Icon type="credit-card" />,
      label: "Payment",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "statistiche",
      path: "/back-office/statistiche",
      icon: <Icon type="line-chart" />,
      label: "Statistiche",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "depositi-prelievi",
      path: "/back-office/depositi",
      icon: <Icon type="bank" />,
      label: "Depositi/Prelievi",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "fatture",
      path: "/back-office/fatture",
      icon: <Icon type="file-text" />,
      label: "Fatture",
      roles: ["super_admin", "agent"],
    },
    {
      key: "generale-section",
      label: "Generale",
      isSection: true,
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "support",
      icon: <Icon type="question-circle" />,
      label: "Support",
      roles: ["main_admin", "super_admin", "agent"],
      onClick: (screenWidth) => openChat(screenWidth, window.innerHeight),
    },
    {
      key: "errori",
      path: "/back-office/support",
      icon: <Icon type="exclamation-circle" />,
      label: "Errori",
      roles: ["main_admin"],
    },
    {
      key: "messaggi",
      path: "/back-office/messaggi",
      icon: <Icon type="message" />,
      label: "Messaggi",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "panic-mode",
      path:
        role === "main_admin" ? "/back-office/configure" : "/back-office/configura",
      icon: <Icon type={role === "main_admin"?"safety": "user"} />,
      label: role === "main_admin" ? "Panic Mode" : "Dati Utente",
      roles: ["main_admin", "super_admin", "agent"],
    },
    {
      key: "gallery",
      path: "/back-office/gallery",
      icon: <Icon type="picture" />,
      label: "Gallery",
      roles: ["main_admin"],
    },
    {
      key: "add-skin",
      path: "/back-office/CreateSkin",
      icon: <Icon type="plus" />,
      label: "Aggiungi skin",
      roles: ["main_admin"],
    },
  ]

  const accessibleMenuItems = menuItems.filter((item) => item.roles.includes(role))

  useEffect(() => {
    if (skinList?.length > 0) {
      const firstSkin = skinList[0];
      
      const newSkinId = activeSkinId !== "-1" ? activeSkinId : firstSkin.id;
      setActiveSkinId(newSkinId);
      setSelectedSkinKey([newSkinId.toString()]);
      
      const newSkinName = activeSkinName !== "" ? activeSkinName : firstSkin.username;
      setActiveSkinName(newSkinName);
    }
  }, [skinList, activeSkinId, activeSkinName]);
  

  useEffect(() => {
    const currentPath = history.location.pathname;
    const activeItem = menuItems.find((item) => currentPath.startsWith(item.path));
  
    if (activeItem) {
      setSelectedNavKey([activeItem.key]);
    } else {
      setSelectedNavKey([]);
    }
  }, [history.location.pathname]);
  

  const handleNavigation = (path) => {
    history.push(path)
    const activeItem = menuItems.find((item) => item.path === path)
    if (activeItem) {
      setSelectedNavKey([activeItem.key])
    }
  }

  const returnIcon = (id) => {
    const skinId = id === 8 ? 6 : id === 51 ? 7 : id
    let url = ""
    try {
      url = require(`../../../assets/images${skinId}/favicon-32x32.svg`)
    } catch (err) {
      url = require(`../../../assets/images/placeholder.jpg`)
    }
    return url
  }

 const returnLogo = (id) => {
    return  require(`../../../assets/images${id}/logo.svg`)
  }

  const handleClickSkin = (skin, e) => {
    const key = e.key

    setActiveSkinId(activeSkinId === skin.id ? -1 : skin.id)
    setActiveSkinName(activeSkinName === skin.username ? "" : skin.username)

    setSelectedSkinKey((prevSelectedKeys) => {
      if (prevSelectedKeys[0] === key) {
        return []
      } else {
        return [key]
      }
    })
  }

  return (
    <div className="admin-menu">
        <div className="menu-logo">
        <a href="/#/back-office/utenti">
              {role != "support" && role != "main_admin" && <>
              <img className={"imageHeaderTitle " + accountInfo.profile.activity_name} src={accountInfo.profile.activity_id === 55 ? images["logoMain55"] : returnLogo(accountInfo.profile.activity_id)} alt="" />
              </> 
              }
              { (role == "support" || role == "main_admin")  && activeSkinId !== -1 && activeSkinId !== undefined  ? <>
                
              <div className="admin-header-logo"> <img className={"logo-"+ activeSkinId} alt={`logo-${activeSkinId}`}
                src={activeSkinId === 55 ? images["logoMain55"] : images.getLogoBySkinId(activeSkinId)}
              />
              <span> { role == "support" ? "SUPPORT PANNEL" : "SERVICES ADMIN PANNEL"
                } </span></div>
            
              </> : 
                <span>
                { (role == "support" || role == "main_admin")  && <>
                 <span>
                {role === "support"
                  ? accountInfo.profile.username ===
                    "support_prenotazioni"
                    ? "Luce&Gas"
                    : "Support"
                  : "Admin"}
              </span>
              <span>Panel</span>
              </>
            }
              </span>
            }
            </a>
        </div>
       
      <div className="menu-section" style={{marginTop: 10, marginBottom: 10}}>Menu Principale</div>
      {/* Skins Menu */}
      {role === "main_admin" && (
        <Menu
          selectedKeys={selectedSkinKey}
          mode="inline"
          defaultOpenKeys={["sub1"]}
        >
          <SubMenu
            key="sub1"
            title={
              <span>
                <Icon type="appstore" />
                <span>Skins</span>
              </span>
            }
          >
            {skinList &&
              Array.isArray(skinList) &&
              skinList.map((skin) => (
                <Menu.Item key={skin.id} onClick={(e) => handleClickSkin(skin, e)}>
                  <div>
                    <img src={returnIcon(skin.id)} alt="" />
                    {skin.username}
                  </div>
                </Menu.Item>
              ))}
          </SubMenu>
        </Menu>
      )}

      {/* Navigation Menu */}
      <Menu selectedKeys={selectedNavKey} mode="inline">
        {accessibleMenuItems.map((item) => {
          if (item.isSection) {
            return (
              <div key={item.key} className="menu-section">
                {item.label}
              </div>
            )
          } else {
            return (
              <Menu.Item
                key={item.key}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick(screenWidth)
                  } else {
                    handleNavigation(item.path)
                  }
                }}
              >
                <span>
                  {item.icon} 
                  {item.label}
                </span>
              </Menu.Item>
            )
          }
        })}
      </Menu>
    </div>
  )
}

const mstp = (state) => ({
  screenWidth: state.main.screenWidth,
  skinList: state.auth.skinList,
  activeSkinId: state.main.activeSkinId,
  activeSkinName: state.main.activeSkinName,
  accountInfo: state.auth.accountInfo,
  screenWidth: state.main.screenWidth,
})

export default connect(mstp, { ...MainActions, ...AuthActions })(
  withRouter(AdminMenu)
)
