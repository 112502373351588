import React, { Component } from "react"
import images from "themes/images"
import { connect } from "react-redux"
//import { Footer as FooterMob } from "shared-componentsMobile";
class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  //
  render() {
    const { skinExtras, screenWidth } = this.props
    // console.log("skinExtras", skinExtras);
    return screenWidth > 1024 ? (
      <footer className={skinExtras.name === "Mrpay" ? "mrPayFooter" : ""}>
        <div className="top">
          <div className="maxWFooter maxWidth">
            {/* <h2>Vuoi diventare un Punto {skinExtras.name}?</h2> */}
            {/* <button
              onClick={() => {
                window.location.href = skinExtras.link4
              }}
            >
              contattaci subito
            </button> */}
            {/* <div
              className="chat"
              onClick={() => {
                window.zE("webWidget", "toggle");
              }}
            >
              <i className="fal fa-comments-alt"></i>
            </div> */}
            {skinExtras.name === "Mrpay" && (
              <>
                <img
                  src={images["mrPayFooter"]}
                  style={{ position: "absolute", width: "100%", marginBottom: 70 }}
                  alt="Footer"
                />
                <div
                  style={{
                    position: "absolute",
                    transform: "translate(18%, -97%)",
                    color: "var(--accent-bg)",
                    fontSize: 26,
                    lineHeight: "30px",
                  }}
                >
                  {/* <div>UN MONDO DI SERVIZI</div>
                  <div>
                    TUTTO IN UN <span style={{ fontWeight: "bold" }}>CLICK</span>!
                  </div> */}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mid">
          <div className="maxWFooter maxWidth">
            <div className="colf">
              <img src={images.logoFooter} className={skinExtras.name} alt="" />
            </div>

            <div className="colf">
              {!window.location.href.includes("stanley") && (
                <>
                  <h3>Contatti</h3>
                  <p>{skinExtras.address}</p>
                </>
              )}
              <p>
                <b>E-mail:</b> <br />
                {skinExtras.mail}
              </p>
            </div>
            {!window.location.href.includes("stanley") && (
              <div className="colf">
                <p>
                  <b>Telefono:</b> <br />
                  {skinExtras.cel}
                </p>
              </div>
            )}
            {/* <div className="colf newsletter">
              <h3>ISCRIVITI ALLA NEWSLETTER</h3>
              <input type="text" placeholder="Vostro Email" />
              <button>ISCRIVITI</button>
            </div> */}
            <div className="colf newsletter">
              <h2>Vuoi diventare un Punto {skinExtras.name}?</h2>
              <button
                onClick={() => {
                  window.location.href = skinExtras.link4
                }}
              >
                CONTATTACI SUBITO
              </button>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="maxWFooter maxWidth">
            {" "}
            <p>© All Rights Reserved Altechsolutions - {new Date().getFullYear()}</p>
            {skinExtras.name === "Mrpay" ?  <img src={images.footerBottomMrPay} alt="" /> :  <img src={images.footerBottom} alt="" />}
           
            {/*  */}
            {window.location.host.match(/point\.st|local/g) ? (
              <p>
                MAPE di Hristova Mariya Hristova e C. S.A.S. | P.IVA: IT 03852290406
                | N.REA: RN - 311209
              </p>
            ) : (
              <div className="socials">
                <i className="fab fa-instagram"></i>
                <i className="fab fa-pinterest"></i>
                <i className="fab fa-youtube"></i>
              </div>
            )}
          </div>
        </div>
      </footer>
    ) : (
      <></>
    )
  }
}

const m = ({ auth, main }) => {
  return {
    skinExtras: auth.skinExtras,
    screenWidth: main.screenWidth,
  }
}
export default connect(m, null)(Footer)
