import React, { useEffect, useState } from "react"
import { AuthActions } from "redux-store/models"
import { connect } from "react-redux"
import axios from "axios"
import { baseUrlImg, endpoint } from "../../../config/api"

const Step0 = ({
  skinList,
  addEditSkinDetails,
  addEditSkin,
  getSkins,
  accountInfo,
}) => {
  useEffect(() => {
    getSkins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSkinDetails = async (id) => {
    const token = accountInfo?.token
    try {
      const response = await axios.get(`${endpoint}/single-skin/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log(response.data.data)
      return response.data.data
    } catch (err) {
      console.error(err)
    }
  }

  const handleSkinClick = async (skin) => {
    const details = await getSkinDetails(skin.id)

    const isSameSkin = addEditSkin?.skinId === skin.id

    console.log(isSameSkin)
    addEditSkinDetails({
      skinDetails: details,
      skinId: skin.id,
      skinName: skin.username,
      skinPannel: true,
      stepNumber: 1,
      step1: isSameSkin ? addEditSkin?.step1 || {} : {},
      changedValues: addEditSkin.changedValues,
    })
  }

  return (
    <div className="AdminLogin--LoginForm">
      <div
        onClick={() => {
          const isSameSkin = addEditSkin?.skinId === -1
          addEditSkinDetails({
            ...{
              skinDetails: {},
              skinId: -1,
              skinName: "newSkin",
              skinPannel: true,
              stepNumber: 1,
              step1: isSameSkin ? addEditSkin?.step1 || {} : {},
            },
          })
        }}
      >
        <span>
          <i className="fal fa-plus"></i>
        </span>
        <span>CREA NUOVO SKIN</span>
      </div>
      {skinList &&
        Array.isArray(skinList) &&
        skinList.map((skin, i) => (
          <div key={skin.id + i} onClick={() => handleSkinClick(skin)}>
            <span>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img
                src={
                  `${baseUrlImg}logo/` +
                  skin.username.toLowerCase().split(" ").join("") +
                  ".svg"
                }
              />
            </span>
            <span>
              {skin.username}
              <i className="fal fa-cog"></i>
            </span>
          </div>
        ))}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    addEditSkin: state.auth.addEditSkin,
    accountInfo: state.auth.accountInfo,
  }
}

export default connect(mapStateToProps, AuthActions)(Step0)
