import React, { useRef, useEffect, useState, useCallback } from "react"
import { Input } from "antd"
import { Form } from "antd"
import uniqBy from "lodash/uniqBy"
import { usePrevious } from "utils/HelperFunc"
import comuni from "config/comuni.json"
import countriesArray from "config/countryArr"
import VirtualizedSelect from "react-virtualized-select"
import FloatingLabelInput from "../FloatingLabelInput/FloatingLabelInput"

const nazioni = uniqBy(countriesArray, "nazione")
const italiaProvinceOptions = [...new Set(comuni.map(({ sigla }) => sigla))].map(
  (s) => ({ label: s, value: s })
)
const allComuni = comuni.map(({ nome }) => nome)
const sortArray = (array) =>
  array.sort((a, b) => (a.label || a).localeCompare(b.label || b))
const nazioniOptions = nazioni.map(({ nazione }) => ({
  label: nazione,
  value: nazione,
}))
const italiaCaps = comuni.map(({ cap }) => cap[0])
const nazioneChanged = (nazione, prevNazione) => {
  return (
    nazione !== prevNazione &&
    prevNazione &&
    prevNazione !== "ITALIA" &&
    nazione === "ITALIA"
  )
}
const capRules = {
  required: true,
  message: "Inserire postcode!",
  whitespace: true,
}

const AdressAutoFinder = (props) => {
  const {
    inputContainerClass,
    titles,
    form,
    nazione,
    province,
    comune,
    setNazione,
    setProvince,
    setComune,
    capFormKey,
  } = props

  const getProvinceOptions = useCallback(() => {
    let provinceOptions = []
    if (countriesArray) {
      if (nazione === "ITALIA" || !nazione || nazione === "") {
        provinceOptions = italiaProvinceOptions
      } else {
        provinceOptions = nazioni
          .filter((item) => nazione === "" || item.nazione === nazione.toUpperCase())
          .map(({ sigla }) => ({ label: sigla, value: sigla }))
      }
    }
    return sortArray(provinceOptions)
  }, [nazione])
  const getComuneOptions = useCallback(() => {
    let comuneOptions = []
    if (countriesArray) {
      const stateProvince = (province || "").toUpperCase()
      const stateNazione = (nazione || "").toUpperCase()
      if (stateNazione === "ITALIA" || !stateNazione || stateNazione === "") {
        comuneOptions = [
          ...new Set(
            comuni
              .filter(
                ({ sigla }) =>
                  !stateProvince || stateProvince === "" || sigla === stateProvince
              )
              .map(({ nome }) => nome)
          ),
        ].map((coumunName) => ({ label: coumunName, value: coumunName }))
      } else {
        comuneOptions = nazioni
          .filter(
            (item) =>
              item.nazione === stateNazione &&
              (item.sigla === stateProvince || stateProvince === "")
          )
          .map(({ provincia }) => ({ label: provincia, value: provincia }))
      }
    }
    return sortArray(comuneOptions)
  }, [province, nazione])
  const [capHelper, setCapHelper] = useState(false)
  const [prevCap, setPrevCap] = useState(
    form && ((form || {}).getFieldValue(capFormKey) || "").toString()
  )
  const [provinceOptions, setProvinceOptions] = useState(getProvinceOptions())
  const [comuneOptions, setComuneOptions] = useState(getComuneOptions())

  const ifComuneFoundUpdate = useCallback((newComune) => {
    if (newComune) {
      setNazione("ITALIA")
      setProvince(newComune.sigla)
      setComune(newComune.nome)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const capOnMouseOver = () => setCapHelper(true)
  const capOnMouseLeave = () => setCapHelper(false)

  const setCap = useCallback(
    (newCap) => form.setFieldsValue({ [capFormKey]: newCap }),
    [form, capFormKey]
  )

  const resetAdressInfo = useCallback(() => {
    setProvince("")
    setComune("")
    setCap(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCap])
  const prevNazione = usePrevious(nazione, useRef, useEffect)

  useEffect(() => {
    setProvinceOptions(getProvinceOptions())
    if (
      nazioneChanged(nazione, prevNazione) ||
      nazioneChanged(prevNazione, nazione)
    ) {
      resetAdressInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nazione, prevNazione])

  useEffect(() => {
    setComuneOptions(getComuneOptions())
  }, [getComuneOptions, nazione, province])

  useEffect(() => {
    ifComuneFoundUpdate(comuni[allComuni.indexOf(comune)])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comune])
  useEffect(() => {
    const newComune = comuni.find((c) => c.nome === comune && c.sigla === province)
    if (newComune) {
      setCap(newComune.cap[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nazione, province, comune])

  const cap = (form.getFieldValue(capFormKey) || "").toString()
  useEffect(() => {
    if (prevCap !== cap) {
      setPrevCap(cap)
      if (cap && cap.toString().length === 5) {
        ifComuneFoundUpdate(comuni[italiaCaps.indexOf(cap)])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cap, prevCap])

  const changeNazione = ({ value }) => setNazione(value)
  const changeProvince = ({ value }) => setProvince(value)
  const changeComune = ({ value }) => setComune(value)

  const capValidator = (a, b, c) => {
    const cap = form.getFieldValue(capFormKey)
    const isItalyCap =
      (nazione === "ITALIA" && (italiaCaps || []).includes(cap)) ||
      (nazione !== "ITALIA" && nazione !== "")
    if (cap.match(/[0-9]/g) && cap.length === 5 && isItalyCap) {
      c()
    } else {
      c("Cap sbagliato!")
    }
  }

  return (
    <React.Fragment>
      <div className={`${inputContainerClass}`}>
        {/* <InputLabel
          title={titles.cap}
          helperComponent={
            <span onMouseOver={capOnMouseOver} onMouseLeave={capOnMouseLeave}>
              * (?)
              {capHelper && <div className="helper">Il cap deve avere 5 numeri</div>}
            </span>
          }
        /> */}
        <Form.Item hasFeedback>
          {form.getFieldDecorator(capFormKey, {
            rules: [capRules, { validator: capValidator }],
          })(<FloatingLabelInput label="Cap di residenza" />)}
        </Form.Item>
      </div>
      <div className={`${inputContainerClass}`}>
        {/* <InputLabel title={titles.nazione} /> */}
        <Form.Item style={{ marginBottom: 15 }}>
          <VirtualizedSelect
            options={nazioniOptions}
            onChange={changeNazione}
            value={nazione}
            maxHeight={100}
            placeholder={titles.nazione}
          />
        </Form.Item>
      </div>
      <div className={`${inputContainerClass}`}>
        {/* <InputLabel title={titles.province} /> */}
        <Form.Item style={{ marginBottom: 15 }}>
          <VirtualizedSelect
            options={provinceOptions}
            onChange={changeProvince}
            value={province}
            maxHeight={100}
            placeholder={titles.province}
          />
        </Form.Item>
      </div>
      <div className={`${inputContainerClass}`}>
        {/* <InputLabel title={titles.comune} /> */}
        <Form.Item style={{ marginBottom: 15 }}>
          <VirtualizedSelect
            options={comuneOptions}
            onChange={changeComune}
            value={comune}
            placeholder={titles.comune}
            maxHeight={100}
          />
        </Form.Item>
      </div>
    </React.Fragment>
  )
}

const InputLabel = ({ title, helperComponent }) => {
  return (
    <div className="inputLabel">
      {title} {helperComponent ? helperComponent : <span>*</span>}
    </div>
  )
}

AdressAutoFinder.defaultProps = {
  inputContainerClass: "itemCol full",
  titles: {
    nazione: "Nazione di residenza",
    cap: "Cap di residenza",
    province: "Provincia di residenza",
    comune: "Comune di residenza",
  },
  nazione: "",
  province: "",
  comune: "",
  capFormKey: "cap",
}

export default AdressAutoFinder
