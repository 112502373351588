import React, { useEffect } from "react"
import NumpadForm from "./modals/NumpadForm"
import BolletiniBianchi from "./modals/BolletiniBianchi"
import PostePay from "./modals/PostePay"
import BolloAuto from "./modals/BolloAuto"
import PagoPa from "./modals/PagoPa"
import MavRav from "./modals/MavRav"
import F24 from "./modals/F24"
import Freccia from "./modals/Freccia"
import { connect } from "react-redux"
const ServicesForms = ({
  activeService,
  activeCategory,
  setService,
  favorites,
  toggleFavorite,
  isSepafin,
  services,
}) => {
  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}

  let allFavServices = []
  Object.keys(favorites).forEach((item) => {
    Object.keys(favorites[item]).forEach((subitem) => {
      allFavServices.push(subitem)
    })
  })

  let serviceObj = {}
  Object.keys(services).forEach((category) => {
    Object.keys(services[category]).forEach((serviceKey) => {
      if (serviceKey === activeService) {
        serviceObj = services[category][serviceKey]
      }
    })
  })

  const pagamentiServices = accountData?.dashboard?.pagamenti_module?.module_ids

  //console.log("serviceObj", serviceObj);
  const ValidService = ["0", "1", "B"].includes(
    serviceObj?.services?.[0].type?.toString()
  )
  
  return (
    <div className="servicesForms">
      {activeCategory === "RTELD" && ValidService ? (
        <NumpadForm
          setService={setService}
          activeCategory={activeCategory}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : [
          "CRYPTOSER",
          "GIFT",
          "SND000",
          "RTELI",
          "RTVD",
          "CCARD",
          "RTELC",
          "SCMS",
          "GIFT CARD",
          "CARTE TELEFONICHE INTERNAZIONALI",
          "CARTE DI CREDITO",
          "RICARICHE CONTI GIOCO",
          "RICARICHE PAY TV",
        ].includes(activeCategory) && ValidService ? (
        <NumpadForm
          noNumbers={true}
          setService={setService}
          activeCategory={activeCategory}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : ["BOL001","SBOL001","SBOLL","BOL002","SBOL002", "3611776", "110067", "106037", "103859"].includes(
          activeService
        ) ? (
        <BolletiniBianchi
          services={services}
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
          pagamentiServices={pagamentiServices}
        />
      ) : ["SMAVRAV", "SBOL004", "SBOL003", "BOL004", "BOL003", "106163", "106161", "102964", "102965"].includes(
          activeService
        ) ? (
        <MavRav
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : ["SPAGPA", "SPPA001", "BOL007", "3619836", "103185"].includes(activeService) ? (
        <PagoPa
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : ["SPAGF24CO", "SPAGF24"].includes(activeService) ? (
        <F24
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : ["SBOLLA", "SBOL006", "106071"].includes(activeService) ? (
        <BolloAuto
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) : ["SBOLLF", "SBOL007"].includes(activeService) ? (
        <Freccia
          setService={setService}
          activeService={activeService}
          toggleFavorite={toggleFavorite}
          allFavServices={allFavServices}
        />
      ) 
      // : activeService === "RPP001" ? (
      //   <PostePay
      //     setService={setService}
      //     activeService={activeService}
      //     toggleFavorite={toggleFavorite}
      //     allFavServices={allFavServices}
      //   />
      // ) 
      : (
        <RedirectDashboard activeService={activeService} setService={setService} />
      )}
    </div>
  )
}
const RedirectDashboard = ({ setService, activeService }) => {
  useEffect(() => {
    setTimeout(() => {
      setService(null)
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="serviceSoon">
      Servizio {activeService} {` `}disponibile a breve. Reindirizzamento alla
      schermata iniziale ...
    </div>
  )
}
const mstp = ({ main: { services }, auth }, props) => {
  return {
    services: props.services || services,
    isSepafin: !!auth?.accountInfo?.profile?.hasSepafinCredentials,
  }
}
export default connect(mstp)(ServicesForms)
