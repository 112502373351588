import React, { useEffect, useState } from "react"
import { notification } from "antd"
import QrCodeReader from "shared-components/QrCodeReader"
import { barcodeReaderReq } from "../../../src/services/auth"
import { AuthActions, MainActions } from "redux-store/models"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const ScanQrCodeModal = (props) => {
  const [visibility, setVisibility] = useState(true)

  useEffect(() => {
    setVisibility(true)
  }, [])

  const redirectUrl = new URLSearchParams(
    window.location.hash.replace(`#/scan-qrcode?`, "")
  ).get("redirectUrl")

  return (
    visibility && (
      <div
        className="scan-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "#f0f2f5",
        }}
      >
        <div
          className="scan-box"
          style={{
            width: "100%",
            maxWidth: "600px",
            padding: "20px",
            borderRadius: "8px",
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Scan QR Code</h2>
          <QrCodeReader visibility={visibility}>
            {(data) => {
              if (data && data !== "No result") {
                if (redirectUrl) {
                  window.location.href = `${redirectUrl}&qr-code=${data}`
                } else {
                  barcodeReaderReq(data).then((res) => {
                   
                    props.history.push("/dashboard")
                  })
                  props.setScannedBarcode(data)
                }
              }
            }}
          </QrCodeReader>
        </div>
      </div>
    )
  )
}

const mpstp = (state) => ({
  scannedBarcode: state.auth.scannedBarcode,
})

export default withRouter(
  connect(mpstp, { ...AuthActions, ...MainActions })(ScanQrCodeModal)
)
