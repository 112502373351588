import React, { useState, useEffect } from "react"
import { AuthActions } from "redux-store/models"
import { connect } from "react-redux"
import Step0 from "./Step0"
import NewStep1 from "./NewStep1"
import NewStep2 from "./NewStep2"
import NewStep3 from "./NewStep3"
import axios from "axios"
import { endpoint } from "../../../config/api"

const AdminLoginSkins = ({
  skinList,
  addEditSkinDetails,
  addEditSkin,
  accountInfo,
}) => {
  const token = accountInfo?.token

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      if (addEditSkin.skinId === -1) {
        await axios.post(
          `${endpoint}/skins`,
          {
            ...addEditSkin.step1,
            ...addEditSkin.step2,
            ...addEditSkin.step3,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      } else {
        await axios.post(
          `${endpoint}/edit-skin/${addEditSkin.skinId}`,
          {
            ...addEditSkin.changedValues,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      }
    } catch (err) {
      console.error("Error:", err)

      if (err.response && err.response.data.errors) {
        const errors = err.response.data.errors
        addEditSkinDetails({
          ...addEditSkin,
          errors: errors,
        })
        console.log("Set Errors:", errors)

        const errorFields = Object.keys(errors)
        let stepNumber = null

        for (let field of errorFields) {
          stepNumber = getStepForField(field)
          if (stepNumber !== null) {
            break
          }
        }

        if (stepNumber !== null) {
          addEditSkinDetails({
            ...addEditSkin,
            errors,
            stepNumber,
          })
        } else {
          console.error(`None of the fields found in any step`)
        }
      }
    }
  }

  const getStepForField = (field) => {
    if (addEditSkin.step1 && field in addEditSkin.step1) {
      return 1
    } else if (addEditSkin.step2 && field in addEditSkin.step2) {
      return 2
    } else if (addEditSkin.step3 && field in addEditSkin.step3) {
      return 3
    } else {
      console.log("Field not found in any step")
      return null
    }
  }

  useEffect(() => {
    console.log("Step:", addEditSkin)
  }, [addEditSkin])

  return (
    <>
      {addEditSkin?.stepNumber === 1 ? (
        <NewStep1 key={1} />
      ) : addEditSkin?.stepNumber === 2 ? (
        <NewStep2 key={2} />
      ) : addEditSkin?.stepNumber === 3 ? (
        <NewStep3 key={3} handleSubmit={handleSubmit} />
      ) : (
        <Step0 skinList={skinList} addEditSkinDetails={addEditSkinDetails} />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    addEditSkin: state.auth.addEditSkin,
    accountInfo: state.auth.accountInfo,
  }
}

export default connect(mapStateToProps, AuthActions)(AdminLoginSkins)
