import { createActions, createReducer } from "reduxsauce"

const { Types, Creators } = createActions({
  changeAgent: ["id", "id2", "skin_id"],
  changeAgentSkin: ["agent_id", "new_skin_id"],
  signInByEmail: ["email", "password", "c", "device_token"],
  setUserCredentials: ["credentials"],
  signInByOtp: ["email", "password", "device_token", "need_auth", "otp"],
  authSuccess: ["user"],
  authFailure: ["error"],
  logOut: [],
  getAccountInfo: [],
  setAccountInfo: ["accountInfo"],
  getAgents: ["skin_id"],
  setAgents: ["agents"],
  getTransferDocument: ["refill"],
  setTransferDocument: ["transferDocument"],
  setUnauthorization: [],
  getBookingsBolMr: ["id_prenotazione", "service_id","user_id"],
  setBookingsBolMr: ["BookingBoletinoInfo"],
  getBolletiniBianchi: [
    "service_id",
    "importo",
    "id_prenotazione",
    "nome",
    "cognome",
    "conto_corrente",
    "causale",
    "callBack",
    // "service_id",
    // "numero_conto_corrente",
    // "importo",
    // "intestato_a",
    // "causale",
    // "eseguito_da",
    // "via_piazza",
    // "cap",
    // "citta",
    // "provincia",
    // "clearFields",
    // "callBack",
  ],
  getBolletiniPremercati: [
    "service_id",
    "numero_conto_corrente",
    "importo",
    "importo_cent",
    "codice_identificativo",
    "tipologia",
    "eseguito_da",
    "via_piazza",
    "cap",
    "citta",
    "provincia",
    "indirizzo",
    "user_id",
    "clearFields",
    "callBack",
  ],
  setBolletiniBianchi: ["bolletiniBianchi"],
  setBolletiniPremercati: ["bolletiniPremercati"],
  setServiceId: ["service_id"],
  setServiceS: ["service_s"],
  getRefills: ["page_number", "limit"],
  getPayments: [
    "username",
    "from",
    "to",
    "page_number",
    "limit",
    "skin_id",
    "excel",
    "service",
    "monthly",
  ],
  getAllPayments: ["from", "to"],
  getRequestedRefills: ["page_number", "limit", "skin_id", "from", "to"],
  requireTransfer: ["importo", "document"],
  getPaymentsForExcel: [
    "username",
    "from",
    "to",
    "page_number",
    "limit",
    "skin_id",
    "excel",
    "callback",
    "service",
  ],
  getAllPaymentsForExcel: ["from", "to"],
  setPayments: ["payments"],
  setAllPayments: ["allPayments"],
  setRequestedRefills: ["requestedRefills"],
  setPaymentsForExcel: ["paymentsForExcel"],
  setAllPaymentsForExcel: ["allPaymentsForExcel"],
  setUsernames: ["usernames"],
  setListedServices: ["listed_services"],
  getRechargeMobile: [
    "service_id",
    "phone_number",
    "callBack",
    "is_sepafin",
    "username",
    "codice_fiscale",
    "amount",
    "ean",
    "userId",
  ],
  getRechargeMobileSim: [
    "service_id",
    "phone_number",
    "amount",
    "ean",
    "userId",
    "callBack",
  ],
  captureRechargeMobileSim: ["payload"],
  getCustomVoucherReq: [
    "service_id",
    "tel_no",
    "callBack",
    "is_sepafin",
    "username",
  ],
  buyBitNovoVoucher: [
    "service_id",
    "importo",
    "validateCustomer",
    "is_sepafin",
    "username",
  ],
  setRechargeMobile: ["rechargeMobile"],
  getStatisticheMain: [""],
  getVisureByServiceId: ["service_id", "callBack"],
  setVisureDocumentLoading: ["visureDocumentLoading"],
  setBussinessInfoLoading: ["bussinessInfoLoading"],
  getVisureDocument: ["barcode"],
  getBussinessInfos: ["piva_or_cf", "callback", "is_sepafin", "username"],
  setVisureByServiceId: ["visureByServiceIdDetails"],
  setVisureDocument: ["visureDocument"],
  setBussinessInfos: ["bussinessInfo"],
  setStatisticheMain: ["StatisticheMain"],
  getPostePay: [
    "service_id",
    "importo",
    "user_id",
    "intestazione",
    "codice_fiscale_intestatario",
    "ordinante",
    "codice_fiscale_ordinante",
    "numero_postepay",
    // "document_type",
    // "imageUrl",
    // "imageUrl2",
    "clearFields",
    "setPostePayLoading",
    "is_sepafin",
    "username",
  ],
  userTransferMoney: [
    "user_id",
    "amount",
    "type",
    "c",
    "role",
    "backOffice",
    "message",
    "sendMsg",
    "callback",
    "is_sepafin",
    "username",
  ],
  setPostePay: ["postePay"],
  setPostePayLoading: ["postePayLoading"],
  setServiceType: ["serviceType"],

  getAds: [],
  getMsg: [],
  getPopup: [],

  getRegister: [
    "first_name",
    "last_name",
    "nickname", // for username
    "email",
    "gender",
    "personal_number",
    "birthday",
    "nazione",
    "province_of_birth",
    "city_of_birth",
    "nazioneDiResidenca",
    "residence_province",
    "residence_city",
    "address",
    "cap",
    "identity_id",
    "identity_type",
    "number_prefix",
    "number",
    "frontImg",
    "backImg",
    "role",
    "aRagSoc",
    "aInsegna",
    "aPhone",
    "aAdress",
    "aCity",
    "aComcode",
    "aCap",
    "aPiva",
    "aFcode",
    "confirm_password",
    "password",
    "rilasciato_da",
    "luogo_di_rilascio",
    "data_di_rilascio",
    "data_di_scadenza",
    "a_cordinate",
    "a_contry",
    "rent",
    "privacy_policy",
    "recieve_emails",
    "percentage",
    "register_token",
    "wallet",
    "tipo_di_rent",
    "active_skin",
    "agent_id"
  ],
  setRegister: ["register"],
  setAds: ["ads"],
  setMsg: ["msg"],
  setPopup: ["popup"],
  createAds: ["data"],
  readAds: ["id"],
  requireRefillStatus: ["id", "status"],
  setRefillStatus: ["refillStatus", "id", "status"],
  createAdsResponse: ["adsCreationgLoading", "adsCreationgMess"],
  getChangedPassword: ["oldPassword", "newPassword"],
  setChangePasswordError: ["pwError"],
  getConfigura: ["id"],
  getCodiceTicket: ["barcode", "service", "paymentNotFinished", "visible"],
  setPaymentsFromCode: ["paymentsFromCode"],
  setConfiguraData: ["usersConfigura"],
  getBarcodeData: ["barcode", "callback"],
  setBarcodeData: ["barcodeData"],
  setLoginMsg: ["loginMsg"],
  setOtpInfo: ["otpInfo"],
  getUserDetail: ["id", "skin_id"],
  setUserDetail: ["userDetail"],
  updateUserDetail: [
    "user_id",
    "phone",
    "document_type",
    "document_number",
    "rilasciato_da",
    "luogo_di_rilascio",
    "data_di_rilascio",
    "data_di_scadenza",
    "a_insegna",
    "a_cordinate",
    "a_phone",
    "a_address",
    "a_city",
    "a_comune_code",
    "a_cap",
    "a_country",
    "a_rent",
    "password",
    "confirm_password",
    "skin_id",
    "resetState",
    "mainAdminObj",
  ],
  updateUserDetailMsg: ["updateMsg"],
  setPrivateMsg: ["privMsg"],
  addPrivateMsg: ["privMsg"],
  getSkinExtras: ["activeSkinId"],
  setSkinExtras: ["skinExtras"],
  getErrors: [
    "limit",
    "page_number",
    "skin_name",
    "from",
    "to",
    "description",
    "DONT_LOAD",
  ],
  setErrorsLoading: ["ErrLoading"],
  setErrors: ["errors"],
  deleteError: ["id", "c"],
  sendDataForm: [
    "prezzo",
    "typee",
    "link",
    "nome_agenzia",
    "extra_data",
    "bagaglio",
    "bagaglio_stiva",
    "callBack",
    "partenza",
    "partenza_stazione",
    "andata_time",
    "destinazione",
    "destinazione_stazione",
    "compagnie",
    "adulti",
    "ragazzi",
    "tipologia_biglietto",
    "ritorno_date",
    "categoria",
    "descrizione_categoria",
    "quantity",
    "name",
    "email",
    "telefono",
    "surname",
    "city",
    "is_sepafin",
    "username",
    "codice_fiscale",
  ],
  getDataFormDetails: ["limit", "page_number"],
  getDataFormDetailsActives: ["isVisure", "limit", "page_number"],
  setDataFormDetailsActives: ["formDetailsActives"],
  setDataFormDetails: ["formDetails"],
  getTicketByTicketId: ["ticket_id"],
  setTicketByTicketId: ["TicketByTcketId"],
  setPaymentsLoading: ["loadingPayments"],
  setStatisticheLoading: ["loadingStatistiche"],
  updateDataForm: [
    "typee",
    "link",
    "nome_agenzia",
    "extra_data",
    "bagaglio",
    "bagaglio_stiva",
    "callBack",
    "partenza",
    "partenza_stazione",
    "andata_time",
    "destinazione",
    "destinazione_stazione",
    "compagnie",
    "adulti",
    "ragazzi",
    "tipologia_biglietto",
    "ritorno_date",
    "categoria",
    "descrizione_categoria",
    "quantity",
    "name",
    "email",
    "telefono",
    "price",
    "ticket_id",
    "consegna",
    "cognome",
    "phone",
    "stato",
    "citta",
    "address1",
    "address2",
    "provincia",
    "cap",
    "note_address",
    "company_name",
    "energiaObj",
    "codice_sconto",
    "contact_time",
    "superfice",
    "nr_bagni",
    "controsoffitti",
    "nr_condizionatori",
    "impianti",
    "checkedList",
    "codice_fiscale",
  ],
  updateVisura: [
    "visura_id",
    "typee",
    "codice_fiscale",
    "provincia",
    "address",
    "telefono",
    "email",
    "price",

    "ragione_sociale",
    "p_iva",
    "comune",

    "nome",
    "cognome",
    "data_di_nascita",
    "luogo_di_nascita",
    "callBack",
    "servizi",
  ],
  setPaymentsPages: ["paymentsPages"],
  setRefillsPages: ["refillsPages"],
  sendVisureDetails: [
    "typee",
    "codice_fiscale",
    "provincia",
    "address",
    "telefono",
    "email",
    "nome",
    "cognome",
    "data_di_nascita",
    "luogo_di_nascita",
    "ragione_sociale",
    "p_iva",
    "comune",
    "callBack",
    "servizi",
    "price",
    "sc",
  ],
  setScannedBarcode: ["scannedBarcode"],
  bigliettoPopUp: ["popUpData"],
  bigliettoPopUpVisure: ["popUpDataVisure"],
  setButtonsSupport: ["enableButtons"],
  addTicket: ["ticket"],
  getVisure: [""],
  setVisure: ["Visure"],
  addVisure: ["singleVisure"],
  getVisureByVisureId: ["visura_id"],
  setVisureByVisureId: ["VisureByVisureId"],
  getUserByUserId: ["user_id", "skin_id"],
  getAgentByUserId: ["user_id", "skin_id"],
  openModalForAdmin: ["openAdminModal"],
  editModalDetails: ["ModalDetails"],
  editStatModal: ["statModal"],
  editUltModal: ["ultModal"],
  editDepModal: ["depModal"],
  editUtentiRespModal: ["utentiResModal"],
  getSkins: [],
  setSkins: ["skinList"],
  getFaturaDetails: ["user_id", "year", "month"],
  sepafinCredentials: ["sep_username", "sep_password"],
  sepafinLogin: ["sepafin_username", "sepafin_password", "username"],
  sepafinForgetPassword: ["sepafin_username"],
  setFaturaDetails: ["faturaDetails"],
  getAllServices: ["skin_id", "supplier"],
  setAllServices: ["allServices"],
  setServicesLoading: ["servicesLoader"],
  setDepositoPopup: ["DepositoPopup"],
  getAllFaturaBySearch: [
    "username",
    "month",
    "year",
    "perPage",
    "page_number",
    "raport",
  ],
  setFatturaLoading: ["fattura_loading"],
  setAllFaturaBySearch: ["Fatture"],
  sendMailFattura: ["file_name"],
  setDepositoModalAdmin: ["adminDepModal"],
  setUserMessageModalAdmin: ["userMsgModal"],
  setPaymentsExcelLoading: ["paymentExcelLoading"],
  addEditSkinDetails: ["addEditSkin"],
  AddSkinNew: ["name", "url", "email", "agency_rent"],
  setSkinId: ["newSkinId"],
  getWidgetPayments: ["skin_id"],
  setWidgetPayments: ["leUltimeTransazioniDet"],
  AddSuperAdmin: [
    "first_name",
    "last_name",
    "gender",
    "username",
    "email",
    "phone",
    "personal_number",
    "password",
    "confirm_password",
    "address",
    "city",
    "comune_code",
    "cap",
    "country",
    "birth_place",
    "birth_country",
    "birthday",
    "a_ragione_sociale",
    "a_p_iva",
    "a_codice_fiscale",
    "skin_id",
    "c",
  ],
  AddInformazioniFinanziario: [
    "nome_banca",
    "societa_beneficiaria",
    "iban",
    "tipo_affitto",
    "canone_mensile",
    "agenzia_percentuale",
    "agende_percentuale",
    "metodo_affitto",
    "skin_affitto",
    "minimum_agenzies",
    "ranges",
    "skin_id",
    "c",
  ],
  AddExtraData: [
    "mail",
    "address",
    "cel",
    "link1",
    "link2",
    "link3",
    "link4",
    "link5",
    "link6",
    "fb",
    "ig",
    "pin",
    "yt",
    "primo_color",
    "secondo_color",
    "background_color",
    "head_color",
    "skin_id",
  ],
  registerSkinSucc: ["registerSkinS"],
  getStatistiche: ["skin_id", "from", "to", "username", "service"],
  setStatistiche: ["Statistiche"],
  setFromDateToDate: ["fromDate"],
  setAdminPanelClass: ["CenterCls"],
  setLoadingRecharge: ["loadingRechargeMobile"],
  setBolletiniLoading: ["bolletiniLoading"],
  UpdateServicesChangeStatus: [
    "name",
    "full_name",
    "company_id",
    "active",
    "skin_id",
    "c",
  ],
  editReportistica: ["ReportisticaDet"],
  fetchBolletini: [
    "codiceFiscaleOrPartitaIvaIsRequired",
    "service_id",
    "person_type",
    "via_piazza",
    "citta",
    "importo",
    "tipologia",
    "numero_conto_corrente",
    "causale",
    "nome",
    "cognome",
    "codice_fiscale",
    "denominazione",
    "partita_iva",
    "codice_identificativo",
    "dynamicFields",
    "clearFields",
    "importo_cent",
    "username",
  ],
  buyTicketOnline: [
    "typee",
    "link",
    "nome_agenzia",
    "extra_data",
    "price",
    "consegna",
    "nome",
    "cognome",
    "email",
    "phone",
    "stato",
    "citta",
    "address1",
    "address2",
    "provincia",
    "cap",
    "note_address",
    "company_name",
    "codice_sconto",
    "contact_time",
    "superfice",
    "nr_bagni",
    "controsoffitti",
    "nr_condizionatori",
    "impianti",
    "checkedList",
    "callBack",
  ],
  buyAssicurazione: [
    "typee",
    "nome",
    "cognome",
    "email",
    "telefono",
    "codice_fiscale",
    "via",
    "nr_civicio",
    "cap",
    "citta",
    "provincia",
    "situazione",
    "tipo",
    "targa",
    "accesories",
    "valore",
    "note",
    "username",
    "callback",
  ],
  setPagoPa: [
    "service_id",
    "tipologia",
    "codice_fiscale_bol",
    "codice_aviso",
    "clearFields",
    "tipo_veicolo",
    "targa",
    "username",
  ],
  mrPayPagoPa: [
    "service_id",
    "tipologia",
    "codice_fiscale_bol",
    "codice_aviso",
    "clearFields",
    "importo",
    "importo_cent",
    "ente_creditore",
    "eseguito_da",
    "user_id",
    "callBack"
  ],
  getBolletiniAuto: ["service_id", "tipo", "targa", "user_id", "clearFields", "callBack"],
  bolletinoCapture: ["payload"],
  setFreccia: [
    "service_id",
    "importo",
    "causale",
    "person_type",
    "via_piazza",
    "citta",
    "identificativo_pagamento",
    "iban",
    "cin_importo",
    "cin_intermedio",
    "cin_complessivo",
    "codice_esenzione",
    "nome",
    "cognome",
    "denominazione",
    "callBack",
    "isPos",
    "username",
  ],
  setMavRav: [
    "service_id",
    "person_type",
    "via_piazza",
    "citta",
    "phone_number",
    "importo",
    "codice",
    "nome",
    "cognome",
    "denominazione",
    "partita_iva",
    "causale",
    "clearFields",
    "isPos",
    "username",
  ],
  cancelPagamento: ["pagamento_id", "service_id", "callBack"],
  payPagoPa: [
    "service_id",
    "total_amount",
    "fee_amount",
    "pagamento_id",
    "isPos",
    "username",
  ],
  // cancelPagamento: ["pagamento_id", "callBack"],
  // payPagoPa: ["service_id", "total_amount", "fee_amount", "pagamento_id"],
  setBokingSep: [
    "service_id",
    "person_type",
    "via_piazza",
    "citta",
    "codice_ufficio",
    "codice_atto",
    "data_pagamento",
    "importo",
    "taxes_array",
    "nome",
    "cognome",
    "codice_fiscale",
    "denominazione",
    "partita_iva",
    "codiceOperazione",
    "ClearFields",
    "username",
  ],
  internetRegister: ["name", "indirizo", "phone", "account_info"],
  nuovoBillerSubmit: [
    "denomination",
    "numero_conto_corrente",
    "note",
    "file_content_type",
    "file_base64",
  ],
  activateDeactivateSepafin: ["userId", "supplier"],
  setPayFSaga: ["service_id", "importo", "fee", "pagamento_id", "isPos", "username"],
  // setPayFSaga: ["service_id", "importo", "fee", "pagamento_id"],
  buyVisure: [
    "service_id",
    "fields",
    "extra_options",
    "callback",
    "is_sepafin",
    "username",
  ],
  getRegistrazioneData: [],
  setRegistrazioneData: ["SelectData"],
  createUserBgame: [
    "nome",
    "cognome",
    "data_nascita",
    "sesso",
    "id_nazione_nascita",
    "id_comune_nascita",
    "codice_fiscale",
    "id_comune_residenza",
    "indirizzo",
    "cap",
    "telefono",
    "cellulare",
    "email",
    "id_tipo_documento",
    "documento",
    "rilasciato_da",
    "data_rilascio",
    "data_scadenza",
    "id_nazione_cittadinanza",
    "nick",
    "password",
    "question",
    "answer",
  ],
  pagoTicket: ["barcode", "isPos", "username"],
  setTransactionModal: ["transactionModal"],
  setSocketReceipt: ["socketReceipt"],
  getCompanyNameByContoCorrento: ["contoCorrente", "tipologia"],
  setDetailByContoCorrente: ["detailByContoCorrente"],
  setCreaMessaggi: ["creaMessaggi"],
  setShowSepafinLogin: ["showSepafinLogin"],
  setIsLoggedinSepafin: ["isLoggedinSepafin"],
  getAgencyPos: ["username"],
  setAgencyPos: ["agencyPosesAvailable"],
  toggleNuovoBiller: ["nuovoBillerVisibility"],
  setVisureMobileDetails: ["visureMobileDetails"],
  // getCompanyNameByContoCorrento: ["contoCorrente"],
  // setDetailByContoCorrente: ["detailByContoCorrente"],
  // setCreaMessaggi: ["creaMessaggi"],
  getContiAccount: ["service_id"],
  setContiAccount: ["contiAccount"],
  setNewContiAccount: ["service_id", "conti_username", "conti_password"],
  setNewContiStatus: ["newContiStatus"],
  getCouriers: [
    "service_id",
    "sender_country_code",
    "sender_cap",
    "sender_place_name",
    "receiver_country_code",
    "receiver_cap",
    "receiver_place_name",
    "packages_details",
  ],
  setCouriers: ["couriers"],
  setShippingInfo: [
    "service_id",
    "shipping_id",
    "nominativoMittente",
    "pressoMittente",
    "indirizzoMittente",
    "comuneMittente",
    "provinciaMittente",
    "capMittente",
    "telefonoMittente",
    "emailMittente",
    "nominativoDestinatario",
    "pressoDestinatario",
    "indirizzoDestinatario",
    "comuneDestinatario",
    "provinciaDestinatario",
    "capDestinatario",
    "telefonoDestinatario",
    "emailDestinatario",
    "noteDestinatario",
    "destinatarioIsBusiness",
    "packages_details",
  ],
  setShippingData: ["shippingData"],
  setSelectedShippingId: ["selectedShippingId"],
  setPackagesDetails: ["packagesDetails"],
  setCouriersLoading: ["couriersLoading"],
  buyShippingOrder: ["service_id", "shipping_info_id"],
  setShippingOrder: ["shippingOrder"],
  getAnnualiStatitiche: ["skinId"],
  setAnnualiStatitiche: ["annualiStatitiche"],
  setAnnualiStatisticheLoading: ["loadingAnnualiStatistiche"],
  getLatestServiceStatistic: ["skinId"],
  setLatestServiceStatistic: ["latestServiceStatistic"],
})

export const AuthTypes = Types
export default Creators

const INITIAL_STATE = {
  socketReceipt: {},
  nuovoBillerVisibility: false,
  agencyPosesAvailable: null,
  creaMessaggi: false,
  SelectData: null,
  ErrLoading: false,
  fattura_loading: false,
  ModalDetails: {},
  bolletiniLoading: false,
  fatturaPdf: "",
  enableButtons: { status: false, instance: null },
  popUpData: {},
  paymentsPages: 0,
  refillsPages: 0,
  loadingPayments: true,
  loadingStatistiche: false,
  privMsg: [],
  agents: [],
  transferDocument: "",
  pwError: "",
  loginMsg: "",
  otpInfo: {},
  user: null,
  loading: false,
  error: null,
  accountInfo: (() => {
    const accountDataB = localStorage.getItem("accountDataB");
    if (accountDataB) {
      try {
        return JSON.parse(accountDataB) || {};
      } catch (error) {
        console.error("Error parsing accountDataB:", error);
        return {};
      }
    }
    return {};
  })(),
  bolletiniBianchi: {},
  bolletiniPremercati: {},
  service_id: {},
  payments: [],
  allPayments: [],
  requestedRefills: [],
  usernames: [],
  listed_services: [],
  service_s: [],
  rechargeMobile: {},
  postePay: {},
  postePayLoading: false,
  ads: [],
  msg: [],
  popup: [],
  register: {},
  paymentsFromCode: {},
  usersConfigura: {},
  barcodeData: {},
  userDetail: {},
  updateMsg: "",
  skinExtras: {},
  errors: [],
  formDetails: [],
  TicketByTcketId: {},
  Visure: {},
  formDetailsActives: { rowsVisure: [], rowsTickets: [] },
  VisureByVisureId: {},
  popUpDataVisure: {},
  openAdminModal: false,
  statModal: { visibility: false, data: "" },
  ultModal: { visibility: false, data: "" },
  depModal: { visibility: false, data: "" },
  utentiResModal: { visibility: false, data: "" },
  skinList: [],
  faturaDetails: {},
  allServices: [],
  servicesLoader: false,
  DepositoPopup: {},
  Fatture: {},
  adminDepModal: null,
  userMsgModal: null,
  paymentsForExcel: [],
  allPaymentsForExcel: [],
  paymentExcelLoading: false,
  addEditSkin: { skinPannel: false },
  newSkinId: -1,
  leUltimeTransazioniDet: [],
  registerSkin: {
    addSkinSucc: false,
    addExtraDataSucc: false,
  },
  Statistiche: null,
  fromDate: null,
  CenterCls: "Center",
  loadingRechargeMobile: false,
  StatisticheMain: null,
  visureByServiceIdDetails: null,
  visureDocument: null,
  bussinessInfo: null,
  ReportisticaDet: true,
  serviceType: "",
  transactionModal: false,
  showSepafinLogin: false,
  isLoggedinSepafin: false,
  visureDocumentLoading: false,
  bussinessInfoLoading: false,
  BookingBoletinoInfo: null,
  refillStatus: null,
  scannedBarcode:null,
  contiAccount: null,
  newContiStatus: null,
  credentials: {},
  couriers: {},
  shippingData: {},
  selectedShippingId: "",
  packagesDetails: {},
  couriersLoading: false,
  shippingOrder: {},
  annualiStatitiche: {},
  loadingAnnualiStatistiche: false,
  latestServiceStatistic: {},
}

export const reducer = createReducer(INITIAL_STATE, {
  SET_SCANNED_BARCODE: (state, { scannedBarcode }) => ({
    ...state,
    scannedBarcode,
  }),
  TOGGLE_NUOVO_BILLER: (state, { nuovoBillerVisibility }) => ({
    ...state,
    nuovoBillerVisibility,
  }),
  SET_AGENCY_POS: (state, { agencyPosesAvailable }) => ({
    ...state,
    agencyPosesAvailable,
  }),
  SET_SHOW_SEPAFIN_LOGIN: (state, { showSepafinLogin }) => ({
    ...state,
    showSepafinLogin,
  }),
  SET_IS_LOGGEDIN_SEPAFIN: (state, { isLoggedinSepafin }) => ({
    ...state,
    isLoggedinSepafin,
  }),
  SET_CREA_MESSAGGI: (state, { creaMessaggi }) => ({
    ...state,
    creaMessaggi,
  }),
  SET_DETAIL_BY_CONTO_CORRENTE: (state, { detailByContoCorrente }) => ({
    ...state,
    detailByContoCorrente,
  }),
  SET_SOCKET_RECEIPT: (state, { socketReceipt }) => ({
    ...state,
    socketReceipt,
  }),
  SET_TRANSACTION_MODAL: (state, { transactionModal }) => ({
    ...state,
    transactionModal,
  }),
  SET_REGISTRAZIONE_DATA: (state, { SelectData }) => ({
    ...state,
    SelectData,
  }),
  SET_ERRORS_LOADING: (state, { ErrLoading }) => ({
    ...state,
    ErrLoading,
  }),
  SET_FATTURA_LOADING: (state, { fattura_loading }) => ({
    ...state,
    fattura_loading,
  }),
  EDIT_REPORTISTICA: (state, { ReportisticaDet }) => ({
    ...state,
    ReportisticaDet,
  }),
  SET_STATISTICHE_MAIN: (state, { StatisticheMain }) => ({
    ...state,
    StatisticheMain,
  }),
  SET_VISURE_BY_SERVICE_ID: (state, { visureByServiceIdDetails }) => ({
    ...state,
    visureByServiceIdDetails,
  }),
  SET_BUSSINESS_INFOS: (state, { bussinessInfo }) => ({
    ...state,
    bussinessInfo,
  }),

  SET_POSTE_PAY_LOADING: (state, { postePayLoading }) => ({
    ...state,
    postePayLoading,
  }),
  SET_BOLLETINI_LOADING: (state, { bolletiniLoading }) => ({
    ...state,
    bolletiniLoading,
  }),
  SET_WIDGET_PAYMENTS: (state, { leUltimeTransazioniDet }) => ({
    ...state,
    leUltimeTransazioniDet,
  }),
  SET_LOADING_RECHARGE: (state, { loadingRechargeMobile }) => ({
    ...state,
    loadingRechargeMobile,
  }),
  SET_DATA_FORM_DETAILS_ACTIVES: (state, { formDetailsActives }) => ({
    ...state,
    formDetailsActives,
  }),
  SET_BUTTONS_SUPPORT: (state, { enableButtons }) => ({
    ...state,
    enableButtons,
  }),
  BIGLIETTO_POP_UP: (state, { popUpData }) => ({
    ...state,
    popUpData,
  }),
  BIGLIETTO_POP_UP_VISURE: (state, { popUpDataVisure }) => ({
    ...state,
    popUpDataVisure,
  }),
  SET_PAYMENTS_PAGES: (state, { paymentsPages }) => ({
    ...state,
    paymentsPages,
  }),
  SET_REFILLS_PAGES: (state, { refillsPages }) => ({
    ...state,
    refillsPages,
  }),
  SET_PAYMENTS_LOADING: (state, { loadingPayments }) => ({
    ...state,
    loadingPayments,
  }),
  SET_STATISTICHE_LOADING: (state, { loadingStatistiche }) => ({
    ...state,
    loadingStatistiche,
  }),
  SET_ERRORS: (state, { errors }) => ({
    ...state,
    errors,
  }),
  SET_SKIN_EXTRAS: (state, { skinExtras }) => ({
    ...state,
    skinExtras,
  }),
  SET_VISURE_DOCUMENT_LOADING: (state, { visureDocumentLoading }) => ({
    ...state,
    visureDocumentLoading,
  }),
  SET_BUSSINESS_INFOS_LOADING: (state, { bussinessInfoLoading }) => ({
    ...state,
    bussinessInfoLoading,
  }),
  SET_AGENTS: (state, { agents }) => ({
    ...state,
    agents,
  }),
  SET_TRANSFER_DOCUMENT: (state, { transferDocument }) => ({
    ...state,
    transferDocument,
  }),
  SET_PRIVATE_MSG: (state, { privMsg }) => ({
    ...state,
    privMsg,
  }),
  UPDATE_USER_DETAIL_MSG: (state, { updateMsg }) => ({
    ...state,
    updateMsg,
  }),
  SET_USER_DETAIL: (state, { userDetail }) => ({
    ...state,
    userDetail,
  }),
  SET_LOGIN_MSG: (state, { loginMsg }) => ({
    ...state,
    loginMsg,
  }),
  SET_OTP_INFO: (state, { otpInfo }) => ({
    ...state,
    otpInfo,
  }),
  SIGN_IN_BY_EMAIL: (state) => ({
    ...state,
    loading: true,
  }),
  SIGN_IN_BY_OTP: (state) => ({
    ...state,
    loading: true,
  }),
  SET_USER_CREDENTIALS: (state, { credentials }) => ({
    ...state,
    credentials,
  }),
  SET_BARCODE_DATA: (state, { barcodeData }) => ({
    ...state,
    barcodeData,
  }),
  SET_PAYMENTS_FROM_CODE: (state, { paymentsFromCode }) => ({
    ...state,
    paymentsFromCode,
  }),
  SET_CHANGE_PASSWORD_ERROR: (state, { pwError }) => ({
    ...state,
    pwError,
  }),
  SET_CONFIGURA_DATA: (state, { usersConfigura }) => ({
    ...state,
    usersConfigura,
  }),
  SET_ACCOUNT_INFO: (state, { accountInfo }) => ({
    ...state,
    accountInfo,
  }),
  SET_VISURE_MOBILE_DETAILS: (state, { visureMobileDetails }) => ({
    ...state,
    visureMobileDetails,
  }),
  AUTH_SUCCESS: (state, { user }) => ({
    ...state,
    user,
    loading: false,
  }),
  AUTH_FAILURE: (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }),
  SET_UNAUTHORIZATION: ({ skinExtras }) => ({
    ...INITIAL_STATE,
    skinExtras,
    accountInfo: {},
  }),
  LOG_OUT: ({ skinExtras }) => ({
    ...INITIAL_STATE,
    accountInfo: {},
    skinExtras,
  }),
  SET_BOLLETINI_BIANCHI: (state, { bolletiniBianchi }) => ({
    ...state,
    bolletiniBianchi,
  }),
  SET_BOLLETINI_PREMERCATI: (state, { bolletiniPremercati }) => ({
    ...state,
    bolletiniPremercati,
  }),
  SET_SERVICE_ID: (state, { service_id }) => ({
    ...state,
    service_id,
  }),
  SET_SERVICE_S: (state, { service_s }) => ({
    ...state,
    service_s,
  }),
  SET_PAYMENTS: (state, { payments }) => ({
    ...state,
    payments,
  }),
  SET_ALL_PAYMENTS: (state, { allPayments }) => ({
    ...state,
    allPayments,
  }),
  SET_REQUESTED_REFILLS: (state, { requestedRefills }) => ({
    ...state,
    requestedRefills,
  }),
  SET_BOOKINGS_BOL_MR: (state, { BookingBoletinoInfo }) => ({
    ...state,
    BookingBoletinoInfo,
  }),
  SET_USERNAMES: (state, { usernames }) => ({
    ...state,
    usernames,
  }),
  SET_LISTED_SERVICES: (state, { listed_services }) => ({
    ...state,
    listed_services,
  }),
  SET_RECHARGE_MOBILE: (state, { rechargeMobile }) => ({
    ...state,
    rechargeMobile,
  }),
  SET_POSTE_PAY: (state, { postePay }) => ({
    ...state,
    postePay,
  }),
  SET_SERVICE_TYPE: (state, { serviceType }) => ({
    ...state,
    serviceType,
  }),
  SET_ADS: (state, { ads }) => ({
    ...state,
    ads,
  }),
  SET_MSG: (state, { msg }) => ({
    ...state,
    msg,
  }),
  SET_POPUP: (state, { popup }) => ({
    ...state,
    popup,
  }),
  SET_REGISTER: (state, { register }) => ({
    ...state,
    register,
  }),
  CREATE_ADS_RESPONSE: (state, { adsCreationgLoading, adsCreationgMess }) => ({
    ...state,
    adsCreationgLoading,
    adsCreationgMess,
  }),
  SET_DATA_FORM_DETAILS: (state, { formDetails }) => ({
    ...state,
    formDetails,
  }),
  SET_TICKET_BY_TICKET_ID: (state, { TicketByTcketId }) => ({
    ...state,
    TicketByTcketId,
  }),
  SET_VISURE: (state, { Visure }) => ({
    ...state,
    Visure,
  }),
  SET_VISURE_BY_VISURE_ID: (state, { VisureByVisureId }) => ({
    ...state,
    VisureByVisureId,
  }),
  OPEN_MODAL_FOR_ADMIN: (state, { openAdminModal }) => ({
    ...state,
    openAdminModal,
  }),
  EDIT_MODAL_DETAILS: (state, { ModalDetails }) => ({
    ...state,
    ModalDetails,
  }),
  EDIT_STAT_MODAL: (state, { statModal }) => ({
    ...state,
    statModal,
  }),
  EDIT_ULT_MODAL: (state, { ultModal }) => ({
    ...state,
    ultModal,
  }),
  EDIT_DEP_MODAL: (state, { depModal }) => ({
    ...state,
    depModal,
  }),
  EDIT_UTENTI_RESP_MODAL: (state, { utentiResModal }) => ({
    ...state,
    utentiResModal,
  }),
  SET_SKINS: (state, { skinList }) => ({
    ...state,
    skinList,
  }),
  SET_FATURA_DETAILS: (state, { faturaDetails }) => ({
    ...state,
    faturaDetails,
  }),
  SET_ALL_SERVICES: (state, { allServices }) => ({
    ...state,
    allServices,
  }),
  SET_SERVICES_LOADING: (state, { servicesLoader }) => ({
    ...state,
    servicesLoader,
  }),
  SET_DEPOSITO_POPUP: (state, { DepositoPopup }) => ({
    ...state,
    DepositoPopup,
  }),
  SET_ALL_FATURA_BY_SEARCH: (state, { Fatture }) => ({
    ...state,
    Fatture,
  }),
  SET_DEPOSITO_MODAL_ADMIN: (state, { adminDepModal }) => ({
    ...state,
    adminDepModal,
  }),
  SET_USER_MESSAGE_MODAL_ADMIN: (state, { userMsgModal }) => ({
    ...state,
    userMsgModal,
  }),
  SET_PAYMENTS_FOR_EXCEL: (state, { paymentsForExcel }) => ({
    ...state,
    paymentsForExcel,
  }),
  SET_ALL_PAYMENTS_FOR_EXCEL: (state, { allPaymentsForExcel }) => ({
    ...state,
    allPaymentsForExcel,
  }),
  SET_PAYMENTS_EXCEL_LOADING: (state, { paymentExcelLoading }) => ({
    ...state,
    paymentExcelLoading,
  }),

  ADD_EDIT_SKIN_DETAILS: (state, { addEditSkin }) => ({
    ...state,
    addEditSkin,
  }),
  SET_SKIN_ID: (state, { newSkinId }) => ({
    ...state,
    newSkinId,
  }),
  REGISTER_SKIN_SUCC: (state, { registerSkinS }) => ({
    ...state,
    registerSkin: { ...state.registerSkin, ...registerSkinS },
  }),
  SET_STATISTICHE: (state, { Statistiche }) => ({
    ...state,
    Statistiche,
  }),
  SET_FROM_DATE_TO_DATE: (state, { fromDate }) => ({
    ...state,
    fromDate,
  }),
  SET_ADMIN_PANEL_CLASS: (state, { CenterCls }) => ({
    ...state,
    CenterCls,
  }),
  SET_REFILL_STATUS: (state, { refillStatus, id, status }) => {
    var value = refillStatus ? status : "pending"
    return {
      ...state,
      refillStatus: refillStatus,
      requestedRefills: (state.requestedRefills || []).map((refill) =>
        id === refill.id ? { ...refill, stato: value } : refill
      ),
    }
  },
  SET_CONTI_ACCOUNT: (state, { contiAccount }) => ({
    ...state,
    contiAccount,
  }),
  SET_NEW_CONTI_STATUS: (state, { newContiStatus }) => ({
    ...state,
    newContiStatus,
  }),
  SET_COURIERS: (state, { couriers }) => ({
    ...state,
    couriers,
  }),
  SET_SHIPPING_DATA: (state, { shippingData }) => ({
    ...state,
    shippingData,
  }),
  SET_SELECTED_SHIPPING_ID: (state, { selectedShippingId }) => ({
    ...state,
    selectedShippingId,
  }),
  SET_PACKAGES_DETAILS: (state, { packagesDetails }) => ({
    ...state,
    packagesDetails,
  }),
  SET_COURIERS_LOADING: (state, { couriersLoading }) => ({
    ...state,
    couriersLoading,
  }),
  SET_SHIPPING_ORDER: (state, { shippingOrder }) => ({
    ...state,
    shippingOrder,
  }),
  SET_ANNUALI_STATITICHE: (state, { annualiStatitiche }) => ({
    ...state,
    annualiStatitiche,
  }),
  SET_ANNUALI_STATISTICHE_LOADING: (state, { loadingAnnualiStatistiche }) => ({
    ...state,
    loadingAnnualiStatistiche,
  }),
  SET_LATEST_SERVICE_STATISTIC: (state, { latestServiceStatistic }) => ({
    ...state,
    latestServiceStatistic,
  }),
  READ_ADS: (state, { id }) => {
    return {
      ...state,
      privMsg: (state.privMsg || []).filter((msg) => id !== msg.id),
      msg: (state.msg || []).map((msg) =>
        id === msg.id ? { ...msg, importance: "4_true" } : msg
      ),
    }
  },
})
