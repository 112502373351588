import React, { useEffect, useState } from "react"
import images from "themes/images"
import AdminModal from "../../AdminModal/AdminModal"
import { Select, notification } from "antd"
import { setVisureStaticInfo, getVisureStaticInfo } from "../../../../services/auth"
const { Option } = Select

const AdminSubServizzi = (props) => {
  const { services } = props
  const [inputActivity, setInputActivity] = useState("decrizione")
  const [descrizioneOrFaq, setDescrizioneOrFaq] = useState({})
  const [editService, setEditService] = useState(false)
  const [additionType, setAdditionType] = useState("List")
  const [prodotti, setProdotti] = useState([])
  const [consegna, setConsegna] = useState("")
  const [costo, setCosto] = useState(0)
  const [extraData, setExtraData] = useState()
  const [provCosto, setProvCosto] = useState(0)

  function handleChangeProdotti(value) {
    setProdotti(value)
  }
  const handleChangeAdditionType = (value) => {
    setAdditionType(value)
  }
  const setDescrizione = () => setInputActivity("decrizione")
  const setFaq = () => setInputActivity("faq")

  const updateDescFaq = (newData = {}) =>
    setDescrizioneOrFaq({
      ...descrizioneOrFaq,
      ...{ [inputActivity]: [...(descrizioneOrFaq[inputActivity] || []), newData] },
    })
  const changeDescFaq = (newData = []) =>
    setDescrizioneOrFaq({ ...descrizioneOrFaq, ...{ [inputActivity]: newData } })

  const addAdditionalData = () => {
    updateDescFaq({ type: additionType, value: "" })
  }

  const onChangeAdditionalData = (e, index) => {
    let newAdditionalData = [...(descrizioneOrFaq[inputActivity] || [])]
    newAdditionalData[index].value = e.target.value
    changeDescFaq(newAdditionalData)
  }

  const closeAdditionalData = (index) => {
    let newAdditionalData = [...(descrizioneOrFaq[inputActivity] || [])]
    newAdditionalData.splice(index, 1)
    changeDescFaq(newAdditionalData)
  }

  const changeCosegna = (e) => setConsegna(e.target.value)
  const changeCosto = (e) => setCosto(e.target.value)
  const changeProvCosto = (e) => setProvCosto(e.target.value)

  const getPostData = () => {
    return {
      service_id: editService?.service_id,
      our_visure_commission: Number(provCosto),
      dati_disponibili: prodotti,
      consegna,
      faq: JSON.stringify(descrizioneOrFaq["faq"]),
      head_info: JSON.stringify(descrizioneOrFaq["decrizione"]),
    }
  }

  const serviceId = editService?.service_id

  useEffect(() => {
    serviceId &&
      getVisureStaticInfo(serviceId).then((res) => {
        if (res?.data) {
          const {
            costo,
            consegna,
            dati_disponibili,
            our_visure_commission,
            faq,
            head_info,
          } = res?.data || {}
          setCosto(costo)
          setConsegna(consegna)
          setProdotti(!dati_disponibili ? [] : JSON.parse(dati_disponibili))
          setExtraData(res)
          setProvCosto(our_visure_commission)
          setDescrizioneOrFaq({
            faq: !faq ? [] : JSON.parse(faq),
            decrizione: !head_info ? [] : JSON.parse(head_info),
          })
        }
      })
  }, [serviceId])

  const onSubmit = () => {
    setVisureStaticInfo(getPostData()).then((res) => {
      notification["success"]({
        message: res?.data?.message,
      })
      setEditService(null)
    })
  }
  return (
    <React.Fragment>
      <AdminModal
        className="edit-visure"
        close={() => setEditService(null)}
        visibility={!!editService}
        selector=".fas.fa-cog"
      >
        <h3>{editService?.name}</h3>
        <div className="visure__content">
          <div className="content__edit__buttons">
            <button
              onClick={setDescrizione}
              className={inputActivity === "decrizione" ? "is-active" : ""}
            >
              <i className="fad fa-info-circle" />
              <p>Descrizione</p>
            </button>
            <button
              onClick={setFaq}
              className={inputActivity === "faq" ? "is-active" : ""}
            >
              <i className="fal fa-question-circle" />
              <p>Domande Frequenti</p>
            </button>
          </div>
          <div className="content__others">
            <div className="display-flex">
              <Select defaultValue="List" onChange={handleChangeAdditionType}>
                <Option value="List">
                  <button className="list-dropdown-buttons">
                    <i className="fal fa-list" />
                    <p>List</p>
                  </button>
                </Option>
                <Option value="title">
                  <button className="list-dropdown-buttons">
                    <p>Title</p>
                  </button>
                </Option>
                <Option value="paragraph">
                  <button className="list-dropdown-buttons">
                    <p>Paragraph</p>
                  </button>
                </Option>
              </Select>
              <div className="content__edit__buttons">
                <button className="plus" onClick={addAdditionalData}>
                  <i className="fal fa-plus-circle" />
                </button>
              </div>
            </div>
            {(descrizioneOrFaq[inputActivity] || []).map((data, index) => (
              <div key={index} className="additional-data">
                <input
                  className="content__faq-or-desc"
                  value={data?.value}
                  onChange={(e) => onChangeAdditionalData(e, index)}
                />
                <i
                  className="fal fa-times"
                  onClick={() => closeAdditionalData(index)}
                />
              </div>
            ))}
            <div className="content__card">
              <div className="card__title">
                <i className="fal fa-plus-circle" />
                <span>Prodotti correlati:</span>
              </div>
              <Select
                mode="multiple"
                value={prodotti}
                placeholder="Please select"
                onChange={handleChangeProdotti}
              >
                {services?.map(({ name, service_id }) => (
                  <Option key={service_id} value={service_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="content__card">
              <div className="card__title">
                <i className="fal fa-plus-circle" />
                <span>Tempo Di consegna:</span>
              </div>
              <input onChange={changeCosegna} value={consegna} />
            </div>
            <div className="content__footer">
              <div>
                <span>Costo (€)</span>
                <input type="number" readOnly onChange={changeCosto} value={costo} />
              </div>
              <div>
                <span>Utile + Provvigione 1,5 (€)</span>
                <input type="number" onChange={changeProvCosto} value={provCosto} />
              </div>
              <button onClick={onSubmit}>Aggiorna</button>
            </div>
          </div>
        </div>
      </AdminModal>
      <a href={`${window.location.origin}#/back-office/servizi`}>
        <i className="fa-solid fa-arrow-left services-go-back"></i>
      </a>

      <div className="AdminServizi">
        {services.map((service) => (
          <React.Fragment>
            <div class="AdminServiziItem animated fadeIn">
              <div class="AdminServiziItem--Header">
                <div class="AdminServiziItem--Header--Prenotazione">
                  {service.name}
                </div>
              </div>
              <img
                src={images["visure-service"]}
                class="AdminServiziItem--Image"
                alt="ALTRI"
                loading="lazy"
              />
              <div class="AdminServiziItem--ButtonWrapper">
                <div>
                  <button class="active">ACTIVE</button>
                  <button class="">INACTIVE</button>
                </div>
                <div>
                  <i class="fas fa-cog" onClick={() => setEditService(service)}></i>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  )
}

export default AdminSubServizzi
