import React from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { Area, Column } from "@ant-design/plots"
import { Spin } from "antd"

class StatisticheAnnuali extends React.Component {
  // componentDidMount() {
  //   this.props.getAnnualiStatitiche(
  //     this.props.activeSkinId !== -1 ? this.props.activeSkinId : 1
  //   )
  // }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeSkinId !== prevProps.activeSkinId &&
      this.props.activeSkinId !== -1
    ) {
      this.props.getAnnualiStatitiche(this.props.activeSkinId)
    }
  }

  render() {
    const { annualiStatitiche, loadingAnnualiStatistiche } = this.props
    let data = []
    if (annualiStatitiche && annualiStatitiche.data) {
      data = annualiStatitiche.data.map((item) => ({
        ...item,
        Sum: item.Sum / 100,
      }));
    } else {
      data = [];
    }
    const monthAbbreviations = {
      January: "Gennaio",
      February: "Febbraio",
      March: "Marzo",
      April: "Aprile",
      May: "Maggio",
      June: "Giugno",
      July: "Luglio",
      August: "Agosto",
      September: "Settembre",
      October: "Ottobre",
      November: "Novembre",
      December: "Dicembre",
    }

    const config = {
      data,
      padding: "auto",
      xField: "Month",
      yField: "Sum",
      xAxis: {
        tickCount: 12,
        label: {
          formatter: (text, item, index) => {
            return monthAbbreviations[text]
          },
        },
      },
      smooth: true,
      color: "#0078FF",
      lineStyle: {
        shadowColor: "#0078FF",
        shadowBlur: 10,
        shadowOffsetX: 1,
        shadowOffsetY: 3,
        cursor: "pointer",
      },
      maxColumnWidth: 30,
      tooltip: {
        customContent: (title, data) => {
          return `<div>${data[0]?.data?.Sum + "€"}</div>`
        },
      },
      areaStyle: () => {
        return {
          fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
        }
      },
    }
    return (
      <div className="statistiche-annuali">
        {/* <div className="statistiche-header">
          <div className="statistiche-header-title">
            <h6>STATISTICHE ANNUALI</h6>
          </div>
          <div className="statistiche-header-data"></div>
        </div> */}
        <div className="statistiche-header">
        Andamento delle vendite
        </div>
        {loadingAnnualiStatistiche ? (
          <Spin tip="Loading" size="large">
            <div style={{ height: "135px" }} className="content" />
          </Spin>
        ) : (
          <Column {...config} />
        )}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  // annualiStatitiche: state.auth.annualiStatitiche,
  activeSkinId: state.main.activeSkinId,
  loadingAnnualiStatistiche: state.auth.loadingAnnualiStatistiche,
})
export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  StatisticheAnnuali
)
