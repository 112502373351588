import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import AuthActions from "redux-store/models/auth"
import { notification } from "antd"
import { BannerColors } from "config/index"
import { getScale } from "utils/HelperFunc"
import PrintTicketMob from "./PrintTicketMob"
import { TransactionModal } from "shared-componentsMobile"
import images from "themes/images"
import { betServices } from "config/index"
import { getServiceIdIdentifier } from "utils/HelperFunc"
import { baseUrlImg } from "../../../../config/api"
import EpayPrintTicket from "../../../../shared-components/ModulePopUp/EpayPrintTicket"

const range = (start, end) => {
  var array = []
  for (var i = start; i <= end; i++) {
    array.push(i)
  }
  return array
}

const Numpad = ({
  services,
  activeService,
  activeCategory,
  setService,
  buyBitNovoVoucher,
  getRechargeMobile,
  getCustomVoucherReq,
  setRechargeMobile,
  rechargeMobile,
  loadingRechargeMobile,
  setLoadingRecharge,
  skinExtras,
  allFavServices,
  toggleFavorite,
  noNumbers,
  transactionModal,
  setTransactionModal,
  accountInfo,
  getRechargeMobileSim,
  setScannedBarcode,
  scannedBarcode,
}) => {
  const [selectedCost, setCost] = useState(null)
  const [inpVal, setVal] = useState("")
  const [toPrint, setPrint] = useState(false)
  const [bgamePad, setBgamePad] = useState(false)
  const [codFisInps, setCodFisInps] = useState("")
  const [isValidCodFis, setIsValidCodFis] = useState(false)
  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const isMrPay = accountData?.profile?.supplier === "MrPay"
  const isSepafin = accountInfo.profile.hasSepafinCredentials
  const isEPay = accountData?.profile?.supplier === "E-Pay"
  const printT = useRef()

  console.log(inpVal)
  useEffect(() => {
    //console.log("ca ka", services, activeService, activeCategory, selectedCost);
    if (!selectedCost) {
      if (`${activeService}Mobile` in betServices) {
        setCost(betServices[`${activeService}Mobile`][0])
      } else {
        if (scannedBarcode) {
          let parsedData
          try {
            parsedData = JSON.parse(scannedBarcode)
            console.log("inside parsed data")
            if (parsedData && parsedData.service_id) {
              const matchingService = services[activeCategory][
                activeService
              ].services.find((service) => service.service_id === parsedData.id)

              if (matchingService) {
                setCost(matchingService)
              }
            }
            if (parsedData.phone_number) {
              setVal(parsedData.phone_number)
            }
          } catch (error) {
            console.error("Failed to parse JSON:", error)
          }
        } else {
          setCost(services[activeCategory][activeService].services[0])
        }
      }
    }
  }, [
    services,
    activeService,
    activeCategory,
    setCost,
    selectedCost,
    scannedBarcode,
    setVal,
  ])

  useEffect(() => {
    //console.log("changed rece", rechargeMobile.receipt);
    if (rechargeMobile.receipt || rechargeMobile?.return?.receipt) {
      setTransactionModal(true)
    } else {
      setTransactionModal(false)
    }
  }, [rechargeMobile.receipt, rechargeMobile?.return?.receipt, setTransactionModal])
  // useEffect(() => {
  //   if (Object.values(rechargeMobile).length > 0)
  //     notification[rechargeMobile.errors ? "error" : "success"]({
  //       message: rechargeMobile.message,
  //       description: Object.values(rechargeMobile.errors || {}),
  //     });
  // }, [rechargeMobile]);
  const plusServices = [
    "BGM005",
    "BBT005",
    "BULL005",
    "MBBT005",
    "FLBT005",
    "FLBT002",
    "AMBT005",
    "BETL005",
  ]

  const hasCostZero = parseInt(selectedCost?.cost) === 0

  useEffect(() => {
    if (
      plusServices.some(
        (allowedService) => selectedCost?.service_id === allowedService
      ) ||
      hasCostZero
    ) {
      setBgamePad(true)
      // setVal("");
    } else if (bgamePad === true) {
      setBgamePad(false)
      setVal("")
    }
  }, [bgamePad, plusServices, selectedCost, setBgamePad])

  useEffect(() => {
    getScale(".img.Page", ".GamingBanner.mobile")
  }, [bgamePad])
  const allServices =
    activeService && `${activeService}Mobile` in betServices
      ? betServices[`${activeService}Mobile`]
      : services[activeCategory][activeService].services || []

  const validateCodiceFiscale = (codiceFiscale) => {
    return codiceFiscale.length === 16 && !codiceFiscale.includes(" ")
  }

  return (
    <div className={"mobileNumPad" + (activeService === "PAYS" ? " paysafe" : "")}>
      <div className="mobileNumPad--services">
        <React.Fragment>
          {allServices.map((item, index) => {
            let bgamePadCondition =
              item.cost === "+" &&
              plusServices.some(
                (allowedService) => item.service_id === allowedService
              )
            return selectedCost?.service_id === item.service_id ? (
              <div
                key={index}
                className={`serv ${
                  selectedCost?.service_id === item.service_id ? "active" : ""
                }`}
                onClick={() => setCost(item)}
              >
                <div className="Upper">
                  <div className="Upper--Left"></div>
                  <div className="Upper--Right"></div>
                </div>
                <div className="Bottom">
                  <span className="Price">
                    {bgamePadCondition ? item.cost : parseInt(item.cost)}
                  </span>
                  {!bgamePadCondition && <span className="Euro">€</span>}
                </div>
              </div>
            ) : (
              <div
                key={index}
                className={`serv ${
                  selectedCost?.service_id === item.service_id ? "active" : ""
                }`}
                onClick={() => setCost(item)}
              >
                <span className="Price">
                  {bgamePadCondition ? item.cost : parseInt(item.cost)}
                </span>
                {!bgamePadCondition && <span className="Euro">€</span>}
              </div>
            )
          })}
          {services[activeCategory][activeService].services &&
            Array.isArray(services[activeCategory][activeService].services) &&
            services[activeCategory][activeService].services.length < 5 &&
            range(
              services[activeCategory][activeService].services.length + 1,
              5
            ).map((item) => {
              return <div key={item} className="serv noborder"></div>
            })}
        </React.Fragment>
      </div>
      {activeService === "PAYS" && (
        <div className="CodiceFiscale">
          <label>
            Codice Fiscale <span>*</span>
          </label>
          <input
            value={codFisInps}
            maxLength={16}
            onChange={(e) => {
              const inputValue = e.target.value
              const isValid = validateCodiceFiscale(inputValue)
              setCodFisInps(inputValue)
              setIsValidCodFis(isValid)
            }}
          />
          {codFisInps && isValidCodFis && <i className="far fa-check-circle"></i>}
          {codFisInps && !isValidCodFis && <i className="far fa-times-circle"></i>}
        </div>
      )}

      <div className="mobileNumPad--headsub">
        <div className="mobileNumPad--header">
          <img
            className={`${activeService}`}
            src={images[activeService] || images[activeCategory]}
            alt=""
          />
          {selectedCost?.name}
          <i
            onClick={() => {
              if (allFavServices.includes(activeService)) {
                toggleFavorite(activeService, "remove")
              } else {
                toggleFavorite(activeService, "set")
              }
            }}
            className={
              "fas fa-star" +
              (allFavServices.includes(activeService) ? " active" : "")
            }
            aria-hidden="true"
          ></i>{" "}
        </div>
        <div className="mobileNumPad--subh">
          {!noNumbers
            ? "INSERIRE IL NUMERO DI TELEFONO DA RICARICARE"
            : "SELEZIONA LE RICARICHE IN BASSO ED ESEGUI"}
        </div>
      </div>
      {!noNumbers || bgamePad === true ? (
        <>
          <div className="mobileNumPad--input">
            {!bgamePad && <span>+39</span>}{" "}
            <input
              value={
                bgamePad === true
                  ? parseFloat(inpVal || "0,00").toLocaleString("it-IT", {
                      minimumFractionDigits: 2,
                    })
                  : inpVal
              }
              type="text"
              readOnly
            />{" "}
            {"contacts" in navigator && "ContactsManager" in window && (
              <i
                onClick={async () => {
                  const props = ["tel"]
                  const opts = { multiple: false }

                  try {
                    const contacts = await navigator.contacts.select(props, opts)
                    setVal((contacts[0]?.tel?.[0] || "").replace("+39", ""))
                  } catch (ex) {}
                }}
                className="fas fa-address-book"
              ></i>
            )}
          </div>
          <div className={`mobileNumPad--numbers ${bgamePad ? "bgm" : ""}`}>
            {bgamePad === true
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, ".", "0", "x"].map((num) => (
                  <div
                    key={num}
                    id={`num${num}`}
                    className={`bgm ${num === "x" ? "x" : ""}`}
                    onClick={() =>
                      num === "x"
                        ? inpVal.charAt(inpVal.length - 1) === "."
                          ? setVal(inpVal.slice(0, -2))
                          : setVal(inpVal.slice(0, -1))
                        : num === "."
                        ? inpVal.includes(".")
                          ? setVal(inpVal)
                          : setVal(`${inpVal}.`)
                        : setVal(`${inpVal}${num}`)
                    }
                  >
                    {num === "x" ? (
                      <span>
                        <i className="fal fa-times" />
                        <div className="triangle"></div>
                      </span>
                    ) : (
                      num
                    )}
                  </div>
                ))
              : [7, 8, 9, 4, 5, 6, 1, 2, 3].map((a) => {
                  return (
                    <div key={a} onClick={() => setVal(`${inpVal}${a}`)}>
                      {a}
                    </div>
                  )
                })}

            <>
              {" "}
              <div onClick={() => setVal("")}>C</div>
              {!bgamePad && (
                <div onClick={() => setVal(`${inpVal}${0}`)}>0</div>
              )}{" "}
              {!bgamePad && (
                <div
                  onClick={() => setVal(inpVal.slice(0, -1))}
                  id="numx"
                  className="notbgm x"
                >
                  <span>
                    <i className="fal fa-times"></i>
                    <div className="triangle"></div>
                  </span>
                </div>
              )}
            </>
          </div>
        </>
      ) : activeCategory === "SCMS" ? (
        <div
          className="GamingBanner mobile"
          style={{
            background: `${getServiceIdIdentifier(
              BannerColors,
              selectedCost?.service_id
            )}`,
          }}
          alt={[selectedCost?.service_id.substring(0, 3)]}
        >
          <div className="img Page">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              alt={`Service${selectedCost?.service_id.substring(0, 3)}`}
              src={getServiceIdIdentifier(
                images,
                selectedCost?.service_id,
                "Service",
                "ServiceDefault"
              )}
            />
            <span>{selectedCost?.cost}€</span>
          </div>
        </div>
      ) : (
        <div className={"OtherBanner" + (isEPay ? "" : " mrPayBanner")}>
          {activeService == "PAYS" ? (
            <img src={images["ServicePSC"] || images[activeService]} />
          ) : (
            <img
              src={
                isEPay
                  ? `${baseUrlImg}/${
                      selectedCost?.background
                        ? selectedCost?.background
                        : "/uncategorized/placeholder.jpg"
                    }`
                  : selectedCost?.background
                  ? `${baseUrlImg}/${selectedCost?.background}`
                  : images[activeService]
              }
            />
          )}
        </div>
      )}

      <div className="mobileNumPad--buttons">
        <button
          className={`${loadingRechargeMobile ? "disable" : ""}`}
          data-send={`${selectedCost?.service_id} ${inpVal} ${selectedCost?.cost}`}
          onClick={() => {
            const { service_id, cost, service_type } = selectedCost || {}

            setLoadingRecharge(true)
            if (service_id === "CRYPVOUCH001") {
              buyBitNovoVoucher(
                service_id,
                inpVal || cost,
                null,
                accountInfo.profile.hasSepafinCredentials,
                accountInfo.profile.username
              )
            } else {
              if (
                [
                  "BGM00",
                  "BBT00",
                  "BBL00",
                  "BULL",
                  "MBBT00",
                  "FLBT",
                  "FLBTF",
                  "AMBT",
                  "BETL",
                  "SPBET",
                ].some((allowedService) => service_id.includes(allowedService))
              ) {
                const serviceIdBet = (service_id || "").slice(0, -1) + "1"
                const allowedService = plusServices.map((s) => s.slice(0, -1) + "1")
                //("inpVal", inpVal, selectedCost);
                getCustomVoucherReq(
                  allowedService.includes(serviceIdBet) ? serviceIdBet : "",
                  inpVal || cost,
                  setLoadingRecharge,
                  accountInfo?.profile?.hasSepafinCredentials,
                  accountInfo?.profile?.username
                )
              } else {
                if (!isMrPay && !isSepafin && selectedCost?.type === 1) {
                  getRechargeMobileSim(
                    service_id,
                    inpVal,
                    selectedCost?.cost,
                    selectedCost?.ean,
                    accountInfo?.profile?.id,
                    setLoadingRecharge
                  )
                } else {
                  getRechargeMobile(
                    service_id,
                    hasCostZero ? null : `${inpVal}`,
                    setLoadingRecharge,
                    accountInfo?.profile?.hasSepafinCredentials,
                    accountInfo?.profile?.username,
                    codFisInps,
                    hasCostZero ? `${inpVal}` : selectedCost?.cost,
                    selectedCost?.ean,
                    accountInfo?.profile?.id
                  )
                }
              }
            }
          }}
        >
          Esegui <i className="fal fa-check" aria-hidden="true"></i>
        </button>
        <button
          className={!rechargeMobile.receipt ? "disable" : ""}
          onClick={() => {
            if (!rechargeMobile.receipt) {
              notification["warning"]({
                message: "Ticket non compilato",
              })
            }
            setPrint(true)
          }}
        >
          Stampa
        </button>
        <button
          onClick={() => {
            setService(null)
            setRechargeMobile({})
            setScannedBarcode(null)
          }}
        >
          Annulla <i className="fal fa-times" aria-hidden="true"></i>
        </button>
      </div>
      {rechargeMobile &&
        toPrint &&
        (isEPay ? (
          <EpayPrintTicket
            arr={rechargeMobile.return ? rechargeMobile.return : rechargeMobile}
            setPrint={setPrint}
          />
        ) : (
          <div className="mobileNumPad--ticket">
            <PrintTicketMob
              rechargeMobile={rechargeMobile.return}
              printT={printT}
              skinExtras={skinExtras}
              setPrint={setPrint}
            />
          </div>
        ))}
      {/* {rechargeMobile && toPrint && (
        <div className="mobileNumPad--ticket">
          <PrintTicketMob rechargeMobile={rechargeMobile.return} printT={printT} skinExtras={skinExtras} setPrint={setPrint} />
        </div>
      )} */}
      {transactionModal && (
        <TransactionModal
          ok={() => {
            setPrint(true)
          }}
          cancel={() => {
            setTransactionModal(false)
          }}
          msg={rechargeMobile.msg || "Grazie. Il tuo ordine e stato ricevuta!"}
        />
      )}
    </div>
  )
}
const mstp = ({
  main: { services },
  auth: {
    rechargeMobile,
    loadingRechargeMobile,
    skinExtras,
    transactionModal,
    buyBitNovoVoucher,
    accountInfo,
    scannedBarcode,
  },
}) => {
  return {
    services,
    rechargeMobile,
    loadingRechargeMobile,
    skinExtras,
    transactionModal,
    buyBitNovoVoucher,
    accountInfo,
    scannedBarcode,
  }
}
export default connect(mstp, AuthActions)(Numpad)
