import React from "react"
import { connect } from "react-redux"
import { AuthActions } from "redux-store/models"
import { Card, Icon, Table } from "antd"
import { Area } from "@ant-design/plots"

const TableStatistiche = (props) => {
  const { latestServiceStatistic } = props
  const dataArray =
    latestServiceStatistic?.payments &&
    Object.entries(latestServiceStatistic.payments || []).map(([key, value]) => ({
      serviceName: key,
      value: value.sum / 100,
    }))

  const config = {
    data: dataArray || [],
    padding: "auto",
    xField: "serviceName",
    yField: "value",
    smooth: true,
    color: "#0078FF",
    lineStyle: {
      shadowColor: "#0078FF",
      shadowBlur: 10,
      shadowOffsetX: 1,
      shadowOffsetY: 3,
      cursor: "pointer",
    },
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
      }
    },
  }

  var columns = [
    {
      title: "Posto",
      dataIndex: "posto",
      key: "posto",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Servizio",
      dataIndex: "servizio",
      key: "servizio",
      width: "30%",
      ellipsis: true,
    },
    {
      title: "Totale",
      dataIndex: "totale",
      key: "totale",
      width: "25%",
      ellipsis: true,
      render: (value) => `${value.toFixed(2)}`,
      align: "center",
      sorter: (a, b) => a.totale - b.totale,
    },
    {
      title: "Crescita",
      dataIndex: "crescita",
      key: "crescita",
      width: "25%",
      ellipsis: true,
      render: (value) => `${value.toFixed(2)}%`,
      align: "center",
      sorter: (a, b) => a.crescita - b.crescita,
    },
  ]

  const dataSource =
    latestServiceStatistic &&
    latestServiceStatistic?.payments &&
    Object.entries(latestServiceStatistic?.payments).map(
      ([serviceName, paymentData], i) => ({
        posto: i + 1,
        servizio: serviceName,
        totale: paymentData.sum / 100,
        crescita: paymentData.percentage,
      })
    )

  return (
    <div className="table-statistiche">
      <Card
        title="Prodotto più venduto"
        bordered={false}
        extra={<Icon type="ellipsis" key="ellipsis" />}
      >
        <Area {...config} style={{ height: "150px" }} />
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey={(record, i) => i}
          pagination={false}
        />
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  latestServiceStatistic: state.auth.latestServiceStatistic,
})

export default connect(mapStateToProps, AuthActions)(TableStatistiche)
