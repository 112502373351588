import React, { Component } from "react"
import { connect } from "react-redux"
import MainActions from "redux-store/models/main"
import { Header } from "../../shared-components"
import images from "../../themes/images"

class UnderConstruction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
    }
  }

  componentDidMount() {
    this.props.getSystemModes()

    const script = document.createElement("script")
    script.src =
      "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
    script.async = true
    document.body.appendChild(script)
    
    return () => {
      document.body.removeChild(script)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.systemModes.find((value) => value.active === 1) &&
      this.props.systemModes.find((value) => value.active === 1) !==
        prevProps.systemModes.find((value) => value.active === 1)
    ) {
      window.location.href = "#/dashboard/service"
    }
  }

  render() {
    return (
      <div>
        <Header />
        <div
          style={{
            height: "70vh",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <img style={{width: "120px" , margin : "auto"}} src={images['commingSoon']} alt="" /> */}
          <lottie-player
            src="https://lottie.host/d0a1e75d-1e9e-43e5-964a-1f78999efe92/lhcKLcQXTx.json"
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "300px" }}
            loop
            autoplay
            direction="1"
            mode="normal"
          ></lottie-player>
          <h3 style={{ marginTop: "20px" }}>SISTEMA IN AGGIORNAMENTO</h3>
        </div>
      </div>
    )
  }
}

const mpStP = (state) => ({
  systemModes: state.main.systemModes,
})

export default connect(mpStP, MainActions)(UnderConstruction)
