import React from "react"
import { connect } from "react-redux"
import { Upload } from "antd"
import { AuthActions } from "redux-store/models"

const { Dragger } = Upload

const LogoUploader = ({
  draggerProps,
  step,
  setStep,
  toBase64,
  fieldName,
  labelText,
  errorMessage,
  addEditSkin,
  addEditSkinDetails,
}) => {
  const handleFileChange = async (e) => {
    const file = e.file.originFileObj
    const Base64 = await toBase64(file)
    const newStep = {
      ...step,
      [fieldName]: e.file?.name || "",
      [`base64_${fieldName}`]: Base64,
    }

    const newChangedValues = { ...addEditSkin.changedValues }
    if (step[fieldName] !== e.file?.name || step[`base64_${fieldName}`] !== Base64) {
      newChangedValues[fieldName] = e.file?.name
      newChangedValues[`base64_${fieldName}`] = Base64
    } else {
      delete newChangedValues[fieldName]
      delete newChangedValues[`base64_${fieldName}`]
    }

    setStep(newStep)
    addEditSkinDetails({
      ...addEditSkin,
      step1: newStep,
      changedValues: newChangedValues,
    })
  }

  const handleRemove = () => {
    const newStep = {
      ...step,
      [fieldName]: "",
      [`base64_${fieldName}`]: "",
    }

    const newChangedValues = { ...addEditSkin.changedValues }
    if (step[fieldName] !== "" || step[`base64_${fieldName}`] !== "") {
      newChangedValues[fieldName] = ""
      newChangedValues[`base64_${fieldName}`] = ""
    } else {
      delete newChangedValues[fieldName]
      delete newChangedValues[`base64_${fieldName}`]
    }

    setStep(newStep)
    addEditSkinDetails({
      ...addEditSkin,
      step1: newStep,
      changedValues: newChangedValues,
    })
  }

  return (
    <div>
      {!step[fieldName] ? (
        <Dragger maxCount={1} {...draggerProps} onChange={handleFileChange}>
          <p className="ant-upload-drag-icon">
            <i className="fal fa-plus-circle"></i>
          </p>
          <p className="ant-upload-text">{labelText}</p>
        </Dragger>
      ) : (
        <div className="logo-uploaded-field">
          <p>{labelText} :</p>
          <div>
            <img src={step[`base64_${fieldName}`]} alt="" />
            <p onClick={handleRemove}>
              Remove <i className="fal fa-trash"></i>
            </p>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="error-message" style={{ color: "red", fontSize: 12 }}>
          {errorMessage}
        </div>
      )}
    </div>
  )
}

const mapsStateToProps = (state) => ({
  addEditSkin: state.auth.addEditSkin,
})

export default connect(mapsStateToProps, AuthActions)(LogoUploader)
