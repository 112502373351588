import React, { useEffect, useState } from "react"
import { notification } from "antd"
import QrCodeReader from "shared-components/QrCodeReader"
import { barcodeReaderReq } from "../../../src/services/auth"
import { AuthActions, MainActions } from "redux-store/models"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const ScanMobile = (props) => {
  const [visibility, setVisibility] = useState(true)

  useEffect(() => {
    setVisibility(true)
  }, [])

  const close = () => setVisibility(false)
  const redirectUrl = new URLSearchParams(
    window.location.hash.replace(`#/scan-mobile?`, "")
  ).get("redirectUrl")


  return (
    visibility && (
      <QrCodeReader visibility={visibility}>
        {(data) => {
          if (data && data !== "No result") {
            if (redirectUrl) {
              window.location.href = `${redirectUrl}&qr-code=${data}`
            } else {
              barcodeReaderReq(data).then((res) => {
                // if (res?.data?.message) {
                //   notification["success"]({ message: res?.data?.message })
                // }

                // close(); 
               
                props.history.push("/dashboard")
              })
              props.setScannedBarcode(data)
            }
          }
        }}
      </QrCodeReader>
    )
  )
}

const mpstp = (state) => ({
  scannedBarcode: state.auth.scannedBarcode,
})

export default withRouter(
  connect(mpstp, { ...AuthActions, ...MainActions })(ScanMobile)
)
