import React, { useCallback, useEffect, useState } from "react"
import images from "themes/images"
import { message } from "antd"
import { connect } from "react-redux"
import MainActions from "redux-store/models/main"
import AuthActions from "redux-store/models/auth"
import UseCode from "routes/views/UseCode"
import { editCompanieBackNames } from "config/index"
import { baseUrlImg } from "../../../config/api"

const companiesToRemove = ["Come prenotare", "Prenota Bollettino", "MAV"]
const subCategoryNames = {
  "Bollettino PagoPa": "PagoPA",
  "Bollettino MAV/RAV": "MAV/RAV",
  "Bollettino Auto": "Bollo Auto",
  "Bollettino Permarcato": "Bollettino Premarcato",
}

const imageByServiceId = (service_id, id) => {
  const imagesByServiceId = {
    SPPA001: "PAGO_PA",
    SPAGF24: `${service_id}-Black-Mobile`,
  }
  const imagesById = {
    PPA: "PAGO_PA",
    BOLMR: "BOLL-Black-Mobile",
  }
  const imageById =
    id in imagesById ? imagesById[id] : id in images ? id : `BOLL-Black-Mobile`

  return service_id in imagesByServiceId ? imagesByServiceId[service_id] : imageById
}

const modifyCompaniesName = (name) => {
  if (name in editCompanieBackNames) return editCompanieBackNames[name]
  return name
}

const CompaniesCheck = ({
  id,
  submit,
  setParentState,
  service,
  company,
  className,
  toggleFavorite,
  getServices,
  accountInfo,
  screenWidth,
  getCodiceTicket,
}) => {
  const { service_id, name } = service || {}
  const [hasVpt, setHasVpt] = useState(false)
  const [allCompaniesHtml, setAllCompaniesHtml] = useState(
    document.querySelectorAll(`[data-id="${id}comp"]`)
  )

  const [image, setImage] = useState(imageByServiceId(service_id, id))
  const [serviceName, setServiceName] = useState(
    modifyCompaniesName(name).replace(/TOPUP/g, "").replace(/PIN/g, "")
  )
  const defaultClass = ["BOLL", "SBOLL", "BOLMR", "PPA", "RCPP"].includes(id)
    ? "Pagamenti"
    : ""
  const role = accountInfo?.profile?.role
  const { services, favourite } = company || {}

  const accountData = JSON.parse(localStorage.getItem("accountDataB")) || {}
  const isEPay = accountData?.profile?.supplier === "E-Pay"

  const updateServices = () => {
    setParentState &&
      setParentState({ Services: services, serviceSelected: services[0] })
  }

  useEffect(() => {
    setImage(imageByServiceId(service_id, id))
  }, [id, service_id])

  useEffect(() => {
    document.querySelectorAll(`[data-id="${id}comp"]`)
  }, [id])

  useEffect(() => {
    setServiceName(
      modifyCompaniesName(name).replace(/TOPUP/g, "").replace(/PIN/g, "")
    )
  }, [name])

  const onMouseEnter = useCallback(
    () => allCompaniesHtml.forEach((el) => el?.classList?.add("hover")),
    [allCompaniesHtml]
  )
  const onMouseLeave = useCallback(
    () => allCompaniesHtml.forEach((el) => el?.classList?.remove("hover")),
    [allCompaniesHtml]
  )

  const handleClick = useCallback(() => {
    if (!role) {
      message.info("Per favore fai prima il log in.")
    } else if (["SBOL001", "SBOL002"].includes(service_id) && screenWidth <= 1024) {
      setHasVpt(true)
    } else {
      submit()
      updateServices()
    }
  }, [role, screenWidth, service_id, submit, updateServices])

  const favouriteClick = useCallback(
    async (e) => {
      e.stopPropagation()
      e.preventDefault()
      await (favourite ? toggleFavorite(id, "remove") : toggleFavorite(id, "set"))
      setTimeout(() => {
        getServices()
      }, 200)
    },
    [favourite, toggleFavorite, id, getServices]
  )

  if (companiesToRemove.includes(name)) {
    return null
  }

  return (
    <React.Fragment>
      {screenWidth <= 1024 && hasVpt && (
        <div className="backDrop" onClick={() => setHasVpt(false)}>
          <UseCode
            getCodiceTicket={getCodiceTicket}
            paymentNotFinished={true}
            prenotationType="PrenotedBollettini"
          />
        </div>
      )}
      {serviceName === "PostePay" ? (
        <div className={`${className || defaultClass || ""} ${serviceName}`}>
          <img src={images.coming_soon} className="coming-soon" />
          <img src={images[image]} alt={image || id} />
          <span>
            {" "}
            {serviceName === "PostePay" ? "Ricarica Carte Prepagate" : serviceName}
          </span>
        </div>
      ) : (
        <div
          onClick={handleClick}
          className={`${className || defaultClass || ""} ${serviceName}`}
        >
          <img
            src={
              isEPay
                ? `${baseUrlImg}/${
                    service?.services?.[0]?.icon || "uncategorized/placeholder.jpg"
                  }`
                : service?.services?.[0]?.icon
                ? `${baseUrlImg}/${service.services[0].icon}`
                : service?.icon
                ? `${baseUrlImg}/${service.icon}`
                : images[image]
            }
            alt={image || id}
          />
          <span> {subCategoryNames[serviceName] || serviceName}</span>
          {screenWidth > 1024 && (
            <i
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={favouriteClick}
              data-id={`${id}comp`}
              className={`fal fa-star ${favourite ? "favourite" : ""}`}
            />
          )}
        </div>
      )}
    </React.Fragment>
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  screenWidth: state.main.screenWidth,
  skinExtras: state.auth.skinExtras,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  CompaniesCheck
)
