import { Form, Icon, Tooltip } from "antd"
import React from "react"
import FloatingLabelInput from "../FloatingLabelInput/FloatingLabelInput"
import GenderToggle from "../GenderToggle/GenderToggle"

function Step1({ form, formData, setFormData }) {
  const { getFieldError, getFieldDecorator, getFieldValue } = form
  return (
    <>
      <Form.Item validateStatus={getFieldError("codice_fiscale") ? "error" : ""}>
        {getFieldDecorator("codice_fiscale", {
          initialValue: formData.codice_fiscale,
          rules: [
            { required: true, message: "Inserire Codice Fiscale!" },
            {
              validator: (rule, value, callback) => {
                if (value && value.length !== 16) {
                  callback("Il Codice Fiscale deve essere lungo 16 caratteri!")
                } else {
                  callback()
                }
              },
            },
            {
              pattern: new RegExp(
                "^[A-Z]{6}\\d{2}[A-EHLMPRST]{1}\\d{2}[A-Z]{1}\\d{3}[A-Z]{1}$"
              ),
            },
          ],
        })(<FloatingLabelInput label="Codice Fiscale" />)}

        {/* Tooltip for validation errors */}
        {getFieldError("codice_fiscale") && (
          <Tooltip title={getFieldError("codice_fiscale")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}

        {!getFieldError("codice_fiscale") &&
          getFieldValue("codice_fiscale")?.length === 16 && (
            <i className="fas fa-check-circle"></i>
          )}
        {/* Conditionally show check or cross icon */}
        {/* {getFieldValue("codice_fiscale")?.length === 16 &&
        !getFieldValue("codice_fiscale").includes(" ") && (
          <i className="fas fa-check-circle"></i>
        )}

      {getFieldValue("codice_fiscale")?.length > 0 &&
        getFieldValue("codice_fiscale")?.length < 16 && (
          <i className="fas fa-times-circle"></i>
        )} */}
      </Form.Item>

      <Form.Item
        validateStatus={
          getFieldError("email") ? "error" : getFieldValue("email") ? "success" : ""
        }
      >
        {getFieldDecorator("email", {
          initialValue: formData.email,
          rules: [
            { type: "email", message: "E-mail non valido!" },
            { required: true, message: "Inserire E-mail!" },
          ],
        })(<FloatingLabelInput label="Email" />)}
        {getFieldError("email") && (
          <Tooltip title={getFieldError("email")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <Form.Item validateStatus={getFieldError("number") ? "error" : ""}>
        {getFieldDecorator("number", {
          initialValue: formData.number,
          rules: [{ required: true, message: "Inserire numero telefono!" }],
        })(<FloatingLabelInput label="Telefono" />)}
        {getFieldError("number") && (
          <Tooltip title={getFieldError("number")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <Form.Item validateStatus={getFieldError("gender") ? "error" : ""}>
        {getFieldDecorator("gender", {
          initialValue: formData.gender,
          rules: [{ required: true, message: "Inserire sesso!" }],
        })(
          <GenderToggle
            initialGender={formData.gender}
            onGenderChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                gender: e,
              }))
            }
          />
        )}
        {getFieldError("gender") && (
          <Tooltip title={getFieldError("gender")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <Form.Item
        style={{ marginBottom: "-17px" }}
        validateStatus={getFieldError("first_name") ? "error" : ""}
      >
        {getFieldDecorator("first_name", {
          initialValue: formData.first_name,
          rules: [{ required: true, message: "Inserire nome!", whitespace: true }],
        })(<FloatingLabelInput label="Nome" />)}
        {getFieldError("first_name") && (
          <Tooltip title={getFieldError("first_name")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <Form.Item validateStatus={getFieldError("last_name") ? "error" : ""}>
        {getFieldDecorator("last_name", {
          initialValue: formData.last_name,
          rules: [{ required: true, message: "Inserire cognome!" }],
        })(<FloatingLabelInput label="Cognome" />)}
        {getFieldError("last_name") && (
          <Tooltip title={getFieldError("last_name")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <Form.Item validateStatus={getFieldError("nickname") ? "error" : ""}>
        {getFieldDecorator("nickname", {
          initialValue: formData.nickname,
          rules: [{ required: true, message: "Inserisci un nome utente valido!" }],
        })(<FloatingLabelInput label="Username" />)}
        {getFieldError("nickname") && (
          <Tooltip title={getFieldError("nickname")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 10,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <Form.Item validateStatus={getFieldError("password") ? "error" : ""}>
        {getFieldDecorator("password", {
          initialValue: formData.password,
          rules: [{ required: true, message: "Inserisci una password valida!" }],
        })(<FloatingLabelInput type="password" label="Password" />)}
        {getFieldError("password") && (
          <Tooltip title={getFieldError("password")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 30,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>

      <Form.Item validateStatus={getFieldError("confirm_password") ? "error" : ""}>
        {getFieldDecorator("confirm_password", {
          initialValue: formData.confirm_password,
          rules: [
            { required: true, message: "Inserisci una password valida!" },
            {
              validator: (rule, value, callback) => {
                if (!value || value === getFieldValue("password")) {
                  callback()
                } else {
                  callback("Le password non corrispondono!")
                }
              },
            },
          ],
        })(<FloatingLabelInput type="password" label="Conferma Password" />)}
        {getFieldError("confirm_password") && (
          <Tooltip title={getFieldError("confirm_password")[0]} placement="right">
            <Icon
              type="exclamation-circle"
              style={{
                color: "red",
                position: "absolute",
                zIndex: 3,
                top: 18,
                right: 30,
              }}
            />
          </Tooltip>
        )}
      </Form.Item>
    </>
  )
}

export default Step1
