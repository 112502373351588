import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { AuthActions } from "redux-store/models"
import MySpan from "./MySpan"
import moment from "moment"
import { DatePicker } from "antd"

const NewStep3 = (props) => {
  const { addEditSkin, addEditSkinDetails, handleSubmit } = props
  const [step3, setStep3] = useState({
    first_name: "",
    last_name: "",
    username: "",
    gender: "",
    email: "",
    address: "",
    city: "",
    birth_comune_code: "",
    cap: "",
    country: "",
    conferma_password: "",
    phone: "",
    personal_number: "",
    birth_place: "",
    birth_country: "",
    birthday: "",
    password: "",
    ragione_sociale: "",
    codice_fiscale: "",
    p_iva: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    const newStep3 = { ...step3, [name]: value }
    setStep3(newStep3)
    addEditSkinDetails({
      ...addEditSkin,
      step3: newStep3,
    })

    const newChangedValues = { ...addEditSkin.changedValues }
    if (step3[name] !== value) {
      newChangedValues[name] = value
    } else {
      delete newChangedValues[name]
    }
    addEditSkinDetails({
      ...addEditSkin,
      step3: newStep3,
      changedValues: newChangedValues,
    })
  }

  const handleDateChange = (date) => {
    const newStep3 = {
      ...step3,
      birthday: date && date.isValid() ? date.format("DD-MM-YYYY") : null,
    };

    const newChangedValues = { ...addEditSkin.changedValues };
    if (step3.birthday !== newStep3.birthday) {
      newChangedValues.birthday = newStep3.birthday;
    } else {
      delete newChangedValues.birthday;
    }

    setStep3(newStep3);
    addEditSkinDetails({
      ...addEditSkin,
      step3: newStep3,
      changedValues: newChangedValues,
    });
  };


  const validateFields = () => {
    const requiredFields = [
      "first_name",
      "last_name",
      "username",
      "gender",
      "email",
      "address",
      "city",
      "birth_comune_code",
      "cap",
      "country",
      "phone",
      "personal_number",
      "birth_place",
      "birth_country",
      "birthday",
      "ragione_sociale",
      "codice_fiscale",
      "p_iva",
    ]
    const newErrors = {}
    let hasError = false

    requiredFields.forEach((field) => {
      if (!step3[field]) {
        newErrors[field] = ["This field is required."]
        hasError = true
      }
    })

    return { newErrors, hasError }
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    const { newErrors, hasError } = validateFields()

    if (hasError) {
      addEditSkinDetails({
        ...addEditSkin,
        errors: newErrors,
      })
    } else {
      console.log("No errors found, proceeding to next step.")
      handleSubmit(e)
      addEditSkinDetails({
        ...addEditSkin,
        step3: step3,
        skinPannel: true,
        errors: {},
      })
    }
  }

  const getErrorMessage = (field) => {
    if (addEditSkin?.errors && addEditSkin?.errors[field]) {
      return addEditSkin?.errors[field][0]
    }
    return ""
  }

  useEffect(() => {
    const skinDetails = addEditSkin?.skinDetails
    if (skinDetails && Object.keys(skinDetails).length > 0) {
      setStep3((prevStep3) => ({
        ...prevStep3,
        first_name: skinDetails.users[0].first_name || prevStep3.first_name,
        last_name: skinDetails.users[0].last_name || prevStep3.last_name,
        username: skinDetails.users[0].username || prevStep3.username,
        gender: skinDetails.users[0].gender || prevStep3.gender,
        email: skinDetails.users[0].email || prevStep3.email,
        address: skinDetails.users[0].address || prevStep3.address,
        city: skinDetails.users[0].city || prevStep3.city,
        birth_comune_code:
          skinDetails.users[0].birth_comune_code || prevStep3.birth_comune_code,
        cap: skinDetails.users[0].cap || prevStep3.cap,
        country: skinDetails.users[0].country || prevStep3.country,
        conferma_password:
          skinDetails.users[0].conferma_password || prevStep3.conferma_password,
        phone: skinDetails.users[0].phone || prevStep3.phone,
        personal_number:
          skinDetails.users[0].personal_number || prevStep3.personal_number,
        birth_place: skinDetails.users[0].birth_place || prevStep3.birth_place,
        birth_country: skinDetails.users[0].birth_country || prevStep3.birth_country,
        birthday: skinDetails.users[0].birthday || prevStep3.birthday,
        password: skinDetails.users[0].password || prevStep3.password,
        ragione_sociale:
          skinDetails.users[0].ragione_sociale || prevStep3.ragione_sociale,
        codice_fiscale:
          skinDetails.users[0].codice_fiscale || prevStep3.codice_fiscale,
        p_iva: skinDetails.users[0].p_iva || prevStep3.p_iva,
      }))
    }

    if (addEditSkin?.step3) {
      setStep3((prevStep3) => ({
        ...prevStep3,
        ...addEditSkin.step3,
      }))
    }
  }, [addEditSkin])

  return (
    <div className="AdminLogin--Step1">
      <div className="AdminLogin--Step1--Step2 Step3">
        <i
          className="fal fa-long-arrow-left"
          onClick={() => {
            addEditSkinDetails({
              ...addEditSkin,
              step3: step3,
              skinId: addEditSkin?.skinId,
              skinName: addEditSkin?.skinName,
              skinPannel: true,
              stepNumber: addEditSkin?.stepNumber - 1,
            })
          }}
        ></i>

        <div className="AdminLogin--Step1--Right">
          <div className="AdminLogin--Step1--Right--Important">
            <h1>INFORMAZIONI PERSONALI</h1>
            <MySpan
              title="Nome"
              iconClass="fal fa-user"
              handleChange={handleChange}
              defaultValue={step3.first_name}
              name="first_name"
              error={getErrorMessage("first_name")}
            />
            <MySpan
              title="Cognome"
              iconClass="fal fa-user"
              handleChange={handleChange}
              defaultValue={step3.last_name}
              name="last_name"
              error={getErrorMessage("last_name")}
            />
            <div>
              {" "}
              <span>
                <select
                  className="spanField"
                  style={{
                    padding: "10px",
                    borderColor: getErrorMessage("gender") && "red",
                  }}
                  onChange={handleChange}
                  name="gender"
                  defaultValue={
                    step3.gender == 1
                      ? "Male"
                      : step3.gender == 2
                      ? "Female"
                      : "Genre"
                  }
                >
                  <option value="0">Genre</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </select>
              </span>
              {getErrorMessage("gender") && (
                <div
                  className="error-message"
                  style={{ color: "red", fontSize: 12 }}
                >
                  {getErrorMessage("gender")}
                </div>
              )}
            </div>

            <span className="spanField">
              <DatePicker
                onChange={handleDateChange}
                value={step3.birthday ? moment(step3.birthday, "DD-MM-YYYY") : null}
                format={["DD/MM/YYYY", "DD/MM/YYYY"]}
              />
            </span>
            <MySpan
              title="Comune Nascita"
              iconClass="fal fa-home"
              handleChange={handleChange}
              defaultValue={step3.birth_place}
              name="birth_place"
              error={getErrorMessage("birth_place")}
            />
            <MySpan
              title="Stato Nascita"
              iconClass="fal fa-flag"
              handleChange={handleChange}
              name="birth_country"
              defaultValue={step3.birth_country}
              error={getErrorMessage("birth_country")}
            />
            <MySpan
              title="Numero Personale"
              iconClass="fal fa-id-card "
              handleChange={handleChange}
              defaultValue={step3.personal_number}
              name="personal_number"
              error={getErrorMessage("personal_number")}
            />
          </div>
          <div className="AdminLogin--Step1--Right--Important">
            <h1>INFORMAZIONI GENERALI</h1>
            <MySpan
              title="Numero di Telefono"
              iconClass="fal fa-mobile"
              handleChange={handleChange}
              name="phone"
              defaultValue={step3.phone}
              error={getErrorMessage("phone")}
            />
            <MySpan
              title="Indirizzo"
              iconClass="fal fa-map-marker-alt"
              handleChange={handleChange}
              defaultValue={step3.address}
              name="address"
              error={getErrorMessage("address")}
            />
            <MySpan
              title="Città"
              iconClass="fal fa-globe-americas"
              handleChange={handleChange}
              defaultValue={step3.city}
              name="city"
              error={getErrorMessage("city")}
            />
          </div>
          <button
            onClick={() => {
              addEditSkinDetails({
                ...addEditSkin,
                step3: step3,
                skinId: addEditSkin?.skinId,
                skinName: addEditSkin?.skinName,
                skinPannel: true,
                stepNumber: addEditSkin?.stepNumber - 1,
              })
            }}
          >
            TORNA INDIETRO
          </button>
        </div>
        <div className="AdminLogin--Step1--Left" style={{ marginTop: 36 }}>
          <MySpan
            title="Provincia"
            iconClass="fal fa-building"
            handleChange={handleChange}
            defaultValue={step3.birth_comune_code}
            name="birth_comune_code"
            error={getErrorMessage("birth_comune_code")}
          />
          <MySpan
            title="Stato"
            iconClass="fal fa-flag"
            handleChange={handleChange}
            defaultValue={step3.country}
            name="country"
            error={getErrorMessage("country")}
          />
          <MySpan
            title="Ragione Sociale"
            iconClass="fal fa-briefcase"
            handleChange={handleChange}
            name="ragione_sociale"
            defaultValue={step3.ragione_sociale}
            error={getErrorMessage("ragione_sociale")}
          />
          <MySpan
            title="CAP"
            iconClass="fal fa-mailbox"
            handleChange={handleChange}
            defaultValue={step3.cap}
            name="cap"
            error={getErrorMessage("cap")}
          />
          <MySpan
            title="P.IVA"
            iconClass="fab fa-receipt"
            handleChange={handleChange}
            defaultValue={step3.p_iva}
            name="p_iva"
            error={getErrorMessage("p_iva")}
          />
          <MySpan
            title="Codice Fiscale "
            iconClass="fab fa-id-card"
            handleChange={handleChange}
            name="codice_fiscale"
            defaultValue={step3.codice_fiscale}
            error={getErrorMessage("codice_fiscale")}
          />
          <div className="AdminLogin--Step1--Left--Affitto">
            <h1>INFORMAZIONI ADMIN ACCOUNT</h1>
            <MySpan
              title="Username"
              iconClass="fal fa-user-circle   "
              handleChange={handleChange}
              defaultValue={step3.username}
              name="username"
              error={getErrorMessage("username")}
            />
            <MySpan
              title="Password"
              iconClass="fal fa-key"
              handleChange={handleChange}
              value={step3.password}
              name="password"
              error={getErrorMessage("password")}
            />
            <MySpan
              title="Conferma Password"
              iconClass="fab fa-key"
              handleChange={handleChange}
              defaultValue={step3.conferma_password}
              name="conferma_password"
              error={getErrorMessage("conferma_password")}
            />
            <MySpan
              title="E-mail"
              iconClass="fal fa-envelope"
              handleChange={handleChange}
              defaultValue={step3.email}
              name="email"
              error={getErrorMessage("email")}
            />
          </div>
          <button
            onClick={(e) => {
              handleSubmitForm(e)
            }}
          >
            {addEditSkin.skinId === -1 ? "CHIUDI REGISTRAZIONE" : "AGGIORNAMENTO"}
          </button>
        </div>
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  addEditSkin: state.auth.addEditSkin,
  newSkinId: state.auth.newSkinId,
  registerSkin: state.auth.registerSkin,
})

export default connect(mapsStateToProps, AuthActions)(NewStep3)
