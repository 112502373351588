import Echo from "laravel-echo"
import { notification } from "antd"
import React from "react"
import DownloadVisure from "../shared-components/DownloadVisure"
import { Provider } from "react-redux"
import { sepafinRegisterLogin, sepafinReLoginRequire } from "utils/HelperFunc"
var audio = new Audio("notification_sound.mp3")
audio.loop = true

const stopAudio = () => {
  audio.pause()
  audio.currentTime = 0
}

const host = window.location.href.includes("staging")
  ? "wss://staging-api.bpoint.store"
  : "wss://services-api.bpoint.store"
window.stopAudio = stopAudio
export const socket = () => {
  window.io = require("socket.io-client")
  window["echo"] = new Echo({
    broadcaster: "socket.io",
    host: host,
    path: "/websocket/socket.io",
    transports: ["websocket", "polling", "flashsocket"],
  })
}
export const subscribeSocketUser = (userID, props) => {
  const USER_NAME =
    props?.accountInfo?.profile?.name?.toUpperCase?.() ||
    JSON.parse(localStorage.getItem("accountDataB"))?.profile?.name?.toUpperCase?.()
  var docTitle = document.title
  document.title = `👋 Benvenuto ${USER_NAME}`

  setTimeout(() => {
    document.title = docTitle
  }, 3000)

  //console.log("props", props.accountInfo);
  window["echo"].channel(`bpoint_cache_${userID}`).listen(".user", (e) => {
    // console.log("subscribed listening...", userID, props, e);
    if (e.type === "payment" || e.type === "simple_notification") {
      props.addPrivateMsg(e.data)
    }
    if (e.type === "popupMessage") {
      props.setPopup([e.data])
    }
    if (e.type === "sepafin_callback") {
      const { result, data } = e || {}
      const { sepafin_access_token, expires } = data || {}
      if (result === "SUCCESS") {
        sepafinRegisterLogin(sepafin_access_token, expires,data?.username ? data.username : props?.accountInfo?.profile?.username)
        props.setShowSepafinLogin(false)
      } else {
        sepafinReLoginRequire(props?.accountInfo?.profile?.username)
        props.setShowSepafinLogin(true)
      }

      notification.close("sepafinLogin")
      notification[result === "SUCCESS" ? "success" : "error"]({
        message: e?.data?.title,
        placement: "topRight",
        duration: 400,
      })
    }
    if (
      e.type === "notification" ||
      e.type === "notification_visure" ||
      e.type === "payment" ||
      e.type === "simple_notification"
    ) {
      notification.open({
        message: "Hai ricevuto una notifica",
        description: e.data.title,
        icon: (
          <i
            className={`fal ${
              e.resul === "FAILED" ? "fa-sad-tear" : "fa-smile-beam"
            } `}
          ></i>
        ),
        duration: !e.ticket ? 0 : 4,
        onClose: () => {
          stopAudio()
        },
      })
      if (!e.ticket) {
        audio.play()
      } else {
        // props.setSocketReceipt({
        //   barcode: e.instance_id,
        //   receipt: e.ticket,
        // });
        if (e.type != "simple_notification")
          props.getCodiceTicket(e.instance_id, "omeLale")
      }
    }
    if (e.type === "popup") {
      props.bigliettoPopUp({
        id: e.instance_id,
        data: e.data,
      })
    }
    if (e.type === "visure_document") {
      const handleDocumentDownload = () =>
        props.getVisureDocument(e?.data?.payment_barcode)
      notification["success"]({
        message: e?.data?.title,
        description: (
          <div className="printModal visure">
            <Provider store={window.store}>
              <DownloadVisure handleClick={handleDocumentDownload} />
            </Provider>
          </div>
        ),
        placement: "topRight",
        duration: 0,
      })
    }
    // setScannedBarcode
    if (e.type === "barcode_reader" && e?.data?.scanned_bardode) {
      props.setScannedBarcode(e?.data?.scanned_bardode)
    }
    if (e.type === "popup_visure") {
      props.bigliettoPopUpVisure({
        id: e.instance_id,
        data: e.data,
      })
    }
    if (
      e.type === "notification" &&
      (e.data.title === "Il biglietto è stato accettato" ||
        e.data.title === "La prenotazione è stata accettata")
    ) {
      notification.open({
        message: "Hai ricevuto una notifica",
        description: e.data.title,
        icon: <i className="fal fa-smile-beam"></i>,
      })
      props.setButtonsSupport({ status: true, instance: e.instance_id })
    }
    if (
      e.type === "token_expired" &&
      e.instance_id === JSON.parse(localStorage.getItem("accountDataB"))?.token_id
    ) {
      notification.open({
        message: "Hai ricevuto una notifica",
        description: "Perfavore re loggati",
        icon: <i className="fal fa-smile-beam"></i>,
        onClose: () => {
          if (window.location.hash.includes("login")) {
          } else {
            window.location.hash = "login"
          }
        },
      })
      unSubscribeSocketUser(
        JSON.parse(localStorage.getItem("accountDataB")).profile.id
      )
      if (
        JSON.parse(localStorage.getItem("accountDataB")).profile.role.name ===
        "support"
      ) {
        unSubscribeSocketSupport()
      }
      localStorage.setItem("accountDataB", null)
      props.setUnauthorization()
    }
    if (e.type === "money") {
      const accountInfo = window.store.getState().auth["accountInfo"]
      let profile = accountInfo["profile"]
      const newProfile = {
        ...profile,
        wallet: e.data.wallet,
      }

      window.store.dispatch({
        type: "SET_ACCOUNT_INFO",
        accountInfo: { ...accountInfo, profile: newProfile },
      })
    }
  })
}
export const subscribeSocketSupport = (props) => {
  window["echo"].channel(`bpoint_cache_support`).listen(".support", (e) => {
    if (e.type === "notification" || e.type === "notification_visure") {
      if (
        e.instance.nome_agenzia === "luce-gas" &&
        window.store.getState().auth.accountInfo.profile.username ===
          "support_prenotazioni"
      ) {
        notification.open({
          message: "Hai ricevuto una notifica",
          description: e.data.title,
          icon: <i className="fal fa-smile-beam"></i>,
          duration: 0,
          onClose: () => {
            stopAudio()
          },
        })

        audio.play()
        props.addTicket(e.instance)
      } else if (
        e.instance.nome_agenzia !== "luce-gas" &&
        window.store.getState().auth.accountInfo.profile.username !==
          "support_prenotazioni"
      ) {
        notification.open({
          message: "Hai ricevuto una notifica",
          description: e.data.title,
          icon: <i className="fal fa-smile-beam"></i>,
          duration: 0,
          onClose: () => {
            stopAudio()
          },
        })

        audio.play()
        props.addTicket(e.instance)
      }
    }
  })
}
export const unSubscribeSocketUser = (userID) => {
  window["echo"].leaveChannel(`bpoint_cache_${userID}`)
}
export const unSubscribeSocketSupport = () => {
  window["echo"].leaveChannel(`bpoint_cache_support`)
}
