import React, { useState, useEffect, useRef } from "react";
import { numberWithCommas } from "utils/HelperFunc";
import ReactToPrint from "react-to-print";
import { Spin } from "antd";

const antIcon = (
  <i
    style={{ fontSize: 24, color: "#c52727" }}
    className="fad fa-spinner-third fa-spin"
  ></i>
);

export default ({
  getPaymentsForExcel,
  username,
  perPage,
  payments,
  from,
  to,
  service,
  paymentExcelLoading,
  allSkins,
  activeSkinId
}) => {
  const ref = useRef(null);
  const print = useRef(null);

  const [toPrint, setPrint] = useState(false);

  useEffect(() => {
    console.log('00000000000000000000000000000000')
    console.log(paymentExcelLoading)
    console.log(payments)

    if (print.current && !paymentExcelLoading) {
      print.current.click();
    }
  }, [paymentExcelLoading]);

  return (
    <div className="exportPDF">
      {payments.length > 0 && toPrint ? (
        <ReactToPrint
          trigger={() => (
            <div ref={print}>
              <i className="fal fa-file-pdf"></i>
              {paymentExcelLoading ? <Spin indicator={antIcon} /> : null}
            </div>
          )}
          onBeforePrint={() => {
            setPrint(false);
          }}
          content={() => ref.current}
          bodyClass="afterprint"
        />
      ) : (
        <div
          onClick={() => {
            if (allSkins) {
              getPaymentsForExcel(from, to);
            } else {
              getPaymentsForExcel(username, from, to, "", perPage, activeSkinId ? activeSkinId : "", "special", "", service);
            }
            setPrint(true);
          }}
        >
          <i className="fal fa-file-pdf"></i>
          {paymentExcelLoading && toPrint ? <Spin indicator={antIcon} /> : <span>.Pdf </span>}
        </div>
      )}
      <table id="pdfTable" ref={ref} className="tablePrint">
        <thead>
          {allSkins ? (
            <tr>
              <th>Date / Ora</th>
              <th>Barcode</th>
              <th>User</th>
              <th>Username</th>
              <th>Service</th>
              <th>Importo</th>
              <th>Commissione</th>
              <th>Proviggione</th>
            </tr>
          ) : (
            <tr>
              <th>Date / Ora</th>
              <th>Barcode</th>
              <th>User</th>
              <th>Service</th>
              <th>Importo</th>
              <th>Commissione</th>
              <th>Proviggione</th>
              <th>Saldo</th>
            </tr>
          )}
        </thead>
        <tbody>
          {payments.map((pay) => (
            <tr key={pay.id}>
              <td>{allSkins ? pay.Date_Ora : pay.executed_date}</td>
              <td>{allSkins ? pay.Barcode : pay.barcode}</td>
              <td>{allSkins ? pay.User : pay.agency_name}</td>
              {allSkins && <td>{pay.Username}</td>}
              <td>{allSkins ? pay.Service : pay.service_name}</td>
              <td>{numberWithCommas(allSkins ? pay.Importo : pay.price1000 / 1000)}</td>
              <td>{pay.commissione}</td>
              <td>{pay.percentage}</td>
              {!allSkins && <td>{pay.saldo}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
